import { useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import RichTextEditorBase, { RichTextEditorInstance } from '@hs-baumappe/rich-text-editor';
import UPLOAD_EDITOR_IMAGE from './graphql';

interface RichTextEditorProps {
  id?: string;
  value?: string;
  enableResizeImage?: boolean;
  initialImageWidth?: number;
  imageUploadingDisabled?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  onRteDirty?: (dirty: boolean) => void;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 mb
const FILE_EXTENSION_REGEX = /^.*\.(png|jpg|jpeg)$/i;

export default function RichTextEditor({
  initialImageWidth,
  ...otherProps
}: RichTextEditorProps): JSX.Element {
  const { t } = useTranslation();
  const [uploadEditorImage, { loading }] = useMutation(UPLOAD_EDITOR_IMAGE);

  const ref = useRef<RichTextEditorInstance>(null);

  const insertImageEmbed = (imageUrl: string) => {
    if (!ref.current) return;

    const editor = ref.current.getEditor();

    const range = editor.getSelection();
    const rangeIndex = range ? range.index : 0;

    editor.insertEmbed(rangeIndex, 'image', imageUrl);

    if (initialImageWidth) {
      editor.formatText(rangeIndex, rangeIndex + 1, 'width', `${initialImageWidth}px`);
    }
  };

  const handleImageSelect = async (file: File) => {
    if (loading) return;

    if (!FILE_EXTENSION_REGEX.test(file.name)) {
      alert(t('richTextEditor.fileUpload.formatError'));
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      alert(t('richTextEditor.fileUpload.sizeError'));
      return;
    }

    try {
      const { data } = await uploadEditorImage({
        variables: {
          file,
        },
      });

      if (!data) return;

      insertImageEmbed(data?.uploadEditorImage.path);
    } catch (error) {
      alert(t('richTextEditor.fileUpload.unknownError'));
    }
  };

  return (
    <RichTextEditorBase
      ref={ref}
      onImageSelect={handleImageSelect}
      onImageDrop={handleImageSelect}
      {...otherProps}
    />
  );
}
