import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ArchiveOvertime,
  ArchiveOvertimeVariables,
} from './__generated__/ArchiveOvertime.mutation';

const ArchiveOvertimeMutation: TypedDocumentNode<ArchiveOvertime, ArchiveOvertimeVariables> = gql`
  mutation ArchiveOvertime($input: DriveDocumentArchiveInput!) {
    driveDocumentArchive(input: $input) {
      id
    }
  }
`;

export default ArchiveOvertimeMutation;
