import { FormikHelpers } from 'formik';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { createPath, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FormikErrors } from 'formik/dist/types';
import { mapBadUserInputErrorValues } from '@hs-baumappe/redkit';
import OvertimeSendEmailMutation from './graphql/OvertimeSendEmail.mutation';
import {
  OvertimeDetailAlert,
  OvertimeDetailSearchParams,
} from '../OvertimeDetail/OvertimeDetail.utils';
import {
  OvertimeSendEmail,
  OvertimeSendEmailVariables,
} from './graphql/__generated__/OvertimeSendEmail.mutation';
import OvertimeDetailQuery from '../../hooks/useOvertime/graphql/OvertimeDetail.query';
import overtimeRoutes from '../overtimeRoutes';
import { SendOvertimeEmailInput } from '../../globalTypes';
import { EmailFormValues } from '../../components/EmailForm';

interface UseOvertimeSendEmailResponse {
  sendEmail: (
    values: EmailFormValues,
    formikHelpers: FormikHelpers<EmailFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useOvertimeSendEmail(overtimeId: string): UseOvertimeSendEmailResponse {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [mutation, { loading }] = useMutation<OvertimeSendEmail, OvertimeSendEmailVariables>(
    OvertimeSendEmailMutation,
    {
      // TODO: Remove that refetch Queries once the server-side mutation return type is fixed into Overtime!
      refetchQueries: [OvertimeDetailQuery],
    },
  );

  async function sendEmail(values: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) {
    if (loading) {
      return;
    }

    setError(undefined);

    try {
      await mutation({
        variables: {
          input: {
            id: overtimeId,
            recipients: values.toEmails,
            subject: values.subject,
            content: values.content,
            cc: values.ccEmails,
          },
        },
      });

      const searchParams = new URLSearchParams({
        [OvertimeDetailSearchParams.ALERT]: OvertimeDetailAlert.SEND,
      });

      navigate(
        createPath({
          pathname: overtimeRoutes.detail.generatePath({ overtimeId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = mapBadUserInputErrorValues<
        SendOvertimeEmailInput,
        FormikErrors<EmailFormValues>
      >(e, (errors) => ({
        ...errors,
        ccEmails: errors.cc,
        toEmails: errors.recipients,
      }));

      if (!formErrors) {
        setError(e);
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return {
    sendEmail,
    loading,
    error,
  };
}
