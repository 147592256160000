import { gql, TypedDocumentNode } from '@apollo/client';
import {
  UploadPublicOvertimeSignature,
  UploadPublicOvertimeSignatureVariables,
} from './__generated__/UploadPublicOvertimeSignature.mutation';

const UploadPublicOvertimeSignatureMutation: TypedDocumentNode<
  UploadPublicOvertimeSignature,
  UploadPublicOvertimeSignatureVariables
> = gql`
  mutation UploadPublicOvertimeSignature($input: UploadOvertimeSignatureInput!, $file: Upload!) {
    uploadOvertimeSignature(input: $input, file: $file) {
      id
      fullName
      type
      imagePath
      createdAt

      overtime {
        id

        signatures {
          id
          type
        }

        pdfPath
        status
        updatedAt
      }
    }
  }
`;

export default UploadPublicOvertimeSignatureMutation;
