import cx from 'classnames';
import { useClickable } from '@hs-baumappe/legacy-ui';

import './c-image-thumbnail.scss';

export type ImageThumbnailProps = JSX.IntrinsicElements['img'];

export default function ImageThumbnail({
  alt,
  className,
  onClick,
  onKeyDown,
  ...otherProps
}: ImageThumbnailProps): JSX.Element {
  const clickableProps = useClickable({ onClick, onKeyDown });

  return (
    <img
      alt={alt}
      className={cx('c-image-thumbnail', className)}
      {...otherProps}
      {...clickableProps}
    />
  );
}
