import { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import { Box, Container, Dialog, Flex, Icon, IconButton } from '@hs-baumappe/legacy-ui';

interface RouteDialogProps
  extends Omit<ComponentProps<typeof Dialog>, 'size' | 'animationType' | 'onRequestClose'> {
  header?: ReactNode;
  onRequestClose: () => void;
}

export default function RouteDialog({
  header,
  children,
  ...props
}: PropsWithChildren<RouteDialogProps>): JSX.Element {
  return (
    <Dialog {...props} size="fullscreen" animationType="slide-up">
      <Container stylex={{ paddingEnds: 'large' }}>
        <Flex alignItems="center">
          <Box stylex={{ flexGrow: '1' }}>{header}</Box>
          <IconButton icon={<Icon name="close" />} onClick={props.onRequestClose} />
        </Flex>

        <Box stylex={{ paddingTop: 'large' }}>{children}</Box>
      </Container>
    </Dialog>
  );
}
