import { gql } from '@apollo/client';

const OvertimeProjectFragment = gql`
  fragment OvertimeProject on Project {
    id

    name
    description
    draft
    hsProjectNumber
    projectNumber
  }
`;

export default OvertimeProjectFragment;
