import { Link, useLocation } from 'react-router-dom';
import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

import { Overtime } from '../../../../graphql/__generated__/OvertimeFragment';
import { createDocumentAssignPath, PDFSource } from '../../OvertimeDetail.utils';
import overtimeRoutes from '../../../overtimeRoutes';
import { createOvertimeLocation } from '../../../../Overtime.utils';
import { OvertimeScopes } from '../../../../Overtime.types';
import OvertimeRemoveButton from './ components/OvertimeRemoveButton';
import { LaunchDarklyFlagSet } from '../../../../launchdarkly/launchDarklyFlagSet';

interface OvertimeDetailSidebarBottomProps {
  overtime: Overtime;
  scopes: OvertimeScopes;
  projectId: string;
  pdfSource: PDFSource;
  signable: boolean;
  signedManually: boolean;
  onTogglePdfButtonClick: () => void;
  onRemoveManualPdfButtonClick: () => void;
  onRemoveSuccess: () => void;
}

export default function OvertimeDetailSidebarBottom({
  overtime,
  scopes,
  projectId,
  pdfSource,
  signable,
  signedManually,
  onTogglePdfButtonClick,
  onRemoveManualPdfButtonClick,
  onRemoveSuccess,
}: OvertimeDetailSidebarBottomProps): JSX.Element {
  const { t } = useTranslation();

  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();
  const location = useLocation();

  const signPermitted = scopes.signOvertimeDocument.isPermitted;
  const sendForSignaturePermitted = scopes.sendOvertimeDocumentSignatureMail.isPermitted;
  const removePermitted =
    scopes.removeDocumentsSimple.isPermitted || scopes.removeDocumentsAdvanced.isPermitted;

  return (
    <Stack gap="small-3x">
      {overtime.viewerCanAssign && (
        <Button
          component={Link}
          to={createDocumentAssignPath(overtime, projectId)}
          fullWidth
          endIcon={<Icon name="folder" />}
          className="qa-overtime-detail-assign-project-button"
        >
          {t('overtimeDetail.assign.button')}
        </Button>
      )}

      {signable && (
        <Button
          component={Link}
          to={overtimeRoutes.manualSign.generatePath({ overtimeId: overtime.id })}
          state={createOvertimeLocation(location)}
          fullWidth
          endIcon={<Icon name="cloud-upload" />}
          className="qa-overtime-detail-manual-upload-button"
        >
          {t('overtimeDetail.upload.button')}
        </Button>
      )}

      {overtime.viewerCanSign && signPermitted && (
        <Button
          component={Link}
          to={overtimeRoutes.sign.generatePath({ overtimeId: overtime.id })}
          state={createOvertimeLocation(location)}
          color="primary"
          fullWidth
          endIcon={<Icon name="border-color" />}
          className="qa-overtime-detail-sign-button"
        >
          {t('overtimeDetail.sign.button')}
        </Button>
      )}

      {signedManually && (
        <Button
          fullWidth
          endIcon={<Icon name="undo" />}
          onClick={onTogglePdfButtonClick}
          className={
            pdfSource === 'signed-manually'
              ? 'qa-overtime-detail-undo-button'
              : 'qa-overtime-detail-signed-report-button'
          }
        >
          {pdfSource === 'signed-manually'
            ? t('overtimeDetail.showGeneratedReportButton')
            : t('overtimeDetail.showManuallySignedReportButton')}
        </Button>
      )}

      {overtime.viewerCanArchive && archiveEnabled && (
        <Button
          component={Link}
          to={overtimeRoutes.archive.generatePath({ overtimeId: overtime.id })}
          state={createOvertimeLocation(location)}
          color="primary"
          fullWidth
          endIcon={<Icon name="archive" />}
          className="qa-overtime-detail-archive-button"
        >
          {t('overtimeDetail.archive.button')}
        </Button>
      )}

      {overtime.viewerCanSendExternalLink && sendForSignaturePermitted && (
        <Button
          component={Link}
          to={overtimeRoutes.sendExternalEmail.generatePath({
            overtimeId: overtime.id,
          })}
          state={createOvertimeLocation(location)}
          color="primary"
          fullWidth
          endIcon={<Icon name="email" />}
          className="qa-overtime-detail-send-email-button"
        >
          {t('overtimeDetail.email.button')}
        </Button>
      )}

      {overtime.viewerCanRemove && removePermitted && (
        <OvertimeRemoveButton
          overtimeId={overtime.id}
          overtimeName={overtime.name}
          onSuccess={onRemoveSuccess}
        />
      )}

      {overtime.viewerCanRemoveManualPdf && pdfSource === 'signed-manually' && removePermitted && (
        <Button
          variant="text"
          color="error"
          onClick={onRemoveManualPdfButtonClick}
          className="qa-overtime-detail-manual-button"
        >
          {t('overtimeDetail.removeManuallyUploadedReportButtonText')}
        </Button>
      )}
    </Stack>
  );
}
