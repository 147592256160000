import { gql, TypedDocumentNode } from '@apollo/client';
import OvertimeFragment from '../../../graphql/OvertimeFragment';
import { OvertimeSave, OvertimeSaveVariables } from './__generated__/OvertimeSave.mutation';

const OvertimeSaveMutation: TypedDocumentNode<OvertimeSave, OvertimeSaveVariables> = gql`
  mutation OvertimeSave($input: OvertimeSaveInput!) {
    overtimeSave(input: $input) {
      ...Overtime
    }
  }

  ${OvertimeFragment}
`;

export default OvertimeSaveMutation;
