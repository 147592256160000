import { Button, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

interface PublicOvertimeExpiredProps {
  contactPersonEmail: string;
}

export default function PublicOvertimeExpired({
  contactPersonEmail,
}: PublicOvertimeExpiredProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="row u-justify-content-center">
      <div className="col col--sm-8 u-display-flex u-flex-direction-column u-text-align-center">
        <Text component="h2" variant="title-medium">
          {t('overtimeCustomerExpired.title')}
        </Text>

        <Text component="p">{t('overtimeCustomerExpired.description')}</Text>

        <div className="row u-justify-content-center u-margin-top">
          <div className="col col--sm-6">
            <Button
              component="a"
              href={`mailto:${contactPersonEmail}`}
              endIcon={<Icon name="email" />}
              color="primary"
              fullWidth
            >
              {t('overtimeCustomerExpired.buttonText')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
