import { OvertimeUploadImageWidgetFormValues } from './OvertimeUploadImageWidget';
import { OvertimeImageInput } from '../../../../globalTypes';
import { ImageUploadWithLabelFormValues } from './OvertimeUploadImageWidgetEntry/components/ImageUploadWithLabel';

export function castOvertimeUploadImageWidgetFormValuesToOvertimeImages(
  entries: OvertimeUploadImageWidgetFormValues['images'],
): OvertimeImageInput[] {
  return entries.reduce((result, entry) => {
    if (!entry) {
      return result;
    }

    if (entry.imageId) {
      return [...result, { imageId: entry.imageId, label: entry.label || undefined }];
    }

    return result;
  }, [] as OvertimeImageInput[]);
}

export function castOvertimeImagesToUploadImageWidgetFormValues(
  entries: any[],
): OvertimeUploadImageWidgetFormValues['images'] {
  return entries.map<ImageUploadWithLabelFormValues>(({ id, label, url, thumbnail }) => ({
    orderId: Math.random().toString(36).substr(2),
    imageId: id,
    label: label || undefined,
    imageUrl: url,
    imageThumbnailUrl: thumbnail,
  }));
}
