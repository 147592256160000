import { useViewer } from '@hs-baumappe/web-auth';
import { ApolloError, useQuery } from '@apollo/client';
import OvertimeDetailQuery from './graphql/OvertimeDetail.query';
import { Overtime } from '../../graphql/__generated__/OvertimeFragment';
import { OvertimeProject } from '../../graphql/__generated__/OvertimeProject.fragment';
import { OvertimeScopes } from '../../Overtime.types';

interface UseOvertimeDataResponse {
  overtime: Overtime;
  scopes: OvertimeScopes;
  project: OvertimeProject;
}

interface UseOvertimeResponse {
  data?: UseOvertimeDataResponse;
  loading: boolean;
  error?: ApolloError;
}

export default function useOvertime(overtimeId: string): UseOvertimeResponse {
  const { viewer } = useViewer();
  const { data, loading, error } = useQuery(OvertimeDetailQuery, {
    variables: {
      overtimeId,
    },
  });

  const overtime = data?.overtime;
  const project = data?.overtime.project;
  const scopes = viewer?.scopes;

  return {
    data: overtime && project && scopes ? { overtime, project, scopes } : undefined,
    loading,
    error,
  };
}
