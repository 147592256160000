import { useEffect } from 'react';
import { Alert, Icon, Stack, useTimer } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import useOvertimeDetailAlert from './hooks/useOvertimeDetailAlert';
import { OvertimeDetailAlert } from '../../OvertimeDetail.utils';

interface OvertimeDetailAlertsProps {
  name: string;
  signedManually: boolean;
}

export default function OvertimeDetailAlerts({
  name,
  signedManually,
}: OvertimeDetailAlertsProps): JSX.Element | null {
  const { t } = useTranslation();

  const { alert, clearAlert } = useOvertimeDetailAlert();
  const { setTimeoutTimer, clearAllTimers } = useTimer();

  useEffect(() => {
    if (!alert) {
      return;
    }

    setTimeoutTimer(clearAlert, 5000);

    return () => clearAllTimers();
  }, [alert, clearAlert, clearAllTimers, setTimeoutTimer]);

  function getAlertContent() {
    switch (alert) {
      case OvertimeDetailAlert.SEND:
        return t('overtimeDetail.sendEmail.alert');
      case OvertimeDetailAlert.ARCHIVED:
        return t('overtimeDetail.archive.alert', { name });
      case OvertimeDetailAlert.SIGNED_MANUALLY:
        return t('overtimeDetail.manuallySignedPdf.success');
      default:
        return '';
    }
  }

  if (!alert && !signedManually) {
    return null;
  }

  return (
    <Stack gap="medium">
      {alert && (
        <Alert color="success" className="qa-alert-message" endSection={<Icon name="check" />}>
          {getAlertContent()}
        </Alert>
      )}

      {signedManually && (
        <Alert color="warning" className="qa-alert-message">
          {t('overtimeDetail.manuallySignedPdf.signed')}
        </Alert>
      )}
    </Stack>
  );
}
