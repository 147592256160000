import { gql, TypedDocumentNode } from '@apollo/client';
import {
  UpdateOvertimeDriveDocumentLastUsed,
  UpdateOvertimeDriveDocumentLastUsedVariables,
} from './__generated__/UpdateOvertimeDriveDocumentLastUsedMutation';

const UpdateOvertimeDriveDocumentLastUsedMutation: TypedDocumentNode<
  UpdateOvertimeDriveDocumentLastUsed,
  UpdateOvertimeDriveDocumentLastUsedVariables
> = gql`
  mutation UpdateOvertimeDriveDocumentLastUsed($input: [DriveDocumentLastUsedUpdateInput!]!) {
    driveDocumentsLastUsedUpdate(input: $input) {
      id
    }
  }
`;

export default UpdateOvertimeDriveDocumentLastUsedMutation;
