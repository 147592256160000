import { TypedDocumentNode, gql } from '@apollo/client';
import {
  DriveDocumentParentFolders,
  DriveDocumentParentFoldersVariables,
} from './__generated__/DriveDocumentParentFolders.query';

const DriveDocumentParentFoldersQuery: TypedDocumentNode<
  DriveDocumentParentFolders,
  DriveDocumentParentFoldersVariables
> = gql`
  query DriveDocumentParentFolders($documentId: ID!) {
    driveDocument(id: $documentId) {
      id

      parentFolders {
        id
        name
        type
        depth
      }
    }
  }
`;

export default DriveDocumentParentFoldersQuery;
