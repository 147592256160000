import { Location } from 'react-router-dom';
import { OvertimeStatus, SignatureType } from './globalTypes';
import { OvertimeLocationState } from './routes/Overtime.route-types';
import { Overtime } from './graphql/__generated__/OvertimeFragment';

interface GetOvertimeMetaResponse {
  signedManually: boolean;
  signedByEmployee: boolean;
  signed: boolean;
  signable: boolean;
}

const SIGNABLE_STATUSES = [OvertimeStatus.Open, OvertimeStatus.Sent];

function hasSignatureInType(signatures: Overtime['signatures'], type: SignatureType) {
  return signatures.some((signature) => signature.type === type);
}

export function getOvertimeMeta(overtime: Overtime): GetOvertimeMetaResponse {
  const signedManually = !!overtime.manualPdfPath;
  const signedByEmployee = hasSignatureInType(overtime.signatures, SignatureType.HeinrichSchmid);
  const signedByCustomer = hasSignatureInType(overtime.signatures, SignatureType.Customer);
  const signed = signedManually || (signedByEmployee && signedByCustomer);
  const signable = !signedManually && SIGNABLE_STATUSES.includes(overtime.status);

  return {
    signedManually,
    signedByEmployee,
    signed,
    signable,
  };
}

export function createOvertimeLocation(location: Location): OvertimeLocationState {
  return {
    backgroundLocation: { ...location, search: '' },
  };
}
