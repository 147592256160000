import { WorkerRole } from '../../globalTypes';
import {
  ImageUploadWithLabelFormValues,
  imageUploadWithLabelValidationSchema,
} from './components/OvertimeUploadImageWidget/OvertimeUploadImageWidgetEntry/components/ImageUploadWithLabel';
import yup, { InferObjectToYupShape, RTEContentRequired, validNumberString } from '../../yup';
import i18n from '../../i18n';

interface OvertimeFormEmployee {
  fullName: string;
  role: WorkerRole;
  hours?: string;
  extraHours?: string;
  materialSurcharge?: string;
  machinesHours?: string;
}

export interface OvertimeFormValues {
  no?: string;
  projectHsId?: string;
  projectClient?: string;
  workName: string;
  workDescription: string;
  orderDate: string;
  date: string;
  endDate?: string;
  employees: OvertimeFormEmployee[];
  images: ImageUploadWithLabelFormValues[];
}

const MAX_ALLOWED_NUMBER_VALUE = 99999999.9;
export const validationSchemaEmployeeArray = yup
  .object({
    id: yup.string(),
    fullName: yup.string().required(),
    role: yup.mixed<WorkerRole>().required().oneOf(Object.values(WorkerRole)),
    hours: validNumberString().max(MAX_ALLOWED_NUMBER_VALUE),
    extraHours: validNumberString().max(MAX_ALLOWED_NUMBER_VALUE),
    materialSurcharge: yup.string(),
    machinesHours: validNumberString().max(MAX_ALLOWED_NUMBER_VALUE),
  })
  .required();

export const validationSchema = yup.object().shape<InferObjectToYupShape<OvertimeFormValues>>({
  no: yup.number().typeError(i18n.t('validation.reportNumber.invalid.message')),
  projectHsId: yup.string(),
  projectClient: yup.string().required(),
  workName: yup.string().required(),
  workDescription: RTEContentRequired(),
  orderDate: yup.lazy(() =>
    yup
      .date()
      .typeError(i18n.t('validation.date.typeError'))
      .required()
      .max(new Date(), i18n.t('validation.date.future')),
  ),
  date: yup.lazy(() =>
    yup
      .date()
      .typeError(i18n.t('validation.date.typeError'))
      .required()
      .max(new Date(), i18n.t('validation.date.future')),
  ),
  endDate: yup
    .date()
    .typeError(i18n.t('validation.date.typeError'))
    .min(yup.ref('orderDate'), i18n.t('validation.date.past')),
  employees: yup.array<OvertimeFormEmployee>().of(validationSchemaEmployeeArray).defined(),
  images: yup.array().of(imageUploadWithLabelValidationSchema.defined()).defined(),
});

export function getOvertimeFormInitialValues(
  initialValues?: Partial<OvertimeFormValues>,
): OvertimeFormValues {
  const employee: OvertimeFormEmployee = {
    fullName: '',
    role: WorkerRole.Fa,
  };

  return {
    no: '',
    projectHsId: initialValues?.projectHsId ? initialValues.projectHsId : '',
    projectClient: initialValues?.projectHsId ? initialValues.projectHsId : '',
    workName: '',
    workDescription: '',
    orderDate: '',
    date: '',
    endDate: '',
    employees: [employee],
    images: [],
    ...initialValues,
  };
}

function castOvertimeFormEmployeeValues(
  employees: OvertimeFormValues['employees'],
): OvertimeFormValues['employees'] {
  return employees.map((employee) => ({
    ...employee,
    materialSurcharge: employee.materialSurcharge || '0',
    extraHours: employee.extraHours ? employee.extraHours.replace(/,/, '.') : undefined,
    hours: employee.hours ? employee.hours.replace(/,/, '.') : undefined,
    machinesHours: employee.machinesHours ? employee.machinesHours.replace(/,/, '.') : undefined,
  }));
}

export function castOvertimeFormValues(values: OvertimeFormValues): OvertimeFormValues {
  return {
    ...values,
    employees: castOvertimeFormEmployeeValues(values.employees),
  };
}
