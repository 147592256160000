import { gql, TypedDocumentNode } from '@apollo/client';
import {
  OvertimeConvertImageAttachments,
  OvertimeConvertImageAttachmentsVariables,
} from './__generated__/OvertimeConvertImageAttachments.mutation';

const OvertimeConvertImageAttachmentsMutation: TypedDocumentNode<
  OvertimeConvertImageAttachments,
  OvertimeConvertImageAttachmentsVariables
> = gql`
  mutation OvertimeConvertImageAttachments($input: [ID!]!) {
    convertCustomFilesToImages(input: $input) {
      id

      url
    }
  }
`;

export default OvertimeConvertImageAttachmentsMutation;
