import { useEffect, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { useViewer } from '@hs-baumappe/web-auth';
import OvertimeSignDetailQuery from './graphql/OvertimeSignDetail.query';
import { OvertimeSignDetail } from './graphql/__generated__/OvertimeSignDetail.query';
import { SignatureType } from '../../globalTypes';
import urlToBase64 from '../../utils/urlToBase64';

interface UseOvertimeSignDetailResponse {
  overtime?: OvertimeSignDetail['overtime'];
  employeeSignature?: OvertimeSignDetail['overtime']['signatures'][number];
  customerSignature?: OvertimeSignDetail['overtime']['signatures'][number];
  employeeNames: string[];
  viewerFullName?: string;
  viewerSignatureBase64?: string;
  loading: boolean;
  error?: ApolloError;
}

export default function useOvertimeSignDetails(overtimeId: string): UseOvertimeSignDetailResponse {
  const { viewer } = useViewer();
  const { data, loading, error } = useQuery(OvertimeSignDetailQuery, {
    variables: {
      overtimeId,
    },
  });

  const overtime = data?.overtime;
  const employeeSignature =
    overtime && overtime.signatures.find(({ type }) => type === SignatureType.HeinrichSchmid);
  const customerSignature =
    overtime && overtime.signatures.find(({ type }) => type === SignatureType.Customer);

  const viewerFullName = viewer ? `${viewer.firstName} ${viewer.lastName}` : undefined;

  const employeeNames = overtime
    ? (overtime.project.contactPeople
        .map((contactPerson) => contactPerson.name)
        .filter(Boolean) as string[])
    : [];

  const viewerSignatureUrl = data?.viewer.signature ?? undefined;
  const [viewerSignatureBase64, setViewerSignatureBase64] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (viewerSignatureUrl) {
      urlToBase64(viewerSignatureUrl).then((result) => setViewerSignatureBase64(result));
    }
  }, [viewerSignatureUrl]);

  return {
    overtime,
    employeeSignature,
    employeeNames,
    customerSignature,
    viewerFullName,
    viewerSignatureBase64,
    loading,
    error,
  };
}
