import { gql } from '@apollo/client';

const OvertimeFragment = gql`
  fragment Overtime on Overtime {
    id

    name
    alias
    no
    pdfPath
    manualPdfPath
    status

    viewerCanRemove
    viewerCanUpdate
    viewerCanArchive
    viewerCanAssign
    viewerCanSign
    viewerCanSendExternalLink
    viewerCanRemoveManualPdf

    signatures {
      id

      type
    }
  }
`;

export default OvertimeFragment;
