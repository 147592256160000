import { ReactNode, useRef } from 'react';
import { Box, Button, FlexGrid, FlexGridColumn, Icon, uniqueId } from '@hs-baumappe/legacy-ui';
import {
  ArchiveForm as BaseArchiveForm,
  ArchiveFormProps as BaseArchiveFormProps,
  createArchiveFormValidationSchema,
} from '@hs-baumappe/forms';
import { useTranslation } from 'react-i18next';

import yup from '../../yup';

type ArchiveFormBaseProps = Omit<
  BaseArchiveFormProps,
  'id' | 'usernameInputProps' | 'passwordInputProps'
>;

export interface ArchiveFormProps extends ArchiveFormBaseProps {
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  loading?: boolean;
  submitButtonText?: ReactNode;
}

export default function ArchiveForm({
  leftSection,
  rightSection,
  loading,
  submitButtonText,
  ...otherProps
}: ArchiveFormProps): JSX.Element {
  const { t } = useTranslation();
  const id = useRef(uniqueId('archive-form-'));

  return (
    <FlexGrid>
      <FlexGridColumn sm={8}>
        {leftSection && <Box stylex={{ marginBottom: 'medium' }}>{leftSection}</Box>}

        <FlexGrid>
          <FlexGridColumn sm={6}>
            <BaseArchiveForm
              {...otherProps}
              id={id.current}
              validationSchema={createArchiveFormValidationSchema(yup)}
              usernameInputProps={{
                label: t('archiveForm.fields.username.label'),
                placeholder: t('archiveForm.fields.username.placeholder'),
              }}
              passwordInputProps={{
                label: t('archiveForm.fields.password.label'),
                placeholder: t('archiveForm.fields.password.placeholder'),
              }}
            />
          </FlexGridColumn>
        </FlexGrid>
      </FlexGridColumn>

      <FlexGridColumn sm={4}>
        <Button
          form={id.current}
          type="submit"
          endIcon={<Icon name="archive" />}
          color="primary"
          loading={loading}
          fullWidth
        >
          {submitButtonText ?? t('archiveForm.submitButtonText')}
        </Button>

        {rightSection && <Box stylex={{ marginTop: 'medium' }}>{rightSection}</Box>}
      </FlexGridColumn>
    </FlexGrid>
  );
}
