import { Details, Divider, Summary, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import OvertimeDetailLogsDetail from './components/OvertimeDetailLogsDetail';

interface OvertimeDetailLogsProps {
  overtimeId: string;
}

export default function OvertimeDetailLogs({ overtimeId }: OvertimeDetailLogsProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Details
      renderSummary={({ onClick }) => (
        <Summary onClick={onClick}>
          <Text variant="label-large">{t('logContainer.title')}</Text>
        </Summary>
      )}
    >
      <Divider role="none" />

      <OvertimeDetailLogsDetail overtimeId={overtimeId} />
    </Details>
  );
}
