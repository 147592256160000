import { Viewer } from '@hs-baumappe/web-auth';
import { TFunction } from 'i18next';
import { EmailFormValues } from '../../components/EmailForm';
import { Overtime } from '../../graphql/__generated__/OvertimeFragment';
import { OvertimeProject } from '../../graphql/__generated__/OvertimeProject.fragment';

export function getEmailFormInitialValues(
  overtime: Overtime,
  project: OvertimeProject,
  viewer: Viewer,
  t: TFunction,
): Partial<EmailFormValues> {
  return {
    subject: t('sendOvertimePdfEmail.initialValues.subject', {
      projectName: project.name,
      overtimeNo: overtime.alias || overtime.no,
    }),
    content: viewer.signatureEmail
      ? t('sendOvertimePdfEmail.initialValues.content', {
          ...viewer,
          email: viewer.signatureEmail,
        })
      : t('sendOvertimePdfEmail.initialValues.content_with_no_email', {
          ...viewer,
        }),
    documentIds: [overtime.id],
  };
}
