import { ApolloError, useMutation } from '@apollo/client';
import SignOvertimeMutation from '../../graphql/UploadPublicOvertimeSignature.mutation';
import { SignatureType } from '../../../../../../globalTypes';
import b64toBlob from '../../../../../../utils/b64toBlob';
import { GetPublicOvertimeDetailQuery } from '../../../PublicOvertimeDetailContainer/graphql/GetPublicOvertimeDetail.query';
import { GetPublicOvertimePdfPathQuery } from '../../../PublicOvertimeDetailContainer/PublicOvertimeDetailContainerBody/graphql/GetPublicOvertimePdfPath.query';

interface OvertimeCustomerSignDto {
  name: string;
  signature: string;
}

interface UseOvertimeCustomerSignResponse {
  sign: (data: OvertimeCustomerSignDto) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

interface UseOvertimeCustomerSignOptions {
  overtimeId: string;
  onSuccess: () => void;
}

export default function useOvertimeCustomerSign({
  overtimeId,
  onSuccess,
}: UseOvertimeCustomerSignOptions): UseOvertimeCustomerSignResponse {
  const [uploadSignatureMutation, { loading, error }] = useMutation(SignOvertimeMutation);

  async function sign({ name, signature }: OvertimeCustomerSignDto) {
    if (loading) return;

    await uploadSignatureMutation({
      variables: {
        input: {
          fullName: name,
          type: SignatureType.Customer,
          overtime: overtimeId,
        },
        file: b64toBlob(signature.replace(/^[^,]+,/, ''), 'image/png'),
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GetPublicOvertimeDetailQuery,
          variables: {
            overtimeId,
          },
        },
        {
          query: GetPublicOvertimePdfPathQuery,
          variables: {
            overtimeId,
          },
        },
      ],
    });

    onSuccess();
  }

  return {
    sign,
    loading,
    error,
  };
}
