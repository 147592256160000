import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';

import useDriveDocumentTagDetails from '../../../../hooks/useDriveDocumentTagDetails';
import useOvertimeSendEmail from '../../useOvertimeSendEmail';
import ErrorState from '../../../../components/ErrorState';
import EmailForm from '../../../../components/EmailForm';
import { Overtime } from '../../../../graphql/__generated__/OvertimeFragment';
import { OvertimeProject } from '../../../../graphql/__generated__/OvertimeProject.fragment';
import { getEmailFormInitialValues } from '../../OvertimeSendEmail.utils';
import { formatBytes } from '../../../../utils/number';

interface OvertimeSendEmailBodyProps {
  overtime: Overtime;
  project: OvertimeProject;
  onDocumentIdsValueChange: () => void;
  onDirtyStateChange: () => void;
}

export default function OvertimeSendEmailBody({
  overtime,
  project,
  onDocumentIdsValueChange,
  onDirtyStateChange,
}: OvertimeSendEmailBodyProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  const { driveDocument } = useDriveDocumentTagDetails(overtime.id);
  const {
    sendEmail,
    loading: emailSending,
    error: emailSendError,
  } = useOvertimeSendEmail(overtime.id);

  if (!viewer) {
    return null;
  }

  const documents = [
    {
      id: overtime.id,
      name: overtime.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
    },
  ];

  return (
    <>
      {emailSendError && <ErrorState error={emailSendError} />}

      <EmailForm
        projectId={project.id}
        initialValues={getEmailFormInitialValues(overtime, project, viewer, t)}
        documents={documents}
        submitButtonText={t('sendOvertimeEmailForm.submitButton')}
        loading={emailSending}
        onSubmit={sendEmail}
        onDocumentIdsValueChange={onDocumentIdsValueChange}
        onDirtyStateChange={onDirtyStateChange}
      />
    </>
  );
}
