import { useNavigate } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import { SignatureFormValues } from '@hs-baumappe/forms';
import OvertimeUploadSignatureMutation from './graphql/OvertimeUploadSignature.mutation';
import b64toBlob from '../../utils/b64toBlob';
import overtimeRoutes from '../overtimeRoutes';
import { SignatureType } from '../../globalTypes';

interface OvertimeSignDto {
  type: SignatureType;
  values: SignatureFormValues;
}

interface UseOvertimeSignResponse {
  sign: (data: OvertimeSignDto) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useOvertimeSign(overtimeId: string): UseOvertimeSignResponse {
  const navigate = useNavigate();
  const [mutation, { loading, error }] = useMutation(OvertimeUploadSignatureMutation);

  async function sign({ type, values }: OvertimeSignDto) {
    if (loading) {
      return;
    }

    await mutation({
      variables: {
        input: {
          type,
          fullName: values.name,
          overtime: overtimeId,
        },
        file: b64toBlob(values.signature.replace(/^[^,]+,/, ''), 'image/png'),
      },
    });

    navigate(overtimeRoutes.detail.generatePath({ overtimeId }));
  }

  return {
    sign,
    loading,
    error,
  };
}
