import { OvertimeStatus } from '../../../../../globalTypes';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getTextColorByOvertimeStatus(status: OvertimeStatus) {
  switch (status) {
    case OvertimeStatus.Signed:
    case OvertimeStatus.ManuallySigned:
    case OvertimeStatus.Archived:
      return 'success';
    default:
      return 'muted';
  }
}
