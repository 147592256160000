import { Flex, Link } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

export default function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Flex
      gap="large-x"
      justifyContent="center"
      stylex={{ paddingEnds: 'large', backgroundColor: 'blue-gray-800' }}
    >
      <Link
        variant="body-large"
        color="white"
        underline="none"
        href="https://www.heinrich-schmid.com/kontakt/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('footer.contact')}
      </Link>
      <Link
        variant="body-large"
        color="white"
        underline="none"
        href="https://www.heinrich-schmid.com/impressum/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('footer.imprint')}
      </Link>
      <Link
        variant="body-large"
        color="white"
        underline="none"
        href="https://www.heinrich-schmid.com/datenschutz/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('footer.privacy')}
      </Link>
    </Flex>
  );
}
