import { useTranslation } from 'react-i18next';
import { useViewer } from '@hs-baumappe/web-auth';
import { getEmailFormInitialValues } from '../../OvertimeSendExternalEmail.utils';
import useOvertimeSendExternalEmail from '../../useOvertimeSendExternalEmail';
import { OvertimeProject } from '../../../../graphql/__generated__/OvertimeProject.fragment';
import { Overtime } from '../../../../graphql/__generated__/OvertimeFragment';
import ErrorState from '../../../../components/ErrorState';
import EmailForm from '../../../../components/EmailForm';

interface OvertimeSendExternalEmailBodyProps {
  overtime: Overtime;
  project: OvertimeProject;
  onDirtyStateChange: () => void;
}

export default function OvertimeSendExternalEmailBody({
  overtime,
  project,
  onDirtyStateChange,
}: OvertimeSendExternalEmailBodyProps): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const { sendExternalEmail, loading, error } = useOvertimeSendExternalEmail(overtime.id);

  if (!viewer) {
    return null;
  }

  return (
    <>
      {error && <ErrorState error={error} />}

      <EmailForm
        projectId={project.id}
        initialValues={getEmailFormInitialValues(overtime, project, viewer, t)}
        loading={loading}
        onSubmit={sendExternalEmail}
        onDirtyStateChange={onDirtyStateChange}
      />
    </>
  );
}
