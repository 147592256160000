import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

interface SendOvertimePdfDialogProps {
  open: boolean;
  onRequestClose: () => void;
  onSendWithoutSignatureClick: () => void;
  onSignNowClick: () => void;
}

export default function SendOvertimePdfDialog({
  open,
  onRequestClose,
  onSendWithoutSignatureClick,
  onSignNowClick,
}: SendOvertimePdfDialogProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Dialog open={open} role="alertdialog" size="xsmall" onRequestClose={onRequestClose}>
      <DialogHeader
        rightSlot={<IconButton icon={<Icon name="close" />} onClick={onRequestClose} />}
      >
        <Text variant="title-small">{t('overtimePdf.pdfHasNotSignedYetModal.title')}</Text>
      </DialogHeader>

      <DialogBody>
        <Text>{t('overtimePdf.pdfHasNotSignedYetModal.body')}</Text>
      </DialogBody>

      <DialogActions direction="column">
        <Button onClick={onSendWithoutSignatureClick}>
          {t('overtimePdf.pdfHasNotSignedYetModal.sendPdfWithoutSignatureButtonText')}
        </Button>

        <Button color="primary" endIcon={<Icon name="border-color" />} onClick={onSignNowClick}>
          {t('overtimePdf.pdfHasNotSignedYetModal.signNowButtonText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
