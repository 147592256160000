import { lazy, Suspense } from 'react';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { Alert, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import { DriveImageGalleryDocument } from './DriveImageGalleryContainer.types';
import RouteDialog from '../RouteDialog';
import ErrorBoundary from '../../ErrorBoundary';

interface DriveImageGalleryContainerProps {
  open: boolean;
  onDialogClose: () => void;
  projectId: string;
  onDocumentsSelected: (documents: DriveImageGalleryDocument[]) => void;
}

// eslint-disable-next-line import/no-unresolved
const DriveImageGallery = lazy(() => import('driveWeb/ImageGallery'));

export default function DriveImageGalleryContainer({
  open,
  onDialogClose,
  projectId,
  onDocumentsSelected,
}: DriveImageGalleryContainerProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <RouteDialog
      open={open}
      onRequestClose={onDialogClose}
      header={<Text variant="body-large">{t('driveImageGallery.title')}</Text>}
    >
      <ErrorBoundary fallback={<Alert color="error">{t('driveMFLoadError')}</Alert>}>
        <Suspense fallback={<LayoutLoading />}>
          <DriveImageGallery projectId={projectId} onDocumentsSelected={onDocumentsSelected} />
        </Suspense>
      </ErrorBoundary>
    </RouteDialog>
  );
}
