import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import RemoveManuallyUploadedOvertimeMutation from './graphql';
import ErrorState from '../ErrorState';

interface RemoveManuallyUploadedReportModalProps {
  open: boolean;
  title: string;
  overtimeId: string;
  onSuccess: () => void;
  onRequestDismiss: () => void;
}

export default function RemoveManuallyUploadedReportModal({
  open,
  title,
  overtimeId,
  onSuccess,
  onRequestDismiss,
}: RemoveManuallyUploadedReportModalProps): JSX.Element {
  const { t } = useTranslation();
  const [mutation, { error, loading }] = useMutation(RemoveManuallyUploadedOvertimeMutation);

  async function handleRequestSubmit() {
    if (loading) {
      return;
    }

    await mutation({
      variables: {
        input: {
          overtime: overtimeId,
        },
      },
    });

    onSuccess();
  }

  return (
    <>
      {error && <ErrorState error={error} />}
      <Dialog open={open} role="alertdialog" size="xsmall">
        <DialogHeader
          rightSlot={
            <IconButton
              onClick={onRequestDismiss}
              icon={<Icon name="close" />}
              disabled={loading}
            />
          }
        >
          <Text variant="title-small">{title}</Text>
        </DialogHeader>
        <DialogBody>
          <Text>{t('removeManuallyUploadedReportModal.bodyText')}</Text>
        </DialogBody>
        <DialogActions direction="column">
          <Button onClick={onRequestDismiss} disabled={loading}>
            {t('removeManuallyUploadedReportModal.dismissButtonText')}
          </Button>

          <Button
            color="error"
            endIcon={<Icon name="delete" />}
            loading={loading}
            onClick={handleRequestSubmit}
          >
            {t('removeManuallyUploadedReportModal.submitButtonText')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
