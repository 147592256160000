import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import useDriveDocumentTagDetails from '../../hooks/useDriveDocumentTagDetails';
import useOvertimeArchive from './useOvertimeArchive';
import RouteDialog from '../../components/RouteDialog';
import ErrorState from '../../components/ErrorState';
import ArchiveForm from '../../components/ArchiveForm';
import { Overtime } from '../../graphql/__generated__/OvertimeFragment';
import overtimeRoutes from '../overtimeRoutes';
import { OvertimeLocationState } from '../Overtime.route-types';
import { formatBytes } from '../../utils/number';
import { LaunchDarklyFlagSet } from '../../launchdarkly/launchDarklyFlagSet';

interface OvertimeArchiveProps {
  overtime: Overtime;
}

export default function OvertimeArchive({ overtime }: OvertimeArchiveProps): JSX.Element {
  const { t } = useTranslation();
  const { archive: archiveEnabled } = useFlags<LaunchDarklyFlagSet>();

  const location = useLocation<OvertimeLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const { sapCredentials } = useSapCredentials();

  const { driveDocument, loading } = useDriveDocumentTagDetails(overtime.id);
  const { archive, loading: archiving, error } = useOvertimeArchive(overtime.id);

  function handleDialogRequestClose() {
    setDialogOpen(false);
  }

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(-1);
      return;
    }

    navigate(overtimeRoutes.detail.generatePath({ overtimeId: overtime.id }));
  }, [location.state?.backgroundLocation, navigate, overtime.id]);

  if (!overtime.viewerCanArchive || !archiveEnabled) {
    return (
      <Navigate to={overtimeRoutes.detail.generatePath({ overtimeId: overtime.id })} replace />
    );
  }

  if (loading) {
    return <LayoutLoading />;
  }

  const documents = [
    {
      id: overtime.id,
      name: overtime.name,
      size: typeof driveDocument?.size === 'number' ? formatBytes(driveDocument.size) : undefined,
    },
  ];

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
          {t('archiveOvertimeModal.title')}
        </Text>
      }
    >
      {error && <ErrorState error={error} />}

      <ArchiveForm
        leftSection={<Text variant="body-small">{t('archiveModals.informationText')}</Text>}
        rightSection={<Text variant="body-small">{t('archiveModals.warningText')}</Text>}
        initialValues={{
          username: sapCredentials?.username,
          password: sapCredentials?.password,
          documents,
        }}
        onDocumentsValueChange={handleDialogRequestClose}
        onSubmit={archive}
        loading={archiving}
      />
    </RouteDialog>
  );
}
