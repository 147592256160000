import { Form, FormikProvider, useFormik } from 'formik';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Icon, Text } from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';

import UploadInputGroup from '../../../../components/UploadInputGroup';

export interface UploadManuallySignedReportFormValues {
  manuallySignedReport?: File;
}

interface UploadManuallySignedReportFormProps {
  onSubmit: (values: UploadManuallySignedReportFormValues) => void;
  onDirty?: () => void;
  helperText?: ReactNode;
  loading?: boolean;
  submitButtonText?: ReactNode;
}

const INITIAL_VALUES: UploadManuallySignedReportFormValues = {
  manuallySignedReport: undefined,
};

function UploadManuallySignedReportForm({
  onDirty,
  onSubmit,
  helperText,
  loading,
  submitButtonText,
}: UploadManuallySignedReportFormProps): JSX.Element {
  const { t } = useTranslation();

  const formik = useFormik<UploadManuallySignedReportFormValues>({
    onSubmit,
    initialValues: INITIAL_VALUES,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const { values, dirty } = formik;

  useEffect(() => {
    if (dirty && onDirty) {
      onDirty();
    }
  }, [dirty, onDirty]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="row u-justify-content-space-between">
          <div className="col col--sm-6 u-padding-top-small">
            {helperText && <Text className="u-margin-bottom-small">{helperText}</Text>}
            <FormikFormField
              control={UploadInputGroup}
              name="manuallySignedReport"
              className="u-margin-top-large"
              label={t('uploadManuallySignedReportForm.fileInput.label')}
              placeholder={t('uploadManuallySignedReportForm.fileInput.placeholder')}
              type="file"
              accept="application/pdf"
            />
          </div>
          <div className="col col--sm-6 col--md-4">
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button
                type="submit"
                color="primary"
                fullWidth
                endIcon={<Icon name="save" />}
                disabled={!values.manuallySignedReport}
                loading={loading}
              >
                {submitButtonText || t('uploadManuallySignedReportForm.submit')}
              </Button>
            </Box>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default UploadManuallySignedReportForm;
