import { Viewer } from '@hs-baumappe/web-auth';
import { TFunction } from 'i18next';
import { EmailFormValues } from '../../components/EmailForm';
import config from '../../config';
import { Overtime } from '../../graphql/__generated__/OvertimeFragment';
import { OvertimeProject } from '../../graphql/__generated__/OvertimeProject.fragment';

export function getEmailFormInitialValues(
  overtime: Overtime,
  project: OvertimeProject,
  viewer: Viewer,
  t: TFunction,
): Partial<EmailFormValues> {
  return {
    subject: t('sendOvertimeEmailForm.subject.initial', {
      projectName: project.name,
      overtimeNo: overtime.alias || overtime.no,
    }),
    content:
      viewer && viewer.signatureEmail
        ? t('sendOvertimeEmailForm.content.initial', {
            overtimeId: overtime.id,
            externalHost: config.HOST_URL,
            ...viewer,
            email: viewer.signatureEmail,
          })
        : t('sendOvertimeEmailForm.content.initial_with_no_email', {
            overtimeId: overtime.id,
            externalHost: config.HOST_URL,
            ...viewer,
          }),
  };
}
