import { ApolloError, ApolloQueryResult, NetworkStatus, useQuery } from '@apollo/client';
import { GetPublicOvertimeDetailQuery } from '../../graphql/GetPublicOvertimeDetail.query';
import { PublicOvertimeFragment } from '../../graphql/__generated__/PublicOvertime.fragment';
import {
  GetPublicOvertimeDetail,
  GetPublicOvertimeDetailVariables,
} from '../../graphql/__generated__/GetPublicOvertimeDetail.query';

interface UsePublicOvertimeDetailsResponse {
  publicOvertime?: PublicOvertimeFragment;
  loading: boolean;
  error?: ApolloError;
  refetch: (
    variables?: GetPublicOvertimeDetailVariables,
  ) => Promise<ApolloQueryResult<GetPublicOvertimeDetail>>;
}

export default function usePublicOvertimeDetails(
  overtimeId: string,
): UsePublicOvertimeDetailsResponse {
  const { data, loading, refetch, networkStatus, error } = useQuery(GetPublicOvertimeDetailQuery, {
    variables: { overtimeId },
    notifyOnNetworkStatusChange: true,
  });

  return {
    publicOvertime: data?.publicOvertime ?? undefined,
    loading: loading && networkStatus !== NetworkStatus.refetch,
    error,
    refetch,
  };
}
