import { OvertimeEditDetails } from './graphql/__generated__/OvertimeEditDetails.query';
import { OvertimeFormValues } from '../../components/OvertimeForm/form';
import { formatNumber } from '../../utils/number';
import { formatISOStringToDateString } from '../../utils/date';
import { castOvertimeImagesToUploadImageWidgetFormValues } from '../../components/OvertimeForm/components/OvertimeUploadImageWidget';

export function castOvertimeEmployees(
  employees: OvertimeEditDetails['overtime']['employees'],
): OvertimeFormValues['employees'] {
  return employees.map((employee) => ({
    fullName: employee.fullName,
    role: employee.role,
    hours: employee.hours ? formatNumber(employee.hours.toString()) : '',
    extraHours: employee.extraHours ? formatNumber(employee.extraHours.toString()) : '',
    materialSurcharge: employee.materialSurcharge ? formatNumber(employee.materialSurcharge) : '',
    machinesHours: employee.machinesHours ? formatNumber(employee.machinesHours.toString()) : '',
  }));
}

export function mapOvertimeToFormValues(
  overtime: OvertimeEditDetails['overtime'],
  hsProjectNumber?: string,
): OvertimeFormValues {
  return {
    no: overtime.alias ? parseFloat(overtime.alias).toString(10) : '',
    projectHsId: hsProjectNumber ?? '',
    projectClient: overtime.clientNameComputed ?? '',
    orderDate: formatISOStringToDateString(overtime.orderDate),
    date: formatISOStringToDateString(overtime.date),
    endDate: overtime.endDate ? formatISOStringToDateString(overtime.endDate) : '',
    workName: overtime.workName,
    workDescription: overtime.workDescription,
    images: castOvertimeImagesToUploadImageWidgetFormValues(overtime.images),
    employees: castOvertimeEmployees(overtime.employees),
  };
}
