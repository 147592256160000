import { gql, TypedDocumentNode } from '@apollo/client';
import OvertimeLogFragment from './OvertimeLog.fragment';
import { OvertimeLogs, OvertimeLogsVariables } from './__generated__/OvertimeLogs.query';

const OvertimeLogsQuery: TypedDocumentNode<OvertimeLogs, OvertimeLogsVariables> = gql`
  query OvertimeLogs($overtimeId: ID!, $page: Int!, $limit: Int!) {
    overtime(id: $overtimeId) {
      id

      logs(page: $page, limit: $limit) {
        logs {
          ...OvertimeLog
        }

        pagination {
          current
          hasNextPage
        }
      }
    }
  }

  ${OvertimeLogFragment}
`;

export default OvertimeLogsQuery;
