import { createPath } from 'react-router-dom';
import { DocumentType } from '../../globalTypes';
import { Overtime } from '../../graphql/__generated__/OvertimeFragment';
import overtimeRoutes from '../overtimeRoutes';
import { projectRoutes } from '../routes';

export type PDFSource = 'original' | 'signed-manually';

export enum OvertimeDetailSearchParams {
  SHOW_ORIGINAL_PDF = 'showOriginalPdf',
  ALERT = 'alert',
}

export enum OvertimeDetailAlert {
  ARCHIVED = 'archived',
  SEND = 'send',
  SIGNED_MANUALLY = 'signedManually',
}

export function createDocumentAssignPath(overtime: Overtime, projectId: string): string {
  const searchParams = new URLSearchParams({
    document: DocumentType.Overtime,
    returnPath: overtimeRoutes.detail.generatePath({
      overtimeId: overtime.id,
    }),
  });

  return createPath({
    pathname: projectRoutes.assignDocumentToProject.generatePath({
      projectId,
      documentId: overtime.id,
    }),
    search: searchParams.toString(),
  });
}
