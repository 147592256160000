import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import { Overtime } from '../../../../graphql/__generated__/OvertimeFragment';
import { OvertimeScopes } from '../../../../Overtime.types';
import DriveDocumentNoteContainer from '../../../../components/DriveDocumentNoteContainer';

interface OvertimeDetailSidebarTopProps {
  overtime: Overtime;
  scopes: OvertimeScopes;
  onEditButtonClick: () => void;
  onSendButtonClick: () => void;
  onDownloadButtonClick: () => void;
}

export default function OvertimeDetailSidebarTop({
  overtime,
  scopes,
  onEditButtonClick,
  onSendButtonClick,
  onDownloadButtonClick,
}: OvertimeDetailSidebarTopProps): JSX.Element {
  const { t } = useTranslation();

  const updatePermitted = scopes.updateOvertimeDocument.isPermitted;
  const sendPermitted = scopes.sendOvertimeDocumentAttachment.isPermitted;
  const downloadPermitted = scopes.downloadOvertimeDocument.isPermitted;

  return (
    <Stack gap="small-3x">
      <DriveDocumentNoteContainer id={overtime.id} name={overtime.name}>
        {updatePermitted && (
          <Button
            fullWidth
            onClick={onEditButtonClick}
            endIcon={<Icon name="create" />}
            className="qa-overtime-detail-edit-button"
          >
            {t('overtimeDetail.edit.button')}
          </Button>
        )}

        {sendPermitted && (
          <Button
            fullWidth
            endIcon={<Icon name="email" />}
            onClick={onSendButtonClick}
            className="qa-overtime-detail-send-pdf-button"
          >
            {t('overtimePdf.sendPdf.button')}
          </Button>
        )}

        {downloadPermitted && (
          <Button
            fullWidth
            endIcon={<Icon name="cloud-download" />}
            onClick={onDownloadButtonClick}
            className="qa-overtime-detail-download-button"
          >
            {t('overtimePdf.downloadPdf.button')}
          </Button>
        )}
      </DriveDocumentNoteContainer>
    </Stack>
  );
}
