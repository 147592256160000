import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import { ErrorRenderer } from '@hs-baumappe/redkit';

import { mapOvertimeToFormValues } from '../../OvertimeEdit.utils';
import { OvertimeProject } from '../../../../graphql/__generated__/OvertimeProject.fragment';
import useOvertimeEditDetail from '../../useOvertimeEditDetail';
import ErrorState from '../../../../components/ErrorState';
import OvertimeForm from '../../../../components/OvertimeForm';
import useOvertimeSave from '../../../../hooks/useOvertimeSave';

interface OvertimeEditBodyProps {
  overtimeId: string;
  project: OvertimeProject;
  onFormDirtyStateChange: (dirty: boolean) => void;
}

export default function OvertimeEditBody({
  overtimeId,
  project,
  onFormDirtyStateChange,
}: OvertimeEditBodyProps): JSX.Element | null {
  const { t } = useTranslation();

  const { overtime, employeeNames, loading, error } = useOvertimeEditDetail({
    overtimeId,
    projectId: project.id,
  });

  const {
    save,
    loading: saving,
    error: saveError,
  } = useOvertimeSave({
    overtimeId,
    draftProject: project.draft,
  });

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  if (!overtime) {
    return null;
  }

  return (
    <>
      {saveError && <ErrorState error={saveError} />}

      <OvertimeForm
        initialValues={mapOvertimeToFormValues(overtime, project.hsProjectNumber ?? undefined)}
        projectId={project.id}
        employeeNames={employeeNames}
        disableProjectHsIdField={!project.draft}
        nextSuggestedNumber={overtime.no}
        submitButtonText={t('updateOvertimeForm.submitButton')}
        onSubmit={save}
        onDirtyStateChange={onFormDirtyStateChange}
        loading={saving}
      />
    </>
  );
}
