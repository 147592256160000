import { Fragment } from 'react';
import { Text } from '@hs-baumappe/legacy-ui';

interface LogEmailsProps {
  emails: string[];
}

export default function LogEmails({ emails }: LogEmailsProps): JSX.Element {
  return (
    <>
      {emails.map((email, i) => (
        <Fragment key={email}>
          <Text variant="label-medium">{email}</Text>
          {i !== emails.length - 1 && `, `}
        </Fragment>
      ))}
    </>
  );
}
