import { useState } from 'react';
import { Button, Icon, Text } from '@hs-baumappe/legacy-ui';
import { Trans, useTranslation } from 'react-i18next';

import signHelper from '../../../../../../../static/img/externalSignHelper/helper.png';
import signHelper2x from '../../../../../../../static/img/externalSignHelper/helper@2x.png';
import { downloadDocument } from './PublicOvertimeDetailPdfView.utils';
import { GetPublicOvertimePdfPath } from '../../graphql/__generated__/GetPublicOvertimePdfPath.query';
import PDFViewer from '../../../../../../../components/PDFViewer';

import { PublicOvertimeFragment } from '../../../graphql/__generated__/PublicOvertime.fragment';

interface PublicOvertimeDetailPdfViewProps {
  daysRemaining: number;
  overtime: PublicOvertimeFragment;
  overtimePdfPath: GetPublicOvertimePdfPath['overtimePdfPath'];
  onSignOvertimeClick: () => void;
}

export default function PublicOvertimeDetailPdfView({
  daysRemaining,
  overtime,
  overtimePdfPath,
  onSignOvertimeClick,
}: PublicOvertimeDetailPdfViewProps): JSX.Element {
  const { t } = useTranslation();

  const [pdfDownloading, setPdfDownloading] = useState(false);

  const { name } = overtime;
  const { pdfPath } = overtimePdfPath;

  async function handleDownloadPdfButtonClick() {
    if (!pdfPath) {
      return;
    }

    setPdfDownloading(true);

    await downloadDocument(pdfPath, `${name}.pdf`);
    setPdfDownloading(false);
  }

  return (
    <>
      <div className="row">
        <div className="col col--sm-8">
          <img
            className="u-width-100%"
            src={signHelper}
            srcSet={`${signHelper2x} 2x`}
            alt="Report signing steps"
          />
        </div>
      </div>
      <div className="row u-margin-top-small">
        <div className="col col--sm-8">
          {pdfPath && <PDFViewer document={pdfPath} downloadPermitted />}

          <div className="u-margin-top-small u-display-none@xs-down">
            <Button
              color="primary"
              onClick={onSignOvertimeClick}
              fullWidth
              endIcon={<Icon name="border-color" />}
            >
              {t('publicOvertimeDetail.sign.button')}
            </Button>
          </div>
        </div>
        <div className="col col--sm-4 u-margin-top-small@xs-down">
          <Button
            color="primary"
            fullWidth
            onClick={onSignOvertimeClick}
            endIcon={<Icon name="border-color" />}
          >
            {t('publicOvertimeDetail.sign.button')}
          </Button>

          <div className="u-margin-top-xsmall">
            <Button
              fullWidth
              endIcon={<Icon name="save" />}
              disabled={!pdfPath}
              loading={pdfDownloading}
              onClick={handleDownloadPdfButtonClick}
            >
              {t('publicOvertimeDetail.savePdf.button')}
            </Button>
          </div>
          <div className="u-margin-top-xsmall">
            <Text>
              <Trans
                i18nKey="publicOvertimeDetail.remainingDays"
                values={{ days: daysRemaining }}
                components={{ daysComponent: <Text variant="label-large" /> }}
              />
            </Text>
          </div>
        </div>
      </div>
    </>
  );
}
