import { gql, TypedDocumentNode } from '@apollo/client';
import OvertimeFragment from '../../graphql/OvertimeFragment';
import {
  UploadManualPdFtoOvertime,
  UploadManualPdFtoOvertimeVariables,
} from './__generated__/graphql';

const UploadManualPDFToOvertimeMutation: TypedDocumentNode<
  UploadManualPdFtoOvertime,
  UploadManualPdFtoOvertimeVariables
> = gql`
  mutation UploadManualPDFtoOvertime($file: Upload!, $input: UploadManualPDFToOvertimeInput!) {
    uploadManualPDFToOvertime(input: $input, file: $file) {
      ...Overtime
    }
  }

  ${OvertimeFragment}
`;

export default UploadManualPDFToOvertimeMutation;
