import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { RequireAuth, useViewer } from '@hs-baumappe/web-auth';
import { NotFoundErrorPage } from '@hs-baumappe/redkit';

import Overtime from './routes/Overtime';
import OvertimeCustomer from './routes/OvertimeCustomer/OvertimeCustomer';
import i18n from './i18n';
import { setYupLocale } from './yup';

function App(): JSX.Element {
  const { viewer } = useViewer();

  useEffect(() => {
    if (!viewer?.preferredLanguageCode) return;

    i18n.changeLanguage(viewer.preferredLanguageCode.toLowerCase());
    setYupLocale();
  }, [viewer?.preferredLanguageCode]);

  return (
    <I18nextProvider i18n={i18n}>
      <Routes>
        <Route
          path=":overtimeId/*"
          element={
            <RequireAuth>
              <Overtime />
            </RequireAuth>
          }
        />

        <Route path="customer-detail/:overtimeId/*" element={<OvertimeCustomer />} />

        <Route path="*" element={<NotFoundErrorPage />} />
      </Routes>
    </I18nextProvider>
  );
}

export default App;
