import { FocusEvent, KeyboardEvent } from 'react';
import {
  Box,
  Flex,
  FormHelperText,
  Icon,
  IconButton,
  NativeSelect,
  Text,
} from '@hs-baumappe/legacy-ui';
import { NameAutocompleteField, FormikFormField } from '@hs-baumappe/forms';
import { useTranslation } from 'react-i18next';
import { useField, useFormikContext } from 'formik';

import { OvertimeFormValues } from '../../../../form';
import { ROLE_TYPE_OPTIONS } from './EmployeeCard.utils';
import { formatNumber } from '../../../../../../utils/number';

interface EmployeeCardProps {
  index: number;
  employeeNames: string[];
  onRemoveButtonClick?: () => void;
}

type EmployeeField = OvertimeFormValues['employees'][number];

export default function EmployeeCard({
  index,
  employeeNames,
  onRemoveButtonClick,
}: EmployeeCardProps): JSX.Element {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext<OvertimeFormValues>();
  const [, employeeFieldMeta] = useField<EmployeeField>(`employees[${index}]`);

  const error = employeeFieldMeta.error;

  function handleFieldBlur(event: FocusEvent<HTMLInputElement>) {
    const { currentTarget } = event;

    setFieldValue(currentTarget.name, formatNumber(currentTarget.value));
  }

  function handleFieldKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    const { currentTarget } = event;

    currentTarget.value = currentTarget.value.replace('.', ',');
  }

  return (
    <Box>
      <Box
        className={`qa-employee-card-${index}`}
        stylex={{
          backgroundColor: 'white',
          boxShadow: 'medium',
          borderRadius: 'medium',
          padding: 'small-x',
        }}
      >
        <Flex alignItems="center">
          <Text variant="title-small">{t('employeeForm.employeeNo', { number: index + 1 })}</Text>

          {onRemoveButtonClick && (
            <IconButton
              type="button"
              stylex={{ marginLeft: 'auto' }}
              icon={<Icon name="close" />}
              onClick={onRemoveButtonClick}
            />
          )}
        </Flex>

        <Box className="row" stylex={{ marginTop: 'small-x' }}>
          <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
            <NameAutocompleteField
              name={`employees[${index}].fullName`}
              label={t('employeeForm.fullName.label')}
              placeholder={t('employeeForm.fullName.placeholder')}
              names={employeeNames}
            />
          </Box>
          <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
            <FormikFormField
              control={NativeSelect}
              name={`employees[${index}].role`}
              label={t('employeeForm.role.label')}
            >
              {ROLE_TYPE_OPTIONS.map((roleTypeOption, i) => (
                <option key={`role-type-option-${i}`} value={roleTypeOption.value}>
                  {t(roleTypeOption.label)}
                </option>
              ))}
            </FormikFormField>
          </Box>
        </Box>
        <Box className="row">
          <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
            <FormikFormField
              name={`employees[${index}].hours`}
              label={t('employeeForm.hours.label')}
              placeholder={t('employeeForm.hours.placeholder')}
              onBlur={handleFieldBlur}
              onKeyUp={handleFieldKeyUp}
            />
          </Box>
          <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
            <FormikFormField
              name={`employees[${index}].materialSurcharge`}
              label={t('employeeForm.materialSurcharge.label')}
              placeholder={t('employeeForm.materialSurcharge.placeholder')}
              onBlur={handleFieldBlur}
              onKeyUp={handleFieldKeyUp}
            />
          </Box>
        </Box>
        <Box className="row">
          <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
            <FormikFormField
              name={`employees[${index}].extraHours`}
              label={t('employeeForm.extraHours.label')}
              placeholder={t('employeeForm.extraHours.placeholder')}
              onBlur={handleFieldBlur}
              onKeyUp={handleFieldKeyUp}
            />
          </Box>
          <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
            <FormikFormField
              name={`employees[${index}].machinesHours`}
              label={t('employeeForm.machinesHours.label')}
              placeholder={t('employeeForm.machinesHours.placeholder')}
              onBlur={handleFieldBlur}
              onKeyUp={handleFieldKeyUp}
            />
          </Box>
        </Box>
      </Box>

      {error && (
        <FormHelperText error stylex={{ marginTop: 'small-4x' }}>
          {t('employeeForm.formFeedback')}
        </FormHelperText>
      )}
    </Box>
  );
}
