import { gql, TypedDocumentNode } from '@apollo/client/core';
import {
  GetPublicOvertimePdfPath,
  GetPublicOvertimePdfPathVariables,
} from './__generated__/GetPublicOvertimePdfPath.query';

export const GetPublicOvertimePdfPathQuery: TypedDocumentNode<
  GetPublicOvertimePdfPath,
  GetPublicOvertimePdfPathVariables
> = gql`
  query GetPublicOvertimePdfPath($overtimeId: ID!) {
    overtimePdfPath(id: $overtimeId) {
      overtimeId
      pdfPath
      manualPdfPath
    }
  }
`;
