import { ComponentPropsWithoutRef } from 'react';

import { Button, ControlGroup, Input, Icon } from '@hs-baumappe/legacy-ui';

type UploadInputGroupProps = Omit<ComponentPropsWithoutRef<'input'>, 'size' | 'color'>;

export default function UploadInputGroup(inputProps: UploadInputGroupProps): JSX.Element {
  return (
    <ControlGroup>
      <Input type="file" {...inputProps} />
      <Button
        component="label"
        htmlFor={inputProps.id}
        color="primary"
        contentClassName="u-display-flex"
      >
        <Icon name="folder" size={24} />
      </Button>
    </ControlGroup>
  );
}
