import { Route, Routes } from 'react-router-dom';
import { NotFoundError } from '@hs-baumappe/redkit';

import OvertimeCustomerDetail from './OvertimeCustomerDetail';

export default function OvertimeCustomer(): JSX.Element {
  return (
    <Routes>
      <Route index element={<OvertimeCustomerDetail />} />

      <Route path="*" element={<NotFoundError />} />
    </Routes>
  );
}
