import { gql, TypedDocumentNode } from '@apollo/client';
import {
  OvertimeUploadSignature,
  OvertimeUploadSignatureVariables,
} from './__generated__/OvertimeUploadSignature.mutation';
import OvertimeFragment from '../../../graphql/OvertimeFragment';

const OvertimeUploadSignatureMutation: TypedDocumentNode<
  OvertimeUploadSignature,
  OvertimeUploadSignatureVariables
> = gql`
  mutation OvertimeUploadSignature($file: Upload!, $input: UploadOvertimeSignatureInput!) {
    uploadOvertimeSignature(file: $file, input: $input) {
      id

      overtime {
        ...Overtime
      }
    }
  }

  ${OvertimeFragment}
`;

export default OvertimeUploadSignatureMutation;
