import { gql, TypedDocumentNode } from '@apollo/client';
import {
  OvertimeEditDetails,
  OvertimeEditDetailsVariables,
} from './__generated__/OvertimeEditDetails.query';

const OvertimeEditDetailsQuery: TypedDocumentNode<
  OvertimeEditDetails,
  OvertimeEditDetailsVariables
> = gql`
  query OvertimeEditDetails($overtimeId: ID!, $thumbnailHeight: Int!, $thumbnailWidth: Int!) {
    overtime(id: $overtimeId) {
      id

      no
      alias
      date
      orderDate
      endDate
      workName
      workDescription
      clientNameComputed

      images {
        id
        label
        url
        thumbnail(height: $thumbnailHeight, width: $thumbnailWidth)
      }

      employees {
        id

        fullName
        hours
        role
        extraHours
        materialSurcharge
        machinesHours
      }
    }
  }
`;

export default OvertimeEditDetailsQuery;
