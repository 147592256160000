import { ApolloError, useMutation } from '@apollo/client';
import OvertimeRemoveMutation from './graphql/OvertimeRemove.mutation';

interface UseOvertimeRemoveResponse {
  remove: (overtimeId: string) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

interface UseOvertimeRemoveOptions {
  onSuccess: () => void;
}

export default function useOvertimeRemove({
  onSuccess,
}: UseOvertimeRemoveOptions): UseOvertimeRemoveResponse {
  const [mutate, { loading, error }] = useMutation(OvertimeRemoveMutation);

  async function remove(overtimeId: string) {
    try {
      await mutate({
        variables: {
          input: {
            id: overtimeId,
          },
        },
        update: (cache) => {
          cache.evict({
            id: cache.identify({
              id: overtimeId,
              __typename: 'Overtime',
            }),
          });
        },
      });

      onSuccess();
    } catch (e) {}
  }

  return {
    remove,
    loading,
    error,
  };
}
