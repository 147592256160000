import { useState } from 'react';
import { createPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FlexGrid, FlexGridColumn, Stack } from '@hs-baumappe/legacy-ui';
import { PageLayoutWithStickySidebar } from '@hs-baumappe/frontend-kit';

import useDriveDocumentParentFolders from '../../hooks/useDriveDocumentParentFolders';
import OvertimeDetailHeader from './components/OvertimeDetailHeader';
import OvertimeDetailAlerts from './components/OvertimeDetailAlerts';
import PDFViewer from '../../components/PDFViewer';
import OvertimeDetailSidebarTop from './components/OvertimeDetailSidebarTop';
import OvertimeDetailSidebarBottom from './components/OvertimeDetailSidebarBottom';
import OvertimeDetailLogs from './components/OvertimeDetailLogs';
import ConfirmOvertimeDuplication from './components/ConfirmOvertimeDuplication';
import SendOvertimePdfDialog from './components/SendOvertimePdfDialog';
import RemoveManuallyUploadedReportModal from '../../components/RemoveManuallyUploadedReportModal';
import { Overtime } from '../../graphql/__generated__/OvertimeFragment';
import { OvertimeProject } from '../../graphql/__generated__/OvertimeProject.fragment';
import { OvertimeScopes } from '../../Overtime.types';
import { OvertimeLocationState } from '../Overtime.route-types';
import { createOvertimeLocation, getOvertimeMeta } from '../../Overtime.utils';
import overtimeRoutes from '../overtimeRoutes';
import { OvertimeDetailSearchParams } from './OvertimeDetail.utils';
import { projectRoutes } from '../routes';

interface OvertimeDetailProps {
  overtime: Overtime;
  project: OvertimeProject;
  scopes: OvertimeScopes;
}

export default function OvertimeDetail({
  overtime,
  project,
  scopes,
}: OvertimeDetailProps): JSX.Element {
  const location = useLocation<OvertimeLocationState>();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const showOriginalPdf = searchParams.has(OvertimeDetailSearchParams.SHOW_ORIGINAL_PDF);

  const { parentFolders } = useDriveDocumentParentFolders(overtime.id);

  const [showDuplicationDialog, setShowDuplicationDialog] = useState(false);
  const [showRemoveManualUploadedPdfDialog, setShowRemoveManualUploadedPdfDialog] = useState(false);
  const [showHasNotSignedDialog, setShowHasNotSignedDialog] = useState(false);

  const { signedManually, signedByEmployee, signed, signable } = getOvertimeMeta(overtime);
  const pdfSource = signedManually && !showOriginalPdf ? 'signed-manually' : 'original';
  const pdfPath = pdfSource === 'original' ? overtime.pdfPath : overtime.manualPdfPath;
  const downloadPermitted = scopes.downloadOvertimeDocument.isPermitted;

  function handleEditButtonClick() {
    if (!overtime.viewerCanUpdate) {
      setShowDuplicationDialog(true);
      return;
    }

    navigate(overtimeRoutes.edit.generatePath({ overtimeId: overtime.id }), {
      state: createOvertimeLocation(location),
    });
  }

  function handleSendButtonClick() {
    if (!signedManually && !signedByEmployee) {
      setShowHasNotSignedDialog(true);
      return;
    }

    navigate(overtimeRoutes.sendEmail.generatePath({ overtimeId: overtime.id }), {
      state: createOvertimeLocation(location),
    });
  }

  function handleDownloadButtonClick() {
    if (!pdfPath) {
      return;
    }

    window.open(pdfPath, '_blank');
  }

  function handleTogglePdfButtonClick() {
    setSearchParams((prev) => {
      if (pdfSource === 'original') {
        prev.delete(OvertimeDetailSearchParams.SHOW_ORIGINAL_PDF);
      } else {
        prev.set(OvertimeDetailSearchParams.SHOW_ORIGINAL_PDF, 'true');
      }

      return prev;
    });
  }

  function handleRemoveSuccess() {
    navigate(
      createPath({
        pathname: projectRoutes.detail.generatePath({
          projectId: project.id,
        }),
        search: `?removedDocumentName=${overtime.name}`,
      }),
    );
  }

  function handleSignNowClick() {
    setShowHasNotSignedDialog(false);

    navigate(overtimeRoutes.sign.generatePath({ overtimeId: overtime.id }), {
      state: createOvertimeLocation(location),
    });
  }

  function handleSendWithoutSignatureClick() {
    setShowHasNotSignedDialog(false);

    navigate(overtimeRoutes.sendEmail.generatePath({ overtimeId: overtime.id }), {
      state: createOvertimeLocation(location),
    });
  }

  function handleDuplicationSuccess(duplicateOvertimeId: string) {
    setShowDuplicationDialog(false);

    navigate(overtimeRoutes.detail.generatePath({ overtimeId: duplicateOvertimeId }));
  }

  return (
    <PageLayoutWithStickySidebar
      header={
        <OvertimeDetailHeader
          name={overtime.name}
          status={overtime.status}
          signed={signed}
          project={project}
          parentFolders={parentFolders}
        />
      }
      sidebarTop={
        <OvertimeDetailSidebarTop
          overtime={overtime}
          scopes={scopes}
          onEditButtonClick={handleEditButtonClick}
          onSendButtonClick={handleSendButtonClick}
          onDownloadButtonClick={handleDownloadButtonClick}
        />
      }
      sidebarBottom={
        <OvertimeDetailSidebarBottom
          overtime={overtime}
          scopes={scopes}
          projectId={project.id}
          pdfSource={pdfSource}
          signable={signable}
          signedManually={signedManually}
          onTogglePdfButtonClick={handleTogglePdfButtonClick}
          onRemoveManualPdfButtonClick={() => setShowRemoveManualUploadedPdfDialog(true)}
          onRemoveSuccess={handleRemoveSuccess}
        />
      }
      footer={
        <FlexGrid>
          <FlexGridColumn sm={8}>
            <OvertimeDetailLogs overtimeId={overtime.id} />
          </FlexGridColumn>
        </FlexGrid>
      }
    >
      <Stack gap="medium">
        <OvertimeDetailAlerts name={overtime.name} signedManually={signedManually} />

        {pdfPath && (
          <PDFViewer document={pdfPath} downloadPermitted={downloadPermitted} key={pdfPath} />
        )}
      </Stack>

      <RemoveManuallyUploadedReportModal
        open={showRemoveManualUploadedPdfDialog}
        title={overtime.name}
        overtimeId={overtime.id}
        onRequestDismiss={() => setShowRemoveManualUploadedPdfDialog(false)}
        onSuccess={() => setShowRemoveManualUploadedPdfDialog(false)}
      />

      <ConfirmOvertimeDuplication
        open={showDuplicationDialog}
        overtime={overtime}
        onSuccess={handleDuplicationSuccess}
        onRequestClose={() => setShowDuplicationDialog(false)}
      />

      <SendOvertimePdfDialog
        open={showHasNotSignedDialog}
        onSendWithoutSignatureClick={handleSendWithoutSignatureClick}
        onSignNowClick={handleSignNowClick}
        onRequestClose={() => setShowHasNotSignedDialog(false)}
      />
    </PageLayoutWithStickySidebar>
  );
}
