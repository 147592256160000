import { parseFromTimeZone } from 'date-fns-timezone';
import { OvertimeFormValues } from '../../components/OvertimeForm/form';
import { OvertimeEmployeeSaveInput, OvertimeSaveInput } from '../../globalTypes';
import { castOvertimeUploadImageWidgetFormValuesToOvertimeImages } from '../../components/OvertimeForm/components/OvertimeUploadImageWidget';

const TIMEZONE_CONFIG = { timeZone: 'Europe/Berlin' };

export function createOvertimeSaveInput({
  formValues,
  overtimeId,
  draftProject,
}: {
  formValues: OvertimeFormValues;
  overtimeId: string;
  draftProject: boolean;
}): OvertimeSaveInput {
  const employees = formValues.employees.map<OvertimeEmployeeSaveInput>((employee) => ({
    ...employee,
    hours: Number(employee.hours) || 0,
    extraHours: Number(employee.extraHours) || 0,
    materialSurcharge: employee.materialSurcharge || '0',
    machinesHours: Number(employee.machinesHours) || 0,
  }));

  const date = parseFromTimeZone(formValues.date, TIMEZONE_CONFIG);
  const orderDate = parseFromTimeZone(formValues.orderDate, TIMEZONE_CONFIG);
  const endDate = formValues.endDate
    ? parseFromTimeZone(formValues.endDate, TIMEZONE_CONFIG)
    : undefined;

  return {
    documentId: overtimeId,
    date,
    orderDate,
    endDate,
    workName: formValues.workName,
    clientName: formValues.projectClient,
    draftProjectNumber:
      draftProject && !!formValues.projectHsId ? formValues.projectHsId : undefined,
    workDescription: formValues.workDescription,
    employees,
    images: castOvertimeUploadImageWidgetFormValuesToOvertimeImages(formValues.images),
    alias: formValues.no ? formValues.no.toString() : undefined,
  };
}
