export function isTabletOrMobileDevice(): boolean {
  const { userAgent, maxTouchPoints } = navigator;
  const tabletDevice =
    /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent) ||
    (maxTouchPoints > 2 && /MacIntel/.test(userAgent));
  const mobileDevice =
    /Mobile|Android|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent,
    );

  return tabletDevice || mobileDevice;
}
