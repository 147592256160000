import { gql, TypedDocumentNode } from '@apollo/client';

const OvertimeEmailFormImageUploadMutation: TypedDocumentNode = gql`
  mutation DriveEmailFormImageUpload($file: Upload!) {
    uploadEditorImage(file: $file) {
      id
      path
    }
  }
`;

export default OvertimeEmailFormImageUploadMutation;
