import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { InputFieldWithCopyAction } from '@hs-baumappe/frontend-kit';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  Icon,
  IconButton,
  Stack,
  Text,
} from '@hs-baumappe/legacy-ui';
import { ErrorCode, parseFirstGraphQLErrorOfApolloError } from '@hs-baumappe/redkit';

interface ErrorStateProps {
  error: ApolloError;
}

function getErrorTitleTranslationKey(code?: ErrorCode): string {
  switch (code) {
    case ErrorCode.BAD_USER_INPUT:
      return 'errorStateModal.validationError.title';
    case ErrorCode.INTERNAL_SERVER_ERROR:
      return 'errorStateModal.internalError.title';
    default:
      return 'errorStateModal.validationError.title';
  }
}

function getErrorMessageTranslationKey({
  code,
  traceId,
}: {
  code?: ErrorCode;
  traceId?: string;
}): string {
  switch (code) {
    case ErrorCode.BAD_USER_INPUT:
      return 'errorStateModal.validationError.message';
    case ErrorCode.INTERNAL_SERVER_ERROR:
      return traceId
        ? 'errorStateModal.internalError.messageWithTrace'
        : 'errorStateModal.internalError.message';
    default:
      return 'errorStateModal.validationError.message';
  }
}

export default function ErrorState({ error }: ErrorStateProps): JSX.Element | null {
  const { t } = useTranslation();
  const [open, setOpen] = useState(!!error);

  if (error.graphQLErrors.length === 0) {
    return null;
  }

  const parsedError = parseFirstGraphQLErrorOfApolloError(error);
  const code = parsedError?.code;
  const traceId = code === ErrorCode.INTERNAL_SERVER_ERROR ? parsedError.traceId : undefined;

  return (
    <Dialog open={open} onRequestClose={() => setOpen(false)} size="xsmall" role="alertdialog">
      <DialogHeader
        rightSlot={<IconButton onClick={() => setOpen(false)} icon={<Icon name="close" />} />}
      >
        <Text variant="title-small">{t(getErrorTitleTranslationKey(code))}</Text>
      </DialogHeader>

      <DialogBody>
        <Text component="div" variant="body-medium">
          {t(getErrorMessageTranslationKey({ code, traceId }))}
        </Text>
      </DialogBody>
      <DialogActions>
        <Stack gap="small-3x" stylex={{ width: '100%' }}>
          {code === ErrorCode.INTERNAL_SERVER_ERROR && !!traceId && (
            <InputFieldWithCopyAction
              variant="filled"
              label={t('errorStateModal.internalError.traceIdLabel')}
              defaultValue={traceId}
              tooltipPlacement="top-end"
              renderTooltipContent={({ showCopied }) =>
                showCopied
                  ? t('copyToClipboard.tooltip.content.copied')
                  : t('copyToClipboard.tooltip.content.copyToClipboard')
              }
              readOnly
            />
          )}
          <Button onClick={() => setOpen(false)} fullWidth>
            {t('errorStateModal.cancelButton')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
