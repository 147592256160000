import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogHeader,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';
import useDuplicateOvertime from './useDuplicateOvertime';
import { useTranslation } from 'react-i18next';

import { Overtime } from '../../../../graphql/__generated__/OvertimeFragment';
import ErrorState from '../../../../components/ErrorState';

interface Props {
  open: boolean;
  overtime: Overtime;
  onRequestClose: () => void;
  onSuccess: (duplicateOvertimeId: string) => void;
}

const ConfirmOvertimeDuplication: FC<Props> = ({
  open,
  overtime,
  onRequestClose,
  onSuccess,
}): JSX.Element => {
  const { t } = useTranslation();
  const [duplicateOvertimeMutation, { loading, error }] = useDuplicateOvertime();

  const handleDuplicateOvertime = async () => {
    if (loading) return;

    const { data } = await duplicateOvertimeMutation({
      variables: { input: { overtime: overtime.id } },
    });

    if (data && data.duplicateOvertime) {
      onSuccess(data.duplicateOvertime.id);
    }
  };

  return (
    <Dialog open={open} onRequestClose={onRequestClose} size="xsmall" role="alertdialog">
      {error && <ErrorState error={error} />}
      <DialogHeader
        rightSlot={<IconButton onClick={onRequestClose} icon={<Icon name="close" />} />}
      >
        <Text variant="title-small">{overtime.name}</Text>
      </DialogHeader>
      <DialogBody>
        <Text variant="body-medium">{t('confirmOvertimeDuplicationModal.description')}</Text>
      </DialogBody>
      <DialogActions direction="column">
        <Button onClick={onRequestClose}>
          {t('confirmOvertimeDuplicationModal.cancelButton')}
        </Button>
        <Button
          color="primary"
          onClick={handleDuplicateOvertime}
          endIcon={<Icon name="file-copy" />}
          loading={loading}
        >
          {t('confirmOvertimeDuplicationModal.confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmOvertimeDuplication;
