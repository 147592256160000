import { gql, TypedDocumentNode } from '@apollo/client';
import { PublicOvertimeFragment } from './PublicOvertime.fragment';
import {
  GetPublicOvertimeDetail,
  GetPublicOvertimeDetailVariables,
} from './__generated__/GetPublicOvertimeDetail.query';

export const GetPublicOvertimeDetailQuery: TypedDocumentNode<
  GetPublicOvertimeDetail,
  GetPublicOvertimeDetailVariables
> = gql`
  query GetPublicOvertimeDetail($overtimeId: ID!) {
    publicOvertime(id: $overtimeId) {
      ...PublicOvertimeFragment
    }
  }
  ${PublicOvertimeFragment}
`;
