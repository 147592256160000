import { ReactNode, useEffect } from 'react';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { Box, Button, Divider, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import {
  castOvertimeFormValues,
  getOvertimeFormInitialValues,
  OvertimeFormValues,
  validationSchema,
} from './form';
import ProjectInformationSection from './components/ProjectInformationSection';
import WorkInformationSection from './components/WorkInformationSection';
import OvertimeUploadImageWidget from './components/OvertimeUploadImageWidget';
import EmployeesSection from './components/EmployeesSection';

interface OvertimeFormProps {
  projectId: string;
  employeeNames: string[];
  initialValues?: Partial<OvertimeFormValues>;
  nextSuggestedNumber?: number;
  disableProjectHsIdField?: boolean;
  submitButtonText?: ReactNode;
  loading?: boolean;
  onSubmit: (values: OvertimeFormValues, formikHelpers: FormikHelpers<OvertimeFormValues>) => void;
  onDirtyStateChange?: (dirty: boolean) => void;
}

export default function OvertimeForm({
  projectId,
  employeeNames,
  initialValues,
  nextSuggestedNumber,
  disableProjectHsIdField,
  submitButtonText,
  loading,
  onSubmit,
  onDirtyStateChange,
}: OvertimeFormProps): JSX.Element {
  const { t } = useTranslation();

  const formik = useFormik({
    validationSchema,
    initialValues: getOvertimeFormInitialValues(initialValues),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: (formValues, formikHelpers) =>
      onSubmit(castOvertimeFormValues(formValues), formikHelpers),
  });

  useEffect(() => onDirtyStateChange?.(formik.dirty), [formik.dirty, onDirtyStateChange]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box className="row">
          <Box className="col col--sm-8">
            <Stack gap="medium">
              <ProjectInformationSection
                nextSuggestedNumber={nextSuggestedNumber}
                disableProjectHsIdField={disableProjectHsIdField}
              />

              <Divider />

              <WorkInformationSection />

              <Divider />

              <OvertimeUploadImageWidget projectId={projectId} />

              <Divider />

              <EmployeesSection employeeNames={employeeNames} />
            </Stack>
          </Box>
          <Box className="col col--sm-4">
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button
                type="submit"
                color="primary"
                className="qa-overtime-form-submit-button"
                fullWidth
                endIcon={<Icon name="add" />}
                loading={loading}
              >
                {submitButtonText || t('overtimeForm.submitButton')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}
