import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@hs-baumappe/legacy-ui';
import { OvertimeStatus } from '../../../../globalTypes';
import ErrorState from '../../../../components/ErrorState';
import SignatureForm from '../../../../components/SignatureForm';
import useOvertimeCustomerSign from './hooks/useOvertimeCustomerSign';
import RouteDialog from '../../../../components/RouteDialog';

interface Props {
  open: boolean;
  overtimeId: string;
  overtimeStatus: OvertimeStatus;
  overtimeName: string;
  overtimeSigned: boolean;
  onRequestClose: () => void;
  onSuccess: () => void;
}

const SignPublicOvertime: FC<Props> = ({
  open,
  overtimeId,
  overtimeName,
  overtimeStatus,
  overtimeSigned,
  onRequestClose,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const { sign, loading, error } = useOvertimeCustomerSign({
    overtimeId,
    onSuccess,
  });

  return (
    <RouteDialog
      open={open}
      onRequestClose={onRequestClose}
      header={
        <>
          <Text component="h2" variant="title-small" className="u-margin-bottom-0">
            {overtimeName}
          </Text>
          <Text variant="body-medium" color={overtimeSigned ? 'success' : 'muted'}>
            {t('signPublicOvertimeModal.status', { status: t(overtimeStatus) })}
          </Text>
        </>
      }
    >
      {error && <ErrorState error={error} />}

      <SignatureForm
        signatureInputLabel={t('signatureForm.signature.label', { context: 'CUSTOMER_PUBLIC' })}
        leftSection={<Text variant="body-small">{t('signPublicOvertimeModal.description')}</Text>}
        rightSection={
          <Text component="p" variant="body-small" stylex={{ marginTop: '0' }}>
            {t('signPublicOvertimeModal.dataSavingPolicy')}
          </Text>
        }
        loading={loading}
        onSubmit={(values) =>
          sign({
            name: values.name,
            signature: values.signature,
          })
        }
      />
    </RouteDialog>
  );
};

export default SignPublicOvertime;
