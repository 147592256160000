import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { ErrorRendererPage, NotFoundErrorPage } from '@hs-baumappe/redkit';

import UploadManualPDFOvertime from './UploadManualPDFOvertime';
import OvertimeArchive from './OvertimeArchive';
import OvertimeSendExternalEmail from './OvertimeSendExternalEmail';
import OvertimeSign from './OvertimeSign';
import OvertimeSendEmail from './OvertimeSendEmail';
import OvertimeEdit from './OvertimeEdit';
import OvertimeDetail from './OvertimeDetail';
import { OvertimeLocationState, OvertimeRouteParams } from './Overtime.route-types';
import useOvertime from '../hooks/useOvertime';
import { useUpdateDriveDocumentLastUsed } from '../hooks/useUpdateDriveDocumentLastUsed';

export default function Overtime(): JSX.Element {
  const { overtimeId } = useParams<OvertimeRouteParams>();
  const location = useLocation<OvertimeLocationState>();
  const backgroundLocation = location.state && location.state.backgroundLocation;
  const { data, loading, error } = useOvertime(overtimeId);

  useUpdateDriveDocumentLastUsed({
    id: overtimeId,
    skip: !data?.overtime,
  });

  if (loading && !data) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (!data) {
    return <NotFoundErrorPage />;
  }

  return (
    <>
      <Routes location={backgroundLocation || location}>
        <Route path="*" element={<OvertimeDetail {...data} />} />
      </Routes>
      <Routes>
        <Route path="edit" element={<OvertimeEdit {...data} />} />
        <Route path="archive" element={<OvertimeArchive {...data} />} />
        <Route path="sign" element={<OvertimeSign {...data} />} />
        <Route path="send-email" element={<OvertimeSendEmail {...data} />} />
        <Route path="send-external-email" element={<OvertimeSendExternalEmail {...data} />} />
        <Route path="manual-sign" element={<UploadManualPDFOvertime />} />
      </Routes>
    </>
  );
}
