const PSPDFKIT_ASSET_URL = `${process.env.REACT_APP_PSPDFKIT_ASSET_URL}/`;

const config = {
  PSPDFKIT_ASSET_URL,
  PSPDFKIT_LICENCE_KEY: process.env.REACT_APP_PSPDFKIT_LICENCE_KEY,
  PSPDFKIT_STYLESHEETS: [`${PSPDFKIT_ASSET_URL}PSPDFKit-overrides.css`],
  HOST_URL: process.env.REACT_APP_HOST_URL,
};

export default config;
