import { Box, Button, Icon, Stack, Text } from '@hs-baumappe/legacy-ui';
import { ArrayHelpers, FieldArray, useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { OvertimeFormValues } from '../../form';
import EmployeeCard from './components/EmployeeCard';

interface EmployeesSectionProps {
  employeeNames: string[];
}

export default function EmployeesSection({ employeeNames }: EmployeesSectionProps): JSX.Element {
  const { t } = useTranslation();

  const [employeesField] = useField<OvertimeFormValues['employees']>('employees');
  const employees = employeesField.value;
  const employeesRemovable = employees.length > 1;
  const latestEmployee = employees[employees.length - 1];

  return (
    <FieldArray name="employees">
      {(arrayHelpers: ArrayHelpers<OvertimeFormValues['employees']>) => (
        <Stack gap="medium">
          <Text variant="title-small" color="muted">
            {t('overtimeForm.employee.title')}
          </Text>

          <Stack gap="medium">
            {employees.map((employee, index) => (
              <EmployeeCard
                key={`employee-${index}`}
                index={index}
                employeeNames={employeeNames}
                onRemoveButtonClick={
                  employeesRemovable ? () => arrayHelpers.remove(index) : undefined
                }
              />
            ))}
          </Stack>

          <Box className="row">
            <Box className="col col--sm-6">
              <Text variant="body-small">{t('overtimeForm.addAnotherEmployeeHintText')}</Text>
            </Box>
            <Box className="col col--sm-6">
              <Button
                type="button"
                onClick={() =>
                  latestEmployee
                    ? arrayHelpers.push({ ...latestEmployee, fullName: '' })
                    : undefined
                }
                fullWidth
                endIcon={<Icon name="add" className="u-font-size-large" />}
              >
                {t('overtimeForm.addAnotherEmployeeButton')}
              </Button>
            </Box>
          </Box>
        </Stack>
      )}
    </FieldArray>
  );
}
