// eslint-disable-next-line import/prefer-default-export
export async function downloadDocument(downloadUrl: string, documentName?: string): Promise<void> {
  const documentAsBlob = await fetch(downloadUrl).then((response) => response.blob());
  const objectUrl = URL.createObjectURL(documentAsBlob);
  const anchor = document.createElement('a');

  anchor.download = documentName || '';
  anchor.href = objectUrl;
  anchor.click();
  anchor.remove();

  URL.revokeObjectURL(objectUrl);
}
