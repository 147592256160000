import { Text } from '@hs-baumappe/legacy-ui';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Log } from '../../OvertimeDetailLogs.types';
import OvertimeLogBody from './components/OvertimeLogBody';
import { isOvertimeLog } from './OvertimeLog.utils';

interface OvertimeLogProps {
  log: Log;
  showDate?: boolean;
}

function renderLogMessage(log: Log): JSX.Element | null {
  if (isOvertimeLog(log)) {
    return <OvertimeLogBody log={log} />;
  }

  return null;
}

export default function OvertimeLog({ log, showDate }: OvertimeLogProps): JSX.Element {
  const { t } = useTranslation();

  const formattedDate = 'date' in log ? format(new Date(log.date), 'dd.MM.yyyy') : undefined;
  const formattedHour = 'date' in log ? format(new Date(log.date), 'HH:mm') : undefined;

  return (
    <>
      <Text variant="label-medium" align="right">
        {showDate && formattedDate && t('logTable.logDate', { date: formattedDate })}

        {formattedHour && t('logTable.logTime', { hour: formattedHour })}
      </Text>

      <Text variant="body-small">{renderLogMessage(log)}</Text>
    </>
  );
}
