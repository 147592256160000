import prettyBytes from 'pretty-bytes';

export const numberFixer = (n: number, maxFraction: number): number => {
  const fractioner = 10 ** maxFraction;

  return Math.round(n * fractioner) / fractioner;
};

export function formatBytes(byteValue: number): string {
  return prettyBytes(byteValue, { maximumFractionDigits: 1, locale: 'de' });
}

export function formatNumber(value: string): string {
  const matcher = /^(\d+)[.,]?(\d+)?$/;
  if (!matcher.test(value)) return value;

  const [decimal, floating = ''] = value.split(/[.,]/);
  return `${decimal},${floating.slice(0, 2).padEnd(2, '0')}`;
}
