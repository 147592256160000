import { OvertimeLog } from '../../graphql/__generated__/OvertimeLog.fragment';
import { Log } from '../../OvertimeDetailLogs.types';

const OVERTIME_LOG_TYPES = [
  'OvertimeArchivedLog',
  'OvertimeCreatedLog',
  'OvertimeDeletedLog',
  'OvertimeEmailSentLog',
  'OvertimeExternalEmailSentLog',
  'OvertimeExternalLinkExpiredLog',
  'OvertimeManuallySignedLog',
  'OvertimeNotSignedLog',
  'OvertimeRenamedLog',
  'OvertimeSignedByCustomerLog',
  'OvertimeUpdatedLog',
];

export function isOvertimeLog(log: Log): log is OvertimeLog {
  return OVERTIME_LOG_TYPES.includes(log.__typename);
}

export interface Actor {
  actorFirstName: string;
  actorLastName: string;
}

type LogWithActor = Extract<Log, Actor>;

export function getActor(log: LogWithActor): Actor {
  return {
    actorFirstName: log.actorFirstName,
    actorLastName: log.actorLastName,
  };
}

export function assertUnknownLog(log: never): never;
export function assertUnknownLog(log: OvertimeLog): never {
  throw new Error(`Unknown log type: ${log.__typename}`);
}

export function getKeyOfLog(log: Log): string | undefined {
  switch (log.__typename) {
    case 'OvertimeExternalEmailSentLog':
      return 'logs.overtimeExternalEmailSent';
    case 'OvertimeEmailSentLog':
      return 'logs.overtimeEmailSent';
    case 'OvertimeCreatedLog':
      return 'logs.overtimeCreated';
    case 'OvertimeDeletedLog':
      return 'logs.overtimeDeleted';
    case 'OvertimeArchivedLog':
      return 'logs.overtimeArchived';
    case 'OvertimeNotSignedLog':
      return 'logs.overtimeNotSigned';
    case 'OvertimeSignedByCustomerLog':
      return 'logs.overtimeSignedByCustomer';
    case 'OvertimeManuallySignedLog':
      return 'logs.overtimeManuallySigned';
    default:
      return undefined;
  }
}
