import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { MissingScopeError } from '@hs-baumappe/redkit';

import { Overtime } from '../../graphql/__generated__/OvertimeFragment';
import { OvertimeScopes } from '../../Overtime.types';
import { OvertimeProject } from '../../graphql/__generated__/OvertimeProject.fragment';
import { OvertimeLocationState } from '../Overtime.route-types';
import { navigateToLocation } from '../route.utils';
import overtimeRoutes from '../overtimeRoutes';
import RouteDialog from '../../components/RouteDialog';
import OvertimeEditBody from './components/OvertimeEditBody';

interface OvertimeEditProps {
  overtime: Overtime;
  project: OvertimeProject;
  scopes: OvertimeScopes;
}

export default function OvertimeEdit({
  overtime,
  project,
  scopes,
}: OvertimeEditProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<OvertimeLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleDialogClose = useCallback(() => {
    navigateToLocation({
      navigate,
      state: location.state,
      fallbackPath: overtimeRoutes.detail.generatePath({ overtimeId: overtime.id }),
    });
  }, [navigate, location.state, overtime.id]);

  function handleDialogRequestClose() {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }

    setDialogOpen(false);
  }

  function handleConfirmationDialogConfirm() {
    setConfirmationDialogOpen(false);
    setDialogOpen(false);
  }

  if (!overtime.viewerCanUpdate) {
    return (
      <Navigate to={overtimeRoutes.detail.generatePath({ overtimeId: overtime.id })} replace />
    );
  }

  const permitted = scopes.updateOvertimeDocument.isPermitted;
  const missingScopeNames = scopes.updateOvertimeDocument.missingScopeNames;

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <>
          <Text variant="body-medium" component="div" color="muted">
            {project.description}
          </Text>
          <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
            {t('updateOvertimeModal.editReportText')}
          </Text>
        </>
      }
    >
      {!permitted ? (
        <MissingScopeError missingScopes={missingScopeNames} />
      ) : (
        <OvertimeEditBody
          overtimeId={overtime.id}
          project={project}
          onFormDirtyStateChange={setFormDirty}
        />
      )}

      <AlertDialog
        open={confirmationDialogOpen}
        title={t('updateOvertimeForm.cancelModal.title')}
        description={t('updateOvertimeForm.cancelModal.message')}
        confirmButtonText={t('updateOvertimeForm.cancelModal.submit')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('updateOvertimeForm.cancelModal.dismiss')}
        onConfirm={handleConfirmationDialogConfirm}
        onCancel={() => setConfirmationDialogOpen(false)}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        destructive
      />
    </RouteDialog>
  );
}
