import { Text } from '@hs-baumappe/legacy-ui';

export function getActorFullName(actor: { actorFirstName: string; actorLastName: string }): string {
  const { actorFirstName, actorLastName } = actor;
  return `${actorFirstName} ${actorLastName}`;
}

interface LogActorProps {
  actor: { actorFirstName: string; actorLastName: string };
}

export default function LogActor({ actor }: LogActorProps): JSX.Element {
  return <Text variant="label-medium">{getActorFullName(actor)}</Text>;
}
