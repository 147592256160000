import { gql, TypedDocumentNode } from '@apollo/client';
import {
  OvertimeProjectEmployeeNames,
  OvertimeProjectEmployeeNamesVariables,
} from './__generated__/OvertimeProjectEmployeeNames.query';

const OvertimeProjectEmployeeNamesQuery: TypedDocumentNode<
  OvertimeProjectEmployeeNames,
  OvertimeProjectEmployeeNamesVariables
> = gql`
  query OvertimeProjectEmployeeNames($projectId: ID!) {
    employeesForProject(id: $projectId) {
      name
    }
  }
`;

export default OvertimeProjectEmployeeNamesQuery;
