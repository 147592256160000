import { gql, TypedDocumentNode } from '@apollo/client';
import {
  OvertimeSignDetail,
  OvertimeSignDetailVariables,
} from './__generated__/OvertimeSignDetail.query';

const OvertimeSignDetailQuery: TypedDocumentNode<OvertimeSignDetail, OvertimeSignDetailVariables> =
  gql`
    query OvertimeSignDetail($overtimeId: ID!) {
      overtime(id: $overtimeId) {
        id

        project {
          id

          contactPeople {
            id
            name
          }
        }

        signatures {
          id

          fullName
          imagePath
          type
          createdAt
        }
      }

      viewer {
        id

        signature
      }
    }
  `;

export default OvertimeSignDetailQuery;
