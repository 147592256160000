import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import UpdateOvertimeDriveDocumentLastUsedMutation from './graphql/UpdateOvertimeDriveDocumentLastUsedMutation';

interface UseUpdateDriveDocumentLastUsedParams {
  id: string;
  skip?: boolean;
}

export default function useUpdateDriveDocumentLastUsed({
  id,
  skip,
}: UseUpdateDriveDocumentLastUsedParams): void {
  const [update] = useMutation(UpdateOvertimeDriveDocumentLastUsedMutation);

  useEffect(() => {
    if (skip) {
      return;
    }

    update({
      variables: {
        input: [{ id }],
      },
    });
  }, [id, update, skip]);
}
