import { gql, TypedDocumentNode } from '@apollo/client';
import OvertimeFragment from '../../graphql/OvertimeFragment';
import {
  RemoveManuallyUploadedOvertime,
  RemoveManuallyUploadedOvertimeVariables,
} from './__generated__/graphql';

const RemoveManuallyUploadedOvertimeMutation: TypedDocumentNode<
  RemoveManuallyUploadedOvertime,
  RemoveManuallyUploadedOvertimeVariables
> = gql`
  mutation RemoveManuallyUploadedOvertime($input: RemoveManuallyUploadedOvertimeInput!) {
    removeManuallyUploadedOvertime(input: $input) {
      ...Overtime
    }
  }

  ${OvertimeFragment}
`;

export default RemoveManuallyUploadedOvertimeMutation;
