import { createPath, generatePath, NavigateFunction, To } from 'react-router-dom';
import { Route, RouteModalLocationState } from './routes.types';

interface NavigateToLocationParams {
  navigate: NavigateFunction;
  state: RouteModalLocationState;
  fallbackPath: To;
}

export function route<Params extends { [K in keyof Params]: string } = Record<string, never>>(
  path: string,
): Route<Params> {
  return {
    path,
    generatePath: (params: Params) => generatePath(path, params),
  };
}

export function navigateToLocation({
  navigate,
  state,
  fallbackPath,
}: NavigateToLocationParams): void {
  if (state?.returnLocation) {
    return navigate(createPath(state.returnLocation));
  }

  if (state?.backgroundLocation) {
    return navigate(-1);
  }

  navigate(fallbackPath);
}
