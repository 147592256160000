import { gql } from '@apollo/client';

const DriveDocumentTagDetailsQuery = gql`
  query DriveDocumentTagDetail($documentId: ID!) {
    driveDocument(id: $documentId) {
      id

      name
      size
    }
  }
`;

export default DriveDocumentTagDetailsQuery;
