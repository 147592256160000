import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { Box } from '@hs-baumappe/legacy-ui';
import { useApolloClient } from '@apollo/client';
import { ErrorRendererPage, NotFoundErrorPage } from '@hs-baumappe/redkit';

import usePublicOvertimeDetail from './hooks/usePublicOvertimeDetail';
import PublicOvertimeDetailContainerBody from './PublicOvertimeDetailContainerBody';
import SignPublicOvertime from '../SignPublicOvertime';
import PublicOvertimeSignatureErrorDialog from '../PublicOvertimeSignatureErrorDialog';
import OvertimeCustomerRouteTypes from '../../OvertimeCustomer.route.types';
import { GetPublicOvertimePdfPathQuery } from './PublicOvertimeDetailContainerBody/graphql/GetPublicOvertimePdfPath.query';

export default function PublicOvertimeDetailContainer(): JSX.Element {
  const apolloClient = useApolloClient();
  const { overtimeId } = useParams<OvertimeCustomerRouteTypes>();
  const { publicOvertime, loading, error, refetch } = usePublicOvertimeDetail(overtimeId);

  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [showSignatureErrorDialog, setShowSignatureErrorDialog] = useState(false);

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRendererPage apolloError={error} />;
  }

  if (!publicOvertime) {
    return <NotFoundErrorPage />;
  }

  /**
   * ```text
   * We should check whether the document is signable when user clicks sign button
   *   If document is signable, continue by opening signature dialog
   *   If document is not signable, show error dialog and refetch pdf details
   * ```
   * For further information please check {@link https://heinrich-schmid.atlassian.net/browse/HS-4075 HS-4075}
   */
  async function handleSignOvertimeClick() {
    if (!publicOvertime?.documentCanBeSigned) {
      setShowSignatureErrorDialog(true);
      return;
    }

    const { data: updatedData } = await refetch({ overtimeId });
    if (!updatedData.publicOvertime?.documentCanBeSigned) {
      setShowSignatureErrorDialog(true);
      await apolloClient.refetchQueries({ include: [GetPublicOvertimePdfPathQuery] });
      return;
    }

    setShowSignatureModal(true);
  }

  return (
    <Box className="container" stylex={{ paddingEnds: 'medium' }}>
      <PublicOvertimeDetailContainerBody
        overtime={publicOvertime}
        onSignOvertimeClick={handleSignOvertimeClick}
      />

      <SignPublicOvertime
        open={showSignatureModal}
        overtimeId={publicOvertime.id}
        overtimeName={publicOvertime.name}
        overtimeStatus={publicOvertime.status}
        overtimeSigned={publicOvertime.signedByCustomer}
        onRequestClose={() => setShowSignatureModal(false)}
        onSuccess={() => setShowSignatureModal(false)}
      />
      <PublicOvertimeSignatureErrorDialog
        open={showSignatureErrorDialog}
        onClose={() => setShowSignatureErrorDialog(false)}
        onRequestClose={() => setShowSignatureErrorDialog(false)}
      />
    </Box>
  );
}
