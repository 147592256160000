import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import useUploadManualPDFOvertime from './useUploadManualPDFOvertime';
import { UploadManualPDFOvertimeRouteParams } from './route';
import { OvertimeLocationState } from '../Overtime.route-types';
import overtimeRoutes from '../overtimeRoutes';
import RouteDialog from '../../components/RouteDialog';
import ErrorState from '../../components/ErrorState';
import UploadManuallySignedReportForm from './components/UploadManuallySignedReportForm';

export default function UploadManualPDFOvertime(): JSX.Element {
  const { t } = useTranslation();

  const { overtimeId } = useParams<UploadManualPDFOvertimeRouteParams>();
  const location = useLocation<OvertimeLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [formDirty, setFormDirty] = useState<boolean | undefined>(false);

  const { uploadFile, error, loading } = useUploadManualPDFOvertime({ overtimeId });

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(location.state.backgroundLocation);
      return;
    }

    navigate(overtimeRoutes.detail.generatePath({ overtimeId }));
  }, [location.state?.backgroundLocation, navigate, overtimeId]);

  function handleDialogRequestClose() {
    if (formDirty) {
      setConfirmationDialogOpen(true);
      return;
    }
    setDialogOpen(false);
  }

  function handleConfirmationDialogConfirm() {
    setDialogOpen(false);
    setConfirmationDialogOpen(false);
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Text variant="body-large" stylex={{ marginBottom: '0' }}>
          {t('uploadSignedOvertimeModal.title')}
        </Text>
      }
    >
      {error && <ErrorState error={error} />}

      <UploadManuallySignedReportForm
        onDirty={() => setFormDirty(true)}
        helperText={t('uploadSignedOvertimeModalForm.message')}
        onSubmit={uploadFile}
        loading={loading}
      />

      <AlertDialog
        open={confirmationDialogOpen}
        title={t('uploadSignedOvertimeModal.closeModal.title')}
        description={t('uploadSignedOvertimeModal.closeModal.content')}
        cancelButtonText={t('updateOvertimeForm.cancelModal.dismiss')}
        confirmButtonText={t('uploadSignedOvertimeModal.closeModal.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onConfirm={handleConfirmationDialogConfirm}
        onCancel={() => setConfirmationDialogOpen(false)}
        onRequestClose={() => setConfirmationDialogOpen(false)}
        destructive
      />
    </RouteDialog>
  );
}
