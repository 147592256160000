import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OvertimeDetailAlert, OvertimeDetailSearchParams } from '../../../../OvertimeDetail.utils';
import { getEnumFromValue } from '../../../../../../utils/enumUtils';

interface UseOvertimeDetailAlertResponse {
  alert?: OvertimeDetailAlert;
  clearAlert: () => void;
}

export default function useOvertimeDetailAlert(): UseOvertimeDetailAlertResponse {
  const [searchParams, setSearchParams] = useSearchParams();

  const alertParam = searchParams.get(OvertimeDetailSearchParams.ALERT);
  const alert = alertParam ? getEnumFromValue(alertParam, OvertimeDetailAlert) : undefined;

  const clearAlert = useCallback(() => {
    setSearchParams(
      (prev) => {
        prev.delete(OvertimeDetailSearchParams.ALERT);
        return prev;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  return {
    alert,
    clearAlert,
  };
}
