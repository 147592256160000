import { OvertimeStatus } from '../../../../globalTypes';

export function getOvertimeStatusColor(status: OvertimeStatus): 'muted' | 'success' {
  switch (status) {
    case OvertimeStatus.ManuallySigned:
    case OvertimeStatus.Signed:
    case OvertimeStatus.Archived:
      return 'success';
    default:
      return 'muted';
  }
}
