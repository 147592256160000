import breadcrumbDe from './locales/de/breadcrumb.json';
import commonDe from './locales/de/common.json';
import driveImageGalleryDe from './locales/de/driveImageGallery.json';
import formsDe from './locales/de/forms.json';
import logDe from './locales/de/log.json';
import overtimeDe from './locales/de/overtime.json';
import validationDe from './locales/de/validation.json';

import breadcrumbFr from './locales/fr/breadcrumb.json';
import commonFr from './locales/fr/common.json';
import driveImageGalleryFr from './locales/fr/driveImageGallery.json';
import formsFr from './locales/fr/forms.json';
import logFr from './locales/fr/log.json';
import validationFr from './locales/fr/validation.json';

import breadcrumbEn from './locales/en/breadcrumb.json';
import commonEn from './locales/en/common.json';
import driveImageGalleryEn from './locales/en/driveImageGallery.json';
import formsEn from './locales/en/forms.json';
import logEn from './locales/en/log.json';
import overtimeEn from './locales/en/overtime.json';
import validationEn from './locales/en/validation.json';

export const resources = {
  de: {
    translation: {
      ...breadcrumbDe,
      ...commonDe,
      ...driveImageGalleryDe,
      ...formsDe,
      ...logDe,
      ...overtimeDe,
      ...validationDe,
    },
  },
  fr: {
    translation: {
      ...breadcrumbFr,
      ...commonFr,
      ...driveImageGalleryFr,
      ...formsFr,
      ...logFr,
      ...validationFr,
    },
  },
  en: {
    translation: {
      ...breadcrumbEn,
      ...commonEn,
      ...driveImageGalleryEn,
      ...formsEn,
      ...logEn,
      ...overtimeEn,
      ...validationEn,
    },
  },
};
