import { Box, Stack } from '@hs-baumappe/legacy-ui';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormikFormField } from '@hs-baumappe/forms';

import { OvertimeFormValues } from '../../form';
import RichTextEditor from '../../../RichTextEditor';

export default function WorkInformationSection(): JSX.Element {
  const { t } = useTranslation();
  const [, , workDescriptionFieldHelpers] =
    useField<OvertimeFormValues['workDescription']>('workDescription');

  return (
    <Stack gap="medium">
      <Box className="row">
        <Box className="col col--sm-6">
          <FormikFormField
            name="workName"
            label={t('overtimeForm.constructionProject.name.label')}
            placeholder={t('overtimeForm.constructionProject.name.placeholder')}
          />
        </Box>
      </Box>
      <Box>
        <FormikFormField<typeof RichTextEditor>
          control={RichTextEditor}
          name="workDescription"
          label={t('overtimeForm.constructionProject.description.label')}
          onChange={(value) => workDescriptionFieldHelpers.setValue(value)}
          imageUploadingDisabled
        />
      </Box>
    </Stack>
  );
}
