import { ApolloError, useQuery } from '@apollo/client';
import OvertimeEditDetailsQuery from './graphql/OvertimeEditDetails.query';
import { OvertimeEditDetails } from './graphql/__generated__/OvertimeEditDetails.query';
import useProjectEmployeeNames from '../../hooks/useProjectEmployeeNames/useProjectEmployeeNames';

interface UseOvertimeEditDetailOptions {
  overtimeId: string;
  projectId: string;
}

interface UseOvertimeEditDetailResponse {
  overtime?: OvertimeEditDetails['overtime'];
  employeeNames: string[];
  loading: boolean;
  error?: ApolloError;
}

const OVERTIME_IMAGE_THUMBNAIL_WIDTH = 56 * 2;
const OVERTIME_IMAGE_THUMBNAIL_HEIGHT = 40 * 2;

export default function useOvertimeEditDetail({
  overtimeId,
  projectId,
}: UseOvertimeEditDetailOptions): UseOvertimeEditDetailResponse {
  const { data, loading, error } = useQuery(OvertimeEditDetailsQuery, {
    variables: {
      overtimeId,
      thumbnailWidth: OVERTIME_IMAGE_THUMBNAIL_WIDTH,
      thumbnailHeight: OVERTIME_IMAGE_THUMBNAIL_HEIGHT,
    },
    fetchPolicy: 'network-only',
  });

  const overtime = data?.overtime;
  const employeeNames = useProjectEmployeeNames(projectId);

  return {
    overtime,
    employeeNames,
    loading,
    error,
  };
}
