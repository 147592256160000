import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogHeader,
  IconButton,
  Icon,
  DialogBody,
  DialogActions,
  Button,
  Text,
} from '@hs-baumappe/legacy-ui';

type PublicOvertimeSignatureErrorDialogProps = Pick<
  ComponentProps<typeof Dialog>,
  'open' | 'onClose' | 'onRequestClose'
>;

export default function PublicOvertimeSignatureErrorDialog({
  open,
  onClose,
  onRequestClose,
}: PublicOvertimeSignatureErrorDialogProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onRequestClose={onRequestClose} size="xsmall" role="alertdialog">
      <DialogHeader rightSlot={<IconButton onClick={onClose} icon={<Icon name="close" />} />}>
        <Text variant="title-small">{t('publicOvertimeDetail.signatureErrorDialog.title')}</Text>
      </DialogHeader>

      <DialogBody>
        <Text component="div" variant="body-medium">
          {t('publicOvertimeDetail.signatureErrorDialog.description')}
        </Text>
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose} fullWidth>
          {t('publicOvertimeDetail.signatureErrorDialog.confirmButtonText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
