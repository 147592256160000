import { useState } from 'react';
import { AlertDialog, Button, Icon } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import useOvertimeRemove from './hooks/useOvertimeRemove';
import ErrorState from '../../../../../../components/ErrorState';

interface OvertimeRemoveButtonProps {
  overtimeId: string;
  overtimeName: string;
  onSuccess: () => void;
}

export default function OvertimeRemoveButton({
  overtimeId,
  overtimeName,
  onSuccess,
}: OvertimeRemoveButtonProps): JSX.Element {
  const { t } = useTranslation();
  const { remove, loading, error } = useOvertimeRemove({ onSuccess });
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleDialogConfirm() {
    setDialogOpen(false);
    remove(overtimeId);
  }

  return (
    <>
      {error && <ErrorState error={error} />}

      <Button
        variant="text"
        color="error"
        fullWidth
        loading={loading}
        className="qa-overtime-detail-remove-button"
        onClick={() => setDialogOpen(true)}
      >
        {t('overtimeDetail.removeOvertime.button')}
      </Button>

      <AlertDialog
        destructive
        open={dialogOpen}
        title={overtimeName}
        description={t('overtimeDetail.removeOvertime.dialog.content')}
        cancelButtonText={t('overtimeDetail.removeOvertime.dialog.cancelButton')}
        confirmButtonText={t('overtimeDetail.removeOvertime.dialog.submitButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        onConfirm={handleDialogConfirm}
        onRequestClose={() => setDialogOpen(false)}
        onCancel={() => setDialogOpen(false)}
      />
    </>
  );
}
