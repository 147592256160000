import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useField } from 'formik';
import UPLOAD_IMAGE_MUTATION from './graphql/UploadOvertimeImage.mutation';
import {
  UploadOvertimeImage,
  UploadOvertimeImageVariables,
} from './graphql/__generated__/UploadOvertimeImage.mutation';
import ImageThumbnail from './components/ImageThumbnail';
import ImageUploadWithLabel, {
  ImageUploadWithLabelFormValues,
} from './components/ImageUploadWithLabel';

interface OvertimeUploadImageWidgetEntryProps {
  name: string;
  onRequestRemove: () => void;
  showRemoveButton?: boolean;
}

export default function OvertimeUploadImageWidgetEntry({
  name,
  onRequestRemove,
  showRemoveButton,
}: OvertimeUploadImageWidgetEntryProps): JSX.Element {
  const { t } = useTranslation();
  const [uploadOvertimeImageMutation, { loading, data }] = useMutation<
    UploadOvertimeImage,
    UploadOvertimeImageVariables
  >(UPLOAD_IMAGE_MUTATION);
  const [imageIdField, , imageIdFieldHelpers] = useField<ImageUploadWithLabelFormValues['imageId']>(
    `${name}.imageId`,
  );
  const [imageUrlField, , imageUrlFieldHelpers] = useField<
    ImageUploadWithLabelFormValues['imageUrl']
  >(`${name}.imageUrl`);
  const [imageThumbnailUrlField, , imageThumbnailUrlFieldHelpers] = useField<
    ImageUploadWithLabelFormValues['imageThumbnailUrl']
  >(`${name}.imageThumbnailUrl`);
  const [fileField] = useField<ImageUploadWithLabelFormValues['file']>(`${name}.file`);

  useEffect(() => {
    const file = fileField.value;

    if (!file || loading || imageIdField.value) {
      return;
    }

    uploadOvertimeImageMutation({
      variables: {
        file,
      },
    });
  }, [fileField.value, uploadOvertimeImageMutation, imageIdField.value, loading]);

  useEffect(() => {
    if (data && !imageIdField.value) {
      imageIdFieldHelpers.setValue(data.uploadImage.id);
      imageUrlFieldHelpers.setValue(data.uploadImage.url);
      imageThumbnailUrlFieldHelpers.setValue(data.uploadImage.url);
    }
  }, [
    data,
    imageIdField.value,
    imageIdFieldHelpers,
    imageUrlFieldHelpers,
    imageThumbnailUrlFieldHelpers,
  ]);

  return (
    <div className="u-margin-bottom-xsmall" data-testid="overtime-upload-image-widget-entry">
      <ImageUploadWithLabel
        name={name}
        labelInputPlaceholder={t('overtimeUploadImageWidget.label.placeholder')}
        loading={loading}
        showRemoveButton={showRemoveButton || !!imageIdField.value}
        originalImageUrl={imageUrlField.value}
        onRequestRemove={onRequestRemove}
        previewSection={(handleShowOriginalImageOpen) => (
          <ImageThumbnail
            width={56}
            height={40}
            src={imageThumbnailUrlField.value}
            onClick={handleShowOriginalImageOpen}
          />
        )}
      />
    </div>
  );
}
