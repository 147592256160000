import { OvertimeCustomerRouteTypes, OvertimeRouteParams } from './Overtime.route-types';
import { route } from './route.utils';

const basePath = '/overtime/:overtimeId';

const overtimeRoutes = {
  detail: route<OvertimeRouteParams>(basePath),
  edit: route<OvertimeRouteParams>(`${basePath}/edit`),
  sendEmail: route<OvertimeRouteParams>(`${basePath}/send-email`),
  sendExternalEmail: route<OvertimeRouteParams>(`${basePath}/send-external-email`),
  archive: route<OvertimeRouteParams>(`${basePath}/archive`),
  sign: route<OvertimeRouteParams>(`${basePath}/sign`),
  manualSign: route<OvertimeRouteParams>(`${basePath}/manual-sign`),
  customer: route<OvertimeCustomerRouteTypes>('customer-detail/:overtimeId'),
};

export default overtimeRoutes;
