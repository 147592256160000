import { gql, TypedDocumentNode } from '@apollo/client';
import OvertimeFragment from '../../../graphql/OvertimeFragment';
import OvertimeProjectFragment from '../../../graphql/OvertimeProject.fragment';
import { OvertimeDetail, OvertimeDetailVariables } from './__generated__/OvertimeDetail.query';

const OvertimeDetailQuery: TypedDocumentNode<OvertimeDetail, OvertimeDetailVariables> = gql`
  query OvertimeDetail($overtimeId: ID!) {
    overtime(id: $overtimeId) {
      ...Overtime

      project {
        ...OvertimeProject
      }
    }
  }

  ${OvertimeFragment}
  ${OvertimeProjectFragment}
`;

export default OvertimeDetailQuery;
