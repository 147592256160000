import { Text } from '@hs-baumappe/legacy-ui';
import { Trans } from 'react-i18next';
import LogActor from '../../LogActor';
import { assertUnknownLog, getActor } from '../../OvertimeLog.utils';
import LogEmails from '../../LogEmails';
import { OvertimeLog } from '../../../../graphql/__generated__/OvertimeLog.fragment';

interface OvertimeLogBodyProps {
  log: OvertimeLog;
}

export default function OvertimeLogBody({ log, ...props }: OvertimeLogBodyProps): JSX.Element {
  switch (log.__typename) {
    case 'OvertimeArchivedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.overtimeArchived"
            values={{ overtimeName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'OvertimeCreatedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.overtimeCreated"
            values={{ overtimeName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'OvertimeDeletedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.overtimeDeleted"
            values={{ overtimeName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'OvertimeEmailSentLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.overtimeEmailSent"
            values={{ overtimeName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );
    case 'OvertimeExternalEmailSentLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.overtimeExternalEmailSent"
            values={{ overtimeName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );
    case 'OvertimeExternalLinkExpiredLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.overtimeExternalLinkExpired"
            values={{ overtimeName: log.name }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'OvertimeManuallySignedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.overtimeManuallySigned"
            values={{ overtimeName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'OvertimeNotSignedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.overtimeNotSigned"
            values={{
              overtimeName: log.name,
              dayCount: log.dayCount || 7,
            }}
            components={{
              bold: <Text variant="label-medium" />,
              warning: <Text color="warning" variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'OvertimeRenamedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.overtimeRenamed"
            values={{ overtimeName: log.name, oldOvertimeName: log.oldName }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    case 'OvertimeSignedByCustomerLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.overtimeSignedByCustomer"
            values={{ overtimeName: log.name }}
            components={{ bold: <Text variant="label-medium" /> }}
          />
        </Text>
      );
    case 'OvertimeUpdatedLog':
      return (
        <Text component="div" variant="body-small" {...props}>
          <Trans
            i18nKey="logs.overtimeUpdated"
            values={{ overtimeName: log.name }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
            }}
          />
        </Text>
      );
    default:
      return assertUnknownLog(log);
  }
}
