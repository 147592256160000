function isEnumName<T extends object>(
  value: string,
  enumValue: T,
): value is Extract<keyof T, string> {
  return value in enumValue;
}

export function stringToEnum<T extends object>(
  name: string,
  enumValue: T,
): T[Extract<keyof T, string>] | undefined {
  if (!isEnumName(name, enumValue)) return undefined;

  return enumValue[name];
}

export function getEnumFromValue<T extends object>(
  value: string,
  enumValue: T,
): T[Extract<keyof T, string>] | undefined {
  const property = Object.entries(enumValue).find(([, v]) => v === value);
  if (!property) {
    return undefined;
  }

  return property[1];
}
