export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

/** Description of acceptance report. */
export type AcceptanceDescription = {
  __typename?: 'AcceptanceDescription';
  /** File reference of acceptance report */
  reference?: Maybe<Scalars['String']['output']>;
  /** Description text of acceptance report acceptance */
  text?: Maybe<Scalars['String']['output']>;
};

/** An acceptance report. */
export type AcceptanceReport = AssignableInterface & DocumentInterface & RemovableInterface & {
  __typename?: 'AcceptanceReport';
  /** This acceptance report is accepted or not */
  acceptance: Scalars['Boolean']['output'];
  /** Description of acceptance report */
  acceptanceDescription?: Maybe<AcceptanceDescription>;
  /** The user who is the owner of this acceptance report */
  addedBy: User;
  /** Alias for the acceptance report */
  alias?: Maybe<Scalars['String']['output']>;
  /** Indicates attach images to pdf */
  attachImagesToPdf: Scalars['Boolean']['output'];
  /** Attendees of the acceptance report */
  attendees: Array<AcceptanceReportAttendee>;
  /** Name of the client for the acceptance report */
  clientName?: Maybe<Scalars['String']['output']>;
  /** Computed client name value for acceptance report */
  clientNameComputed?: Maybe<Scalars['String']['output']>;
  /** Contractor of the acceptance report */
  contractor: Scalars['String']['output'];
  /** Creation date of the acceptance report */
  createdAt: Scalars['Date']['output'];
  /** Date of the acceptance report */
  date: Scalars['Date']['output'];
  /** Defect data of the acceptance report */
  defect?: Maybe<AcceptanceReportDefect>;
  /** Description of the acceptance report */
  description?: Maybe<Scalars['String']['output']>;
  /** Draft project number */
  draftProjectNumber?: Maybe<Scalars['String']['output']>;
  /** This acceptance report is defect or not */
  hasDefect: Scalars['Boolean']['output'];
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Images of the acceptance report */
  images: Array<AcceptanceReportImageWithLabel>;
  /** Logs of the acceptance report */
  logs?: Maybe<Logs>;
  /** Manual uploaded pdf path for the acceptance report. */
  manualPdfPath?: Maybe<Scalars['String']['output']>;
  /** Name of the document */
  name: Scalars['String']['output'];
  /** No of the acceptance report */
  no: Scalars['Int']['output'];
  /** Computed No of the acceptance report */
  noComputed: Scalars['String']['output'];
  /** Pdf path for the acceptance report if it has been generated before. */
  pdfPath?: Maybe<Scalars['String']['output']>;
  /** Contractor of the acceptance report */
  professions?: Maybe<Array<Scalars['String']['output']>>;
  /** Project of the acceptance report */
  project: Project;
  /** Project name of the acceptance report */
  projectName: Scalars['String']['output'];
  /** Computed project number for acceptance report */
  projectNumberComputed?: Maybe<Scalars['String']['output']>;
  /** Signatures of the acceptance report */
  signatures: Array<AcceptanceReportSignature>;
  /** Status of the acceptance report */
  status: AcceptanceReportStatus;
  /** Updated date of the acceptance report */
  updatedAt: Scalars['Date']['output'];
  /** Indicates whether the viewer can archive the acceptance report */
  viewerCanArchive: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can assign the acceptance report to a project */
  viewerCanAssign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the acceptance report */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove manual pdf from the acceptance report */
  viewerCanRemoveManualPdf: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can send external link */
  viewerCanSendExternalLink: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can sign the acceptance report */
  viewerCanSign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the acceptance report */
  viewerCanUpdate: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can upload manual pdf to the acceptance report */
  viewerCanUploadManualPdf: Scalars['Boolean']['output'];
};


/** An acceptance report. */
export type AcceptanceReportLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type AcceptanceReportArchivedLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'AcceptanceReportArchivedLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

/** Attendee of acceptance report */
export type AcceptanceReportAttendee = {
  __typename?: 'AcceptanceReportAttendee';
  /** Full name of the attendee */
  fullName: Scalars['String']['output'];
  /** ID of the attendee */
  id: Scalars['ID']['output'];
  /** Position of the attendee */
  position?: Maybe<Scalars['String']['output']>;
  /** Role of the attendee */
  role: AcceptanceReportAttendeeRole;
};

/** Acceptance report attendee role enum */
export enum AcceptanceReportAttendeeRole {
  Client = 'CLIENT',
  HeinrichSchmid = 'HEINRICH_SCHMID',
  Other = 'OTHER'
}

export type AcceptanceReportAttendeeSaveInput = {
  /** Full name of the attendee. */
  fullName: Scalars['String']['input'];
  /** Position/function of the attendee. */
  position?: InputMaybe<Scalars['String']['input']>;
  /** Role of the attendee. */
  role: AcceptanceReportAttendeeRole;
};

export type AcceptanceReportCreatedLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'AcceptanceReportCreatedLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

/** Defect data of acceptance report. */
export type AcceptanceReportDefect = {
  __typename?: 'AcceptanceReportDefect';
  /** Whether the defects have been agreed by client or not */
  agreed: Scalars['Boolean']['output'];
  /** Due date of the acceptance report */
  dueDate: Scalars['Date']['output'];
  /** Defects of acceptance report */
  metaData?: Maybe<Array<AcceptanceReportDefectMeta>>;
  /** Note for acceptance report defect */
  note?: Maybe<Scalars['String']['output']>;
  /** File reference of acceptance report defect */
  reference?: Maybe<Scalars['String']['output']>;
};

/** Meta data of an acceptance report defect */
export type AcceptanceReportDefectMeta = {
  __typename?: 'AcceptanceReportDefectMeta';
  /** Description of the defect */
  description: Scalars['String']['output'];
  /** Image of the defect */
  image?: Maybe<AcceptanceReportImage>;
};

export type AcceptanceReportDefectMetaSaveInput = {
  /** Description text of the defect. */
  description: Scalars['String']['input'];
  /** Id of the defect image. */
  imageId?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportDefectSaveInput = {
  /** Did client agree on defects or not. */
  agreed: Scalars['Boolean']['input'];
  /** Due date of the acceptance report. */
  dueDate: Scalars['Date']['input'];
  /** Meta data of the defect. */
  metaData?: InputMaybe<Array<AcceptanceReportDefectMetaSaveInput>>;
  /** Note of acceptance report defect */
  note?: InputMaybe<Scalars['String']['input']>;
  /** File reference of the acceptance report defect. */
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportDeletedLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'AcceptanceReportDeletedLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportDescriptionSaveInput = {
  /** File reference of acceptance */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Description text of acceptance */
  text?: InputMaybe<Scalars['String']['input']>;
};

export type AcceptanceReportEmailSentLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & LogWithEmail & {
  __typename?: 'AcceptanceReportEmailSentLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that acceptance report sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportExternalEmailSentLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & LogWithEmail & {
  __typename?: 'AcceptanceReportExternalEmailSentLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that acceptance report sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportExternalLinkExpiredLog = AcceptanceReportLog & Log & LogWithDate & {
  __typename?: 'AcceptanceReportExternalLinkExpiredLog';
  /** ID of the acceptanceReport */
  acceptanceReportId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Expired date of the acceptance report external link in ISO-8601 format */
  expiredDate: Scalars['Date']['output'];
  /** ID of the acceptanceReport */
  id: Scalars['ID']['output'];
  /** Name of the acceptanceReport */
  name: Scalars['String']['output'];
};

/** Defect images of the acceptance report */
export type AcceptanceReportImage = {
  __typename?: 'AcceptanceReportImage';
  /** ID of the image */
  id: Scalars['ID']['output'];
  /** Thumbnail image for the acceptance report. */
  thumbnail: Scalars['String']['output'];
  /** Full url of the image */
  url: Scalars['String']['output'];
};


/** Defect images of the acceptance report */
export type AcceptanceReportImageThumbnailArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type AcceptanceReportImageInput = {
  /** Id of the image. */
  imageId: Scalars['ID']['input'];
  /** Label for the image. */
  label?: InputMaybe<Scalars['String']['input']>;
};

/** Defect images of the acceptance report */
export type AcceptanceReportImageWithLabel = {
  __typename?: 'AcceptanceReportImageWithLabel';
  /** ID of the image */
  id: Scalars['ID']['output'];
  /** Label of the image */
  label?: Maybe<Scalars['String']['output']>;
  /** Thumbnail image for the acceptance report. */
  thumbnail: Scalars['String']['output'];
  /** Full url of the image */
  url: Scalars['String']['output'];
};


/** Defect images of the acceptance report */
export type AcceptanceReportImageWithLabelThumbnailArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type AcceptanceReportLog = {
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportManuallySignedLog = AcceptanceReportLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'AcceptanceReportManuallySignedLog';
  /** ID of the acceptanceReport */
  acceptanceReportId: Scalars['ID']['output'];
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the acceptanceReport */
  id: Scalars['ID']['output'];
  /** Name of the acceptanceReport */
  name: Scalars['String']['output'];
};

export type AcceptanceReportNotSignedLog = AcceptanceReportLog & Log & LogWithDate & {
  __typename?: 'AcceptanceReportNotSignedLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The day count of that document not signed */
  dayCount?: Maybe<Scalars['Int']['output']>;
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

export type AcceptanceReportPdfPath = {
  __typename?: 'AcceptanceReportPdfPath';
  acceptanceReportId: Scalars['ID']['output'];
  manualPdfPath?: Maybe<Scalars['String']['output']>;
  pdfPath?: Maybe<Scalars['String']['output']>;
};

export type AcceptanceReportSaveInput = {
  /** Is acceptance report accepted or not. */
  acceptance: Scalars['Boolean']['input'];
  /** Description of acceptance report. */
  acceptanceDescription?: InputMaybe<AcceptanceReportDescriptionSaveInput>;
  /** Alias for the acceptance report no. */
  alias?: InputMaybe<Scalars['String']['input']>;
  /** Indicates attach defect images to pdf. This flag is ONLY valid for the defect images, not for the regular images. */
  attachImagesToPdf: Scalars['Boolean']['input'];
  /** Attendees of the acceptance report. */
  attendees: Array<AcceptanceReportAttendeeSaveInput>;
  /** Name of the client. */
  clientName: Scalars['String']['input'];
  /** Contractor of the acceptance report. */
  contractor: Scalars['String']['input'];
  /** Date of acceptance. */
  date: Scalars['Date']['input'];
  /** Defect data of the acceptance report */
  defect?: InputMaybe<AcceptanceReportDefectSaveInput>;
  /** Description of the acceptance report. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Document ID of the acceptance report. */
  documentId: Scalars['ID']['input'];
  /** Draft project number. It can be applied only for draft projects. */
  draftProjectNumber?: InputMaybe<Scalars['String']['input']>;
  /** Is acceptance report defect or not */
  hasDefect: Scalars['Boolean']['input'];
  /** Images for the acceptance report. */
  images?: InputMaybe<Array<AcceptanceReportImageInput>>;
  /** Contractor of the acceptance report. */
  professions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Name of the acceptance report. */
  projectName: Scalars['String']['input'];
};

export type AcceptanceReportSignature = SignatureInterface & {
  __typename?: 'AcceptanceReportSignature';
  /** Acceptance report of the signature */
  acceptanceReport: AcceptanceReport;
  /** Creation date of the signature */
  createdAt: Scalars['Date']['output'];
  /** Full name of the signatory */
  fullName: Scalars['String']['output'];
  /** ID of the signature */
  id: Scalars['ID']['output'];
  /** Image path of the signature */
  imagePath: Scalars['String']['output'];
  /** Type of the signature */
  type: AcceptanceReportSignatureType;
};

/** Acceptance Report Signature type enum */
export enum AcceptanceReportSignatureType {
  Customer = 'CUSTOMER',
  HeinrichSchmid = 'HEINRICH_SCHMID'
}

export type AcceptanceReportSignedByCustomerLog = AcceptanceReportLog & Log & LogWithDate & {
  __typename?: 'AcceptanceReportSignedByCustomerLog';
  /** ID of the acceptance report */
  acceptanceReportId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the acceptance report */
  id: Scalars['ID']['output'];
  /** Name of the acceptance report */
  name: Scalars['String']['output'];
};

/** Acceptance report status enum */
export enum AcceptanceReportStatus {
  Archived = 'ARCHIVED',
  ManuallySigned = 'MANUALLY_SIGNED',
  NotAgreed = 'NOT_AGREED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED',
  SignedWithDefects = 'SIGNED_WITH_DEFECTS'
}

export type AddBulkOperationToFolderInput = {
  /** ID of the folder. */
  folder: Scalars['ID']['input'];
  /** IDs of the catalogue operations */
  operations: Array<Scalars['ID']['input']>;
};

export type AddOperationToFolderInput = {
  /** Is this operation's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the category. */
  category?: InputMaybe<Scalars['ID']['input']>;
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Id of the category. */
  folder: Scalars['ID']['input'];
  /** Measurements of operation */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Note for the operation */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the operation. */
  operation?: InputMaybe<Scalars['ID']['input']>;
  /** Is this operation optional */
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  /** Operation payment input. Please send totalPrice flat is true, otherwise please send discount/surcharge value and type. If you do not have any price adjustment please do not send this input at all. */
  payment?: InputMaybe<OperationPaymentInput>;
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type ArchiveDriveBulkJob = DriveBulkJob & {
  __typename?: 'ArchiveDriveBulkJob';
  documents: Array<DriveDocument>;
  id: Scalars['ID']['output'];
  meta: ArchiveDriveBulkJobMeta;
  status: DriveBulkJobStatus;
};

export type ArchiveDriveBulkJobMeta = {
  __typename?: 'ArchiveDriveBulkJobMeta';
  failed: Array<ArchiveDriveBulkJobMetaFailure>;
  queue: Array<DriveDocument>;
  succeed: Array<DriveDocument>;
};

export type ArchiveDriveBulkJobMetaFailure = {
  __typename?: 'ArchiveDriveBulkJobMetaFailure';
  document: DriveDocument;
  reason: ArchiveDriveBulkJobMetaFailureReason;
  reasonDetail?: Maybe<Scalars['String']['output']>;
};

export enum ArchiveDriveBulkJobMetaFailureReason {
  InvalidCredentials = 'INVALID_CREDENTIALS',
  ServerError = 'SERVER_ERROR'
}

export type AssignDocumentToProjectInput = {
  id: Scalars['ID']['input'];
  project: Scalars['ID']['input'];
  type: DocumentType;
};

export type AssignOfferToProjectInput = {
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
  /** Project ID that offer will be assigned to. */
  to: Scalars['ID']['input'];
};

export type AssignableInterface = {
  id: Scalars['ID']['output'];
  project: Project;
  viewerCanAssign: Scalars['Boolean']['output'];
};

/** Catalogue is the operation portfolio of user. */
export type Catalogue = {
  __typename?: 'Catalogue';
  /** All categories of catalogue in flat fashion. It contains all categories and sub categories in a single array. */
  allCategories: Array<Category>;
  /** Categories of the catalogue. */
  categories: Array<Category>;
  /** ID of the catalogue */
  id: Scalars['ID']['output'];
  /** Meta of the catalogue. */
  meta: CatalogueMeta;
  /** Name of the catalogue */
  name: Scalars['String']['output'];
  /** Offers of the catalogue */
  offers: Array<Offer>;
};


/** Catalogue is the operation portfolio of user. */
export type CatalogueOffersArgs = {
  limit?: Scalars['Float']['input'];
};

/** Count of offers that has relationship with this catalogue */
export type CatalogueMeta = {
  __typename?: 'CatalogueMeta';
  /** Count of parent offers */
  offerCount: Scalars['Int']['output'];
};

/** Category for the catalogue. */
export type Category = {
  __typename?: 'Category';
  /** Calculated part no of the category */
  calculatedPartNo: Scalars['String']['output'];
  /** Catalogue of the category. */
  catalogue: Catalogue;
  /** ID of the category */
  id: Scalars['ID']['output'];
  /** Name of the category */
  name?: Maybe<Scalars['String']['output']>;
  /** Operations for the category. */
  operations: Array<Operation>;
  /** Parent of the category. */
  parent?: Maybe<Category>;
  /** Part no of the category */
  partNo: Scalars['String']['output'];
  /** Subcategories the category. */
  subCategories: Array<Category>;
};


/** Category for the catalogue. */
export type CategoryOperationsArgs = {
  catalogue?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

/** Client is the type for client. */
export type Client = {
  __typename?: 'Client';
  /** Address of the client */
  address?: Maybe<Scalars['String']['output']>;
  /** HS id of the client */
  hsId: Scalars['String']['output'];
  /** ID of the client */
  id: Scalars['ID']['output'];
  /** Name of the client */
  name: Scalars['String']['output'];
};

export type CloneFolderInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the source. */
  from: Scalars['ID']['input'];
  /** Measurements of clone folder */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the clone folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type CloneOperationInput = {
  /** Is this operation's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Id of the source operation. */
  from: Scalars['ID']['input'];
  /** Measurements of clone operation */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Note for the clone operation */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Is this operation optional */
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  /** Operation payment input. Please send totalPrice flat is true, otherwise please send discount/surcharge value and type. If you do not have any price adjustment please do not send this input at all. */
  payment?: InputMaybe<OperationPaymentInput>;
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

/** A concern. */
export type Concern = DocumentInterface & {
  __typename?: 'Concern';
  /** The user who is the owner of this concern */
  addedBy: User;
  /** Name of the client for the concern */
  clientName?: Maybe<Scalars['String']['output']>;
  /** contractor of the concern */
  contractor?: Maybe<Contractor>;
  /** Creation date of the concern */
  createdAt: Scalars['Date']['output'];
  /** Date of the concern */
  date?: Maybe<Scalars['Date']['output']>;
  /** End date of concern */
  endDate: Scalars['Date']['output'];
  /** execution of the concern */
  execution?: Maybe<Scalars['String']['output']>;
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Images of the concern */
  images: Array<ConcernImage>;
  /** Logs of the concern */
  logs?: Maybe<Logs>;
  /** Name of the document */
  name: Scalars['String']['output'];
  /** Pdf path for the concern if it has been generated before */
  pdfPath?: Maybe<Scalars['String']['output']>;
  /** professions of the concern */
  professions?: Maybe<Array<Scalars['String']['output']>>;
  /** Project of the concern */
  project: Project;
  /** quality of the concern */
  quality?: Maybe<Array<Quality>>;
  /** Signatures of concern */
  signatures: Array<ConcernSignature>;
  /** Status of the concern */
  status: ConcernStatus;
  /** Updated date of the concern */
  updatedAt: Scalars['Date']['output'];
  /** Indicates whether the viewer can archive the concern */
  viewerCanArchive: Scalars['Boolean']['output'];
  /** Indicated whether the concern assignable to a project */
  viewerCanAssign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the concern */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can sign the concern */
  viewerCanSign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the concern */
  viewerCanUpdate: Scalars['Boolean']['output'];
};


/** A concern. */
export type ConcernLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ConcernArchiveInput = {
  /** Id of the concern. */
  documentId: Scalars['ID']['input'];
  /** SAP password */
  sapPassword: Scalars['String']['input'];
  /** SAP username */
  sapUsername: Scalars['String']['input'];
};

export type ConcernArchivedLog = ConcernLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConcernArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

export type ConcernCreatedLog = ConcernLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConcernCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

export type ConcernDeletedLog = ConcernLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConcernDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

export type ConcernDuplicateInput = {
  documentId: Scalars['ID']['input'];
};

export type ConcernEmailSentLog = ConcernLog & Log & LogWithActor & LogWithDate & LogWithEmail & {
  __typename?: 'ConcernEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that concern sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

/** An concern image */
export type ConcernImage = {
  __typename?: 'ConcernImage';
  /** ID of the image */
  id: Scalars['ID']['output'];
  /** Label of the image */
  label?: Maybe<Scalars['String']['output']>;
  /** Thumbnail of the image */
  thumbnail: Scalars['String']['output'];
  /** Full url of the image */
  url: Scalars['String']['output'];
};


/** An concern image */
export type ConcernImageThumbnailArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type ConcernImageInput = {
  /** Id of the image. */
  imageId: Scalars['ID']['input'];
  /** Label for the image. */
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ConcernLog = {
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

export type ConcernPdfEmailSendInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of concern */
  documentId: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type ConcernSaveInput = {
  /** Clientname of concern */
  clientName: Scalars['String']['input'];
  /** Contractor of concern */
  contractor?: InputMaybe<ContractorSaveInput>;
  /** Date of concern */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Document id for the concern */
  documentId: Scalars['ID']['input'];
  /** End date of concern */
  endDate: Scalars['Date']['input'];
  /** Execution of concern */
  execution?: InputMaybe<Scalars['String']['input']>;
  /** Images for the obstruction */
  images: Array<ConcernImageInput>;
  /** Professions of concern */
  professions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Quality of concern */
  quality?: InputMaybe<Array<QualitySaveInput>>;
};

export type ConcernSignInput = {
  /** ID of the concern */
  documentId: Scalars['ID']['input'];
  /** Full name of the signatory */
  fullName: Scalars['String']['input'];
  /** Image of the signature */
  image: Scalars['Upload']['input'];
};

export type ConcernSignature = {
  __typename?: 'ConcernSignature';
  /** Creation date of the signature */
  createdAt: Scalars['Date']['output'];
  /** Full name of the signatory */
  fullName: Scalars['String']['output'];
  /** Image path of the signature */
  imagePath: Scalars['String']['output'];
};

export type ConcernSignedLog = ConcernLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConcernSignedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the concern */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the concern */
  id: Scalars['ID']['output'];
  /** Name of the concern */
  name: Scalars['String']['output'];
};

/** Concern status enum */
export enum ConcernStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export type ConcernUpdatedLog = ConcernLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConcernUpdatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the obstruction */
  concernId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
};

/** Construction note type */
export type ConstructionNote = AssignableInterface & DocumentInterface & RemovableInterface & {
  __typename?: 'ConstructionNote';
  /** The user who is owner of this construction note */
  addedBy: User;
  /** ID of the construction note */
  id: Scalars['ID']['output'];
  /** Logs of the construction note */
  logs?: Maybe<Logs>;
  /** Name of the construction note */
  name: Scalars['String']['output'];
  /** No of the construction note */
  no: Scalars['Int']['output'];
  /** Path of the asset. For free drawing notes, client needs to download the json file, and construct canvas with upcoming data. For other types, this link can be used directly to show asset */
  path: Scalars['String']['output'];
  /** Project of the construction note */
  project: Project;
  /** Status of construction note */
  status: ConstructionNoteStatus;
  /** Thumbnail image for the construction note. It only supports image construction notes. */
  thumbnail: Scalars['String']['output'];
  /** Type of the construction note */
  type: ConstructionNoteType;
  /** Last modified date for the offer */
  updatedAt: Scalars['Date']['output'];
  /** Indicates whether the viewer can archive the construction note */
  viewerCanArchive: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can assign the construction note to a project */
  viewerCanAssign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the construction note */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can rename the construction note */
  viewerCanRename: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the construction note */
  viewerCanUpdate: Scalars['Boolean']['output'];
};


/** Construction note type */
export type ConstructionNoteLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


/** Construction note type */
export type ConstructionNoteThumbnailArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type ConstructionNoteArchivedLog = ConstructionNoteLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConstructionNoteArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the construction note */
  constructionNoteId: Scalars['ID']['output'];
  /** Type of the construction note */
  constructionNoteType?: Maybe<ConstructionNoteType>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the construction note */
  id: Scalars['ID']['output'];
  /** Name of the construction note */
  name: Scalars['String']['output'];
};

export type ConstructionNoteCanvasUpdateInput = {
  /** Document ID of the construction note. */
  documentId: Scalars['ID']['input'];
};

export type ConstructionNoteCreateInput = {
  /** Document ID of the construction note. */
  documentId: Scalars['ID']['input'];
  /** Construction note name. */
  name: Scalars['String']['input'];
};

export type ConstructionNoteCreatedLog = ConstructionNoteLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConstructionNoteCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the construction note */
  constructionNoteId: Scalars['ID']['output'];
  /** Type of the construction note */
  constructionNoteType?: Maybe<ConstructionNoteType>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the construction note */
  id: Scalars['ID']['output'];
  /** Name of the construction note */
  name: Scalars['String']['output'];
};

export type ConstructionNoteDeletedLog = ConstructionNoteLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'ConstructionNoteDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the construction note */
  constructionNoteId: Scalars['ID']['output'];
  /** Type of the construction note */
  constructionNoteType?: Maybe<ConstructionNoteType>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the construction note */
  id: Scalars['ID']['output'];
  /** Name of the construction note */
  name: Scalars['String']['output'];
};

export type ConstructionNoteEmailSentLog = ConstructionNoteLog & Log & LogWithActor & LogWithDate & LogWithEmail & {
  __typename?: 'ConstructionNoteEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the construction note */
  constructionNoteId: Scalars['ID']['output'];
  /** Type of the construction note */
  constructionNoteType?: Maybe<ConstructionNoteType>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that constructin note sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the construction note */
  id: Scalars['ID']['output'];
  /** Name of the construction note */
  name: Scalars['String']['output'];
};

export type ConstructionNoteLog = {
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the construction note */
  constructionNoteId: Scalars['ID']['output'];
  /** Type of the construction note */
  constructionNoteType?: Maybe<ConstructionNoteType>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the construction note */
  name: Scalars['String']['output'];
};

/** Construction note status enum */
export enum ConstructionNoteStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN',
  Sent = 'SENT'
}

/** Construction note type enum */
export enum ConstructionNoteType {
  FreeDrawing = 'FREE_DRAWING',
  Image = 'IMAGE',
  Pdf = 'PDF'
}

export type ConstructionNoteUpdateInput = {
  /** Document ID of the construction note. */
  documentId: Scalars['ID']['input'];
  /** Construction note name. */
  name: Scalars['String']['input'];
};

/** Contact person for a project. */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  /** E-mail of the person */
  email?: Maybe<Scalars['String']['output']>;
  /** ID of the person */
  id: Scalars['ID']['output'];
  /** Name of the person */
  name?: Maybe<Scalars['String']['output']>;
  /** Note for the person */
  note?: Maybe<Scalars['String']['output']>;
  /** Phone number of the person */
  phone?: Maybe<Scalars['String']['output']>;
  /** Project of the contact person */
  project: Project;
};

export type ContactPersonAddInput = {
  /** Contact person e-mail */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Contact person name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Note for contact person */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Contact person phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Project id of the which project where contact person belongs */
  projectId: Scalars['ID']['input'];
};

export type ContactPersonRemoveInput = {
  /** Id of the contact person */
  id: Scalars['ID']['input'];
};

export type ContactPersonUpdateInput = {
  /** Contact person e-mail */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Id of the contact person */
  id: Scalars['ID']['input'];
  /** Contact person name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Note for contact person */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Contact person phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Contractor = {
  __typename?: 'Contractor';
  /** Description of contractor */
  description: Scalars['String']['output'];
  /** Name of contractor */
  name: Scalars['String']['output'];
};

export type ContractorSaveInput = {
  /** Description of contractor */
  description: Scalars['String']['input'];
  /** Name of contractor */
  name: Scalars['String']['input'];
};

export type CreateFolderForFrameworkInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the catalogue category. */
  category: Scalars['ID']['input'];
  /** Measurements of folder */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
  /** Id of the parent folder. */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type CreateFolderForFreeFormInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Measurements of folder */
  measurements: Array<CreateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
  /** Id of the parent folder. */
  parent?: InputMaybe<Scalars['ID']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type CreateMeasurementInput = {
  meta: Array<Scalars['String']['input']>;
  multiplier?: InputMaybe<Scalars['Float']['input']>;
  /** Offer name. */
  name: Scalars['String']['input'];
  /** Is subtraction enabled */
  subtraction: Scalars['Boolean']['input'];
  /** Total measurement */
  total: Scalars['Float']['input'];
  /** Total measurement meta for the operation. */
  totalMeta: Scalars['String']['input'];
};

export type CreateOperationInput = {
  /** Id of the category. */
  category: Scalars['ID']['input'];
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type CustomFile = {
  __typename?: 'CustomFile';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  /** Logs of the custom file */
  logs?: Maybe<Logs>;
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  no: Scalars['String']['output'];
  size: Scalars['Float']['output'];
  url: Scalars['String']['output'];
};


export type CustomFileLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomFileArchivedLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The folder ID the document */
  folderId?: Maybe<Scalars['ID']['output']>;
  /** The folder Name the document */
  folderName?: Maybe<Scalars['String']['output']>;
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
};

export type CustomFileCreatedLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The folder ID the document */
  folderId?: Maybe<Scalars['ID']['output']>;
  /** The folder Name the document */
  folderName?: Maybe<Scalars['String']['output']>;
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
  /** The source of the document */
  source: CustomFileSource;
};

export type CustomFileDeletedLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The folder ID the document */
  folderId?: Maybe<Scalars['ID']['output']>;
  /** The folder Name the document */
  folderName?: Maybe<Scalars['String']['output']>;
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
};

export type CustomFileDuplicatedForManipulationLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileDuplicatedForManipulationLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Original custom file's name */
  duplicatedFromName: Scalars['String']['output'];
  /** The folder ID the document */
  folderId: Scalars['ID']['output'];
  /** The folder Name the document */
  folderName: Scalars['String']['output'];
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
};

export type CustomFileEmailSendInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the custom file. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type CustomFileEmailSentLog = CustomFileLog & Log & LogWithActor & LogWithDate & LogWithEmail & {
  __typename?: 'CustomFileEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that customFile sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
};

export type CustomFileLog = {
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
};

export type CustomFileManipulatedLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileManipulatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The folder ID the document */
  folderId: Scalars['ID']['output'];
  /** The folder Name the document */
  folderName: Scalars['String']['output'];
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** Name of the custom file */
  name: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
};

export type CustomFileRenamedLog = CustomFileLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'CustomFileRenamedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** ID of the custom file */
  customFileId: Scalars['ID']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The folder ID the document */
  folderId?: Maybe<Scalars['ID']['output']>;
  /** The folder Name the document */
  folderName?: Maybe<Scalars['String']['output']>;
  /** ID of the custom file */
  id: Scalars['ID']['output'];
  /** New name of the custom file */
  name: Scalars['String']['output'];
  /** Old name of the custom file */
  oldName: Scalars['String']['output'];
  /** The project ID the document */
  projectId: Scalars['ID']['output'];
};

export enum CustomFileSource {
  Baumappe = 'BAUMAPPE',
  Hspass = 'HSPASS',
  Sap = 'SAP'
}

export type CustomFileUploadInput = {
  documentId: Scalars['ID']['input'];
  file: Scalars['Upload']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};

export type DigitalSalary = {
  __typename?: 'DigitalSalary';
  id: Scalars['ID']['output'];
  /** @deprecated Use `scopes` from ViewerUserScopes */
  scopes: ViewerDigitalSalaryScopes;
};

export enum DigitalSalaryHistoryAction {
  CommercialApprove = 'COMMERCIAL_APPROVE',
  SapTransmission = 'SAP_TRANSMISSION',
  TechnicalApprove = 'TECHNICAL_APPROVE'
}

export type DigitalSalaryHistoryItem = {
  __typename?: 'DigitalSalaryHistoryItem';
  action: DigitalSalaryHistoryAction;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  user: User;
};

export enum DigitalSalaryRole {
  CommercialApprover = 'COMMERCIAL_APPROVER',
  SapTransmission = 'SAP_TRANSMISSION',
  TechnicalApprover = 'TECHNICAL_APPROVER'
}

/** Discount or surcharge value enum */
export enum DiscountOrSurchargeValueType {
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE'
}

export type DocumentInterface = {
  addedBy: User;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  project: Project;
};

export type DocumentLog = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document */
  documentId: Scalars['ID']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the document */
  name: Scalars['String']['output'];
};

export enum DocumentManipulator {
  Pspdfkit = 'PSPDFKIT'
}

export type DocumentMovedLog = DocumentLog & Log & LogWithActor & LogWithDate & {
  __typename?: 'DocumentMovedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  documentId: Scalars['ID']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Name of the document log */
  name: Scalars['String']['output'];
  /** ID of the project */
  projectId: Scalars['ID']['output'];
  /** The folder of the document before move operation */
  sourceFolder: FolderData;
  /** The folder of the document after move operation */
  targetFolder: FolderData;
};

export enum DocumentType {
  AcceptanceReport = 'ACCEPTANCE_REPORT',
  Concern = 'CONCERN',
  ConstructionNote = 'CONSTRUCTION_NOTE',
  CustomFile = 'CUSTOM_FILE',
  Obstruction = 'OBSTRUCTION',
  Offer = 'OFFER',
  Overtime = 'OVERTIME',
  Qsst = 'QSST',
  RiskAssessment = 'RISK_ASSESSMENT',
  SivBillingAttachment = 'SIV_BILLING_ATTACHMENT'
}

export type DownloadDriveBulkJob = DriveBulkJob & {
  __typename?: 'DownloadDriveBulkJob';
  documents: Array<DriveDocument>;
  id: Scalars['ID']['output'];
  meta: DownloadDriveBulkJobMeta;
  status: DriveBulkJobStatus;
};

export type DownloadDriveBulkJobLink = {
  __typename?: 'DownloadDriveBulkJobLink';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DownloadDriveBulkJobMeta = {
  __typename?: 'DownloadDriveBulkJobMeta';
  link?: Maybe<DownloadDriveBulkJobLink>;
};

export type Drive = {
  __typename?: 'Drive';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  mimeTypeFolderMap: Array<DriveMimeTypeFolderMapItem>;
  rootFolder: DriveFolder;
  /** @deprecated Use `scopes` from ViewerUserScopes */
  scopes: ViewerDriveScopes;
  updatedAt: Scalars['Date']['output'];
};

export type DriveApp = {
  __typename?: 'DriveApp';
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DriveBulkJob = {
  documents: Array<DriveDocument>;
  id: Scalars['ID']['output'];
  status: DriveBulkJobStatus;
};

export type DriveBulkJobCancelInput = {
  id: Scalars['ID']['input'];
};

export type DriveBulkJobRemoveInput = {
  id: Scalars['ID']['input'];
};

export type DriveBulkJobRemoveResult = {
  __typename?: 'DriveBulkJobRemoveResult';
  status: Scalars['Boolean']['output'];
};

export enum DriveBulkJobStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Queued = 'QUEUED'
}

export type DriveDocument = Notable & Sortable & {
  __typename?: 'DriveDocument';
  addedBy: User;
  createdAt: Scalars['Date']['output'];
  download?: Maybe<DriveDocumentDownload>;
  folder: DriveFolder;
  id: Scalars['ID']['output'];
  lastChangedDate: Scalars['Date']['output'];
  lastUsedDate?: Maybe<Scalars['Date']['output']>;
  manipulable: Scalars['Boolean']['output'];
  manipulated: Scalars['Boolean']['output'];
  manipulator?: Maybe<DocumentManipulator>;
  /** Meta of the document */
  meta?: Maybe<DriveDocumentMeta>;
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  no: Scalars['String']['output'];
  note?: Maybe<DriveEntryNote>;
  parentFolders: Array<DriveFolder>;
  preview?: Maybe<DriveDocumentPreview>;
  project: Project;
  size?: Maybe<Scalars['Int']['output']>;
  status: DriveDocumentStatus;
  thumbnail?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  viewerCanArchive: Scalars['Boolean']['output'];
  viewerCanManipulate: Scalars['Boolean']['output'];
  viewerCanMove: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanRename: Scalars['Boolean']['output'];
  viewerCanSendEmail: Scalars['Boolean']['output'];
  viewerCanSendExternalLinkEmail: Scalars['Boolean']['output'];
  viewerCanSign: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
};


export type DriveDocumentThumbnailArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type DriveDocumentArchiveInput = {
  id: Scalars['ID']['input'];
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export type DriveDocumentBulkArchiveInput = {
  documentIds: Array<Scalars['ID']['input']>;
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export type DriveDocumentBulkDownloadInput = {
  documentIds: Array<Scalars['ID']['input']>;
};

export type DriveDocumentBulkSendEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  documentIds: Array<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};

export type DriveDocumentDownload = {
  __typename?: 'DriveDocumentDownload';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DriveDocumentEmailSendInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};

export type DriveDocumentGenerateIdInput = {
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
};

export type DriveDocumentLastUsedUpdateInput = {
  id: Scalars['ID']['input'];
};

export type DriveDocumentMeta = FinancialDocumentMeta | OvertimeMeta;

export type DriveDocumentNoteSaveInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
};

export type DriveDocumentPreview = {
  __typename?: 'DriveDocumentPreview';
  mimeType: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type DriveDocumentRemoveInput = {
  id: Scalars['ID']['input'];
};

export type DriveDocumentRemoveResult = {
  __typename?: 'DriveDocumentRemoveResult';
  status: Scalars['Boolean']['output'];
};

export type DriveDocumentRenameInput = {
  id: Scalars['ID']['input'];
  newName: Scalars['String']['input'];
};

export enum DriveDocumentStatus {
  Archived = 'ARCHIVED',
  ManuallySigned = 'MANUALLY_SIGNED',
  NotAgreed = 'NOT_AGREED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED',
  SignedWithDefects = 'SIGNED_WITH_DEFECTS',
  Unknown = 'UNKNOWN'
}

export type DriveDocumentsExternalLinkEmailSendInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  ids: Array<Scalars['ID']['input']>;
  subject: Scalars['String']['input'];
  to: Array<Scalars['String']['input']>;
};

export type DriveDocumentsMoveInput = {
  ids: Array<Scalars['ID']['input']>;
  targetFolderId: Scalars['ID']['input'];
};

export type DriveDocumentsRemoveInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type DriveDocumentsRemoveResult = {
  __typename?: 'DriveDocumentsRemoveResult';
  status: Scalars['Boolean']['output'];
};

export type DriveEntry = DriveDocument | DriveFolder;

export type DriveEntryNote = {
  __typename?: 'DriveEntryNote';
  content: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  createdBy: User;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  updatedBy?: Maybe<User>;
};

export type DriveEntrySorting = {
  __typename?: 'DriveEntrySorting';
  direction: DriveSortingDirection;
  type: DriveEntrySortingType;
};

export type DriveEntrySortingInput = {
  direction: DriveSortingDirection;
  type: DriveEntrySortingType;
};

export enum DriveEntrySortingType {
  Alphabetical = 'ALPHABETICAL',
  LastChangedDate = 'LAST_CHANGED_DATE',
  LastUsedDate = 'LAST_USED_DATE',
  Status = 'STATUS'
}

export type DriveFolder = Notable & Sortable & {
  __typename?: 'DriveFolder';
  addedBy: User;
  createdAt: Scalars['Date']['output'];
  depth: Scalars['Int']['output'];
  documentCounts: DriveFolderDocumentCounts;
  documentsCount: Scalars['Int']['output'];
  drive: Drive;
  entries: Array<DriveEntry>;
  id: Scalars['ID']['output'];
  lastChangedDate: Scalars['Date']['output'];
  lastUsedDate: Scalars['Date']['output'];
  mimeTypes: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  note?: Maybe<DriveEntryNote>;
  parent?: Maybe<DriveFolder>;
  /** @deprecated This field deprecated. You should use viewerCanRemove field instead of removable field. */
  removable: Scalars['Boolean']['output'];
  /** @deprecated This field deprecated. You should use viewerCanRemove field instead of removable field. */
  renamable: Scalars['Boolean']['output'];
  subtype?: Maybe<DriveFolderSubtype>;
  type: DriveFolderType;
  updatedAt: Scalars['Date']['output'];
  viewerCanCreateCustomFileDocument: Scalars['Boolean']['output'];
  viewerCanCreateFolder: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanRename: Scalars['Boolean']['output'];
};


export type DriveFolderDocumentCountsArgs = {
  excludedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  includedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type DriveFolderDocumentsCountArgs = {
  excludedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  includedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type DriveFolderEntriesArgs = {
  excludedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  includedMimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<DriveSorting>;
  sortingv2?: InputMaybe<DriveEntrySortingInput>;
  viewPreferences?: InputMaybe<DriveViewPreferencesInput>;
};

export type DriveFolderCreateAndMoveDocumentsInput = {
  documentIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  parentId: Scalars['ID']['input'];
};

export type DriveFolderCreateInput = {
  name: Scalars['String']['input'];
  parentId: Scalars['ID']['input'];
};

export type DriveFolderCreatedLog = DriveFolderLog & Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'DriveFolderCreatedLog';
  actorFirstName: Scalars['String']['output'];
  actorId: Scalars['String']['output'];
  actorLastName: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  driveFolderId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
};

export type DriveFolderDeletedLog = DriveFolderLog & Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'DriveFolderDeletedLog';
  actorFirstName: Scalars['String']['output'];
  actorId: Scalars['String']['output'];
  actorLastName: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  driveFolderId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
};

export type DriveFolderDocumentCounts = {
  __typename?: 'DriveFolderDocumentCounts';
  bauarchiv: Scalars['Int']['output'];
  baumappe: Scalars['Int']['output'];
};

export type DriveFolderLastUsedUpdateInput = {
  id: Scalars['ID']['input'];
};

export type DriveFolderLog = {
  driveFolderId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type DriveFolderNoteSaveInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  folderId: Scalars['ID']['input'];
};

export type DriveFolderRemoveInput = {
  fallbackFolderId?: InputMaybe<Scalars['ID']['input']>;
  folderId: Scalars['ID']['input'];
};

export type DriveFolderRemoveResult = {
  __typename?: 'DriveFolderRemoveResult';
  meta: DriveFolderRemoveResultMeta;
  status: Scalars['Boolean']['output'];
};

export type DriveFolderRemoveResultMeta = {
  __typename?: 'DriveFolderRemoveResultMeta';
  movedDocuments: Array<DriveDocument>;
  movedFolder?: Maybe<DriveFolder>;
};

export type DriveFolderSorting = {
  __typename?: 'DriveFolderSorting';
  direction: DriveSortingDirection;
  type: DriveFolderSortingType;
};

export type DriveFolderSortingInput = {
  direction: DriveSortingDirection;
  type: DriveFolderSortingType;
};

export enum DriveFolderSortingType {
  Alphabetical = 'ALPHABETICAL',
  LastUsedDate = 'LAST_USED_DATE',
  Standard = 'STANDARD'
}

export enum DriveFolderSubtype {
  /**  Plaene  */
  Blueprint = 'BLUEPRINT',
  /**  Abnahmen  */
  ConstructionSideAcceptance = 'CONSTRUCTION_SIDE_ACCEPTANCE',
  /**  Baustellenbeschrieb  */
  ConstructionSideDescription = 'CONSTRUCTION_SIDE_DESCRIPTION',
  /**  Baustellendokumentationr  */
  ConstructionSideDocumentation = 'CONSTRUCTION_SIDE_DOCUMENTATION',
  /**  Bauvertraege  */
  Contract = 'CONTRACT',
  /**  Korrespondenz  */
  Correspondence = 'CORRESPONDENCE',
  /**  SUB / Leiher  */
  FinancialBorrowers = 'FINANCIAL_BORROWERS',
  /**  Rechnung / AZ  */
  FinancialInvoices = 'FINANCIAL_INVOICES',
  /**  Lieferanten  */
  FinancialSuppliers = 'FINANCIAL_SUPPLIERS',
  /**  Buergschaften  */
  Guarantee = 'GUARANTEE',
  /**  Aufmaß  */
  Measurement = 'MEASUREMENT',
  /**  Angebote  */
  Offer = 'OFFER',
  /**  Rapporte  */
  Overtime = 'OVERTIME',
  /**  Nachunternehmer  */
  Subcontractor = 'SUBCONTRACTOR',
  /**  Nachtraege   */
  SupplementaryOffer = 'SUPPLEMENTARY_OFFER'
}

export enum DriveFolderType {
  Custom = 'CUSTOM',
  Root = 'ROOT',
  SystemBauarchiv = 'SYSTEM_BAUARCHIV',
  SystemBaumappe = 'SYSTEM_BAUMAPPE'
}

export type DriveFolderUpdateInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type DriveFolderUpdatedLog = DriveFolderLog & Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'DriveFolderUpdatedLog';
  actorFirstName: Scalars['String']['output'];
  actorId: Scalars['String']['output'];
  actorLastName: Scalars['String']['output'];
  date: Scalars['Date']['output'];
  driveFolderId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  oldName: Scalars['String']['output'];
  projectId: Scalars['ID']['output'];
};

export type DriveMimeTypeFolderMapItem = {
  __typename?: 'DriveMimeTypeFolderMapItem';
  folder: DriveFolder;
  mimeType: Scalars['String']['output'];
};

export type DriveSorting = {
  direction: DriveSortingDirection;
  field: DriveSortingField;
};

/** The sorting direction */
export enum DriveSortingDirection {
  /** Lowest is the first */
  Asc = 'ASC',
  /** Greatest is the first */
  Desc = 'DESC'
}

/** The sorting field for Drive Document */
export enum DriveSortingField {
  AddedBy = 'ADDED_BY',
  Custom = 'CUSTOM',
  Name = 'NAME',
  No = 'NO',
  Status = 'STATUS',
  UpdatedAt = 'UPDATED_AT'
}

export type DriveViewPreferences = {
  __typename?: 'DriveViewPreferences';
  bauarchiv: Scalars['Boolean']['output'];
  baumappe: Scalars['Boolean']['output'];
};

export type DriveViewPreferencesInput = {
  bauarchiv: Scalars['Boolean']['input'];
  baumappe: Scalars['Boolean']['input'];
};

export type DuplicateAcceptanceReportInput = {
  /** ID of the acceptance report. */
  acceptanceReportId: Scalars['String']['input'];
};

export type DuplicateConstructionNoteInput = {
  /** ID of the construction note. */
  constructionNote: Scalars['String']['input'];
};

export type DuplicateOvertimeInput = {
  overtime: Scalars['String']['input'];
};

export type DuplicateRiskAssessmentInput = {
  /** ID of the risk assessment */
  riskAssessment: Scalars['String']['input'];
};

/** Uploaded image for editor. */
export type EditorImage = {
  __typename?: 'EditorImage';
  /** ID of the image */
  id: Scalars['ID']['output'];
  /** Path of the image */
  path: Scalars['String']['output'];
};

export type EmailSendDriveBulkJob = DriveBulkJob & {
  __typename?: 'EmailSendDriveBulkJob';
  documents: Array<DriveDocument>;
  id: Scalars['ID']['output'];
  meta: EmailSendDriveBulkJobMeta;
  status: DriveBulkJobStatus;
};

export type EmailSendDriveBulkJobMeta = {
  __typename?: 'EmailSendDriveBulkJobMeta';
  failureReason?: Maybe<EmailSendDriveBulkJobMetaFailureReason>;
};

export enum EmailSendDriveBulkJobMetaFailureReason {
  MaxFileSizeExceed = 'MAX_FILE_SIZE_EXCEED',
  ServerError = 'SERVER_ERROR'
}

/** Response as status for e-mail sending */
export type EmailSentResponse = {
  __typename?: 'EmailSentResponse';
  /** Status of the operation */
  status: Scalars['Boolean']['output'];
};

/** An e-mail suggestion */
export type EmailSuggestion = {
  __typename?: 'EmailSuggestion';
  /** E-mail of the person */
  email: Scalars['String']['output'];
  /** Name of the person */
  name?: Maybe<Scalars['String']['output']>;
};

/** E-mail suggestions for a project */
export type EmailSuggestions = {
  __typename?: 'EmailSuggestions';
  /** E-mails from contact people of the project */
  contactPeople: Array<EmailSuggestion>;
  /** E-mails from HS DB */
  recipients: Array<EmailSuggestion>;
};

export type Employee = {
  __typename?: 'Employee';
  avatar?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  profitCenterId?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  sapId: Scalars['ID']['output'];
  trainee: Scalars['Boolean']['output'];
  wageHourly?: Maybe<Scalars['Float']['output']>;
};

/** Equipment is the type for equipment. */
export type Equipment = {
  __typename?: 'Equipment';
  /** Icon of the equipment */
  icon: Scalars['String']['output'];
  /** ID of the equipment */
  id: Scalars['ID']['output'];
  /** Preselected status of the equipment */
  preselected?: Maybe<Scalars['Boolean']['output']>;
  /** Required status of the equipment */
  required?: Maybe<Scalars['Boolean']['output']>;
  /** Sort of the equipment */
  sort: Scalars['Float']['output'];
  /** Title of the equipment */
  title: Scalars['String']['output'];
};

export enum Event {
  AcceptanceReportArchived = 'ACCEPTANCE_REPORT_ARCHIVED',
  AcceptanceReportCreated = 'ACCEPTANCE_REPORT_CREATED',
  AcceptanceReportDeleted = 'ACCEPTANCE_REPORT_DELETED',
  AcceptanceReportEmailSent = 'ACCEPTANCE_REPORT_EMAIL_SENT',
  AcceptanceReportExternalEmailSent = 'ACCEPTANCE_REPORT_EXTERNAL_EMAIL_SENT',
  AcceptanceReportExternalLinkExpired = 'ACCEPTANCE_REPORT_EXTERNAL_LINK_EXPIRED',
  AcceptanceReportManuallySigned = 'ACCEPTANCE_REPORT_MANUALLY_SIGNED',
  AcceptanceReportNotSigned = 'ACCEPTANCE_REPORT_NOT_SIGNED',
  AcceptanceReportSignedByCustomer = 'ACCEPTANCE_REPORT_SIGNED_BY_CUSTOMER',
  AcceptanceReportUpdated = 'ACCEPTANCE_REPORT_UPDATED',
  ConcernArchived = 'CONCERN_ARCHIVED',
  ConcernCreated = 'CONCERN_CREATED',
  ConcernDeleted = 'CONCERN_DELETED',
  ConcernEmailSent = 'CONCERN_EMAIL_SENT',
  ConcernSigned = 'CONCERN_SIGNED',
  ConcernUpdated = 'CONCERN_UPDATED',
  ConstructionNoteArchived = 'CONSTRUCTION_NOTE_ARCHIVED',
  ConstructionNoteCreated = 'CONSTRUCTION_NOTE_CREATED',
  ConstructionNoteDeleted = 'CONSTRUCTION_NOTE_DELETED',
  ConstructionNoteEmailSent = 'CONSTRUCTION_NOTE_EMAIL_SENT',
  ConstructionNoteUpdated = 'CONSTRUCTION_NOTE_UPDATED',
  CustomFileArchived = 'CUSTOM_FILE_ARCHIVED',
  CustomFileCreated = 'CUSTOM_FILE_CREATED',
  CustomFileDeleted = 'CUSTOM_FILE_DELETED',
  CustomFileDuplicatedForManipulation = 'CUSTOM_FILE_DUPLICATED_FOR_MANIPULATION',
  CustomFileEmailSent = 'CUSTOM_FILE_EMAIL_SENT',
  CustomFileManipulated = 'CUSTOM_FILE_MANIPULATED',
  CustomFileUpdated = 'CUSTOM_FILE_UPDATED',
  DocumentMoved = 'DOCUMENT_MOVED',
  DriveFolderCreated = 'DRIVE_FOLDER_CREATED',
  DriveFolderDeleted = 'DRIVE_FOLDER_DELETED',
  DriveFolderUpdated = 'DRIVE_FOLDER_UPDATED',
  ObstructionArchived = 'OBSTRUCTION_ARCHIVED',
  ObstructionCreated = 'OBSTRUCTION_CREATED',
  ObstructionDeleted = 'OBSTRUCTION_DELETED',
  ObstructionEmailSent = 'OBSTRUCTION_EMAIL_SENT',
  ObstructionSigned = 'OBSTRUCTION_SIGNED',
  ObstructionUpdated = 'OBSTRUCTION_UPDATED',
  OfferCreated = 'OFFER_CREATED',
  OfferDeleted = 'OFFER_DELETED',
  OfferEmailSent = 'OFFER_EMAIL_SENT',
  OvertimeArchived = 'OVERTIME_ARCHIVED',
  OvertimeCreated = 'OVERTIME_CREATED',
  OvertimeDeleted = 'OVERTIME_DELETED',
  OvertimeEmailSent = 'OVERTIME_EMAIL_SENT',
  OvertimeExternalEmailSent = 'OVERTIME_EXTERNAL_EMAIL_SENT',
  OvertimeExternalLinkExpired = 'OVERTIME_EXTERNAL_LINK_EXPIRED',
  OvertimeManuallySigned = 'OVERTIME_MANUALLY_SIGNED',
  OvertimeNotSigned = 'OVERTIME_NOT_SIGNED',
  OvertimeRenamed = 'OVERTIME_RENAMED',
  OvertimeSignedByCustomer = 'OVERTIME_SIGNED_BY_CUSTOMER',
  OvertimeUpdated = 'OVERTIME_UPDATED',
  ProjectClientInformationChanged = 'PROJECT_CLIENT_INFORMATION_CHANGED',
  ProjectConstructionInformationChanged = 'PROJECT_CONSTRUCTION_INFORMATION_CHANGED',
  ProjectExternalStatusChanged = 'PROJECT_EXTERNAL_STATUS_CHANGED',
  ProjectImported = 'PROJECT_IMPORTED',
  ProjectNumberChanged = 'PROJECT_NUMBER_CHANGED',
  ProjectProfitCenterChanged = 'PROJECT_PROFIT_CENTER_CHANGED',
  ProjectStatusUpdated = 'PROJECT_STATUS_UPDATED',
  ProjectSynced = 'PROJECT_SYNCED',
  QsstArchived = 'QSST_ARCHIVED',
  QsstAssignedToProject = 'QSST_ASSIGNED_TO_PROJECT',
  QsstCreated = 'QSST_CREATED',
  QsstDeleted = 'QSST_DELETED',
  QsstDownloaded = 'QSST_DOWNLOADED',
  QsstEmailSent = 'QSST_EMAIL_SENT',
  QsstEmailSentWithAttachment = 'QSST_EMAIL_SENT_WITH_ATTACHMENT',
  QsstExternalLinkExpired = 'QSST_EXTERNAL_LINK_EXPIRED',
  QsstNotSigned = 'QSST_NOT_SIGNED',
  QsstSigned = 'QSST_SIGNED',
  QsstSignedOnSide = 'QSST_SIGNED_ON_SIDE',
  QsstUpdated = 'QSST_UPDATED',
  QsstUploaded = 'QSST_UPLOADED',
  QsstUploadedDeleted = 'QSST_UPLOADED_DELETED',
  RiskAssessmentArchived = 'RISK_ASSESSMENT_ARCHIVED',
  RiskAssessmentCreated = 'RISK_ASSESSMENT_CREATED',
  RiskAssessmentDeleted = 'RISK_ASSESSMENT_DELETED',
  RiskAssessmentEmailSent = 'RISK_ASSESSMENT_EMAIL_SENT',
  RiskAssessmentSignedByCreator = 'RISK_ASSESSMENT_SIGNED_BY_CREATOR',
  RiskAssessmentSignedByEmployee = 'RISK_ASSESSMENT_SIGNED_BY_EMPLOYEE',
  RiskAssessmentUpdated = 'RISK_ASSESSMENT_UPDATED'
}

export type ExportOfferInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the offer. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type ExternalAuthToken = {
  __typename?: 'ExternalAuthToken';
  token: Scalars['String']['output'];
};

export type ExternalOffer = {
  __typename?: 'ExternalOffer';
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  no: Scalars['String']['output'];
  source: ExternalOfferSource;
  status: Scalars['String']['output'];
};

export enum ExternalOfferSource {
  Itworun = 'ITWORUN',
  Vendoc = 'VENDOC'
}

/** Surcharge/Discount for a offer. */
export type ExtraPrice = {
  __typename?: 'ExtraPrice';
  /** name of the extra price */
  name: Scalars['ID']['output'];
  /** Percentage of the  extra price amount */
  percentage: Scalars['Float']['output'];
  /** Type of the extra price */
  type: ExtraPriceType;
  /** Amount of the extra price */
  value: Scalars['Float']['output'];
};

export type ExtraPriceInput = {
  /** Name of the extra price. */
  name: Scalars['String']['input'];
  /** Type of the extra price. */
  type: ExtraPriceType;
  /** Extra price value based on it's type. */
  value: Scalars['Float']['input'];
};

/** Extra price type enum */
export enum ExtraPriceType {
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE'
}

/** Meta data of the financial document */
export type FinancialDocumentMeta = {
  __typename?: 'FinancialDocumentMeta';
  /** Bill Number / Also same as Invoice number */
  billNumber?: Maybe<Scalars['String']['output']>;
  /** Name of the Creditor / Also same as Vendor name */
  creditorName?: Maybe<Scalars['String']['output']>;
  /** Creditor Number / Also same as Vendor Number */
  creditorNumber?: Maybe<Scalars['String']['output']>;
  /** Name of the Debitor */
  debitorName?: Maybe<Scalars['String']['output']>;
  /** Debitor number */
  debitorNumber?: Maybe<Scalars['String']['output']>;
  /** Net Price / Also same as Invoice Sum */
  netPrice?: Maybe<Scalars['Float']['output']>;
};

/** Folder for a offer. */
export type Folder = {
  __typename?: 'Folder';
  /** Is this calculation of folder is approximate or not */
  approximate: Scalars['Boolean']['output'];
  /** Category of this folder */
  category?: Maybe<Category>;
  /** ID of the folder */
  id: Scalars['ID']['output'];
  /** Measurements of this folder */
  measurements: Array<Measurement>;
  /** Name of the folder */
  name: Scalars['String']['output'];
  /** Note for the folder */
  note?: Maybe<Scalars['String']['output']>;
  /** Offer that this folder belongs to */
  offer: Offer;
  /** Operations of this folder */
  operations: Array<FolderOperation>;
  /** Parent of this folder */
  parent?: Maybe<Folder>;
  /** Part no of the folder */
  partNo: Scalars['String']['output'];
  /** Part no alias of the folder */
  partNoAlias?: Maybe<Scalars['String']['output']>;
  /** Computed part no of the folder */
  partNoComputed: Scalars['String']['output'];
  /** Sort no of the folder */
  sort: Scalars['Int']['output'];
  /** Total measurement of the folder */
  totalMeasurement: Scalars['Float']['output'];
  /** Total optional price of the folder */
  totalOptionalPrice: Scalars['Float']['output'];
  /** Total price of the folder */
  totalPrice: Scalars['Float']['output'];
  /** Unit type of the folder */
  unit?: Maybe<OperationUnit>;
  /** Whether viewer can add operation the current folder or not. */
  viewerCanAddOperation: Scalars['Boolean']['output'];
  /** Whether viewer can add subfolder the current folder or not. */
  viewerCanAddSubfolder: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can change the category of the folder */
  viewerCanChangeCategory: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can clone the folder */
  viewerCanClone: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can give part no alias to folder */
  viewerCanGivePartNoAlias: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the folder */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the folder */
  viewerCanUpdate: Scalars['Boolean']['output'];
};

export type FolderData = {
  __typename?: 'FolderData';
  /** Id of the folder */
  id: Scalars['String']['output'];
  /** Name of the folder */
  name: Scalars['String']['output'];
};

/** Folder for a offer. */
export type FolderOperation = {
  __typename?: 'FolderOperation';
  /** Is this calculation of operation is approximate or not */
  approximate: Scalars['Boolean']['output'];
  /** Is this operation completed for exporting */
  completed: Scalars['Boolean']['output'];
  /** Description for the operation */
  description?: Maybe<Scalars['String']['output']>;
  /** Folder of this operation */
  folder: Folder;
  /** ID of the operation */
  id: Scalars['ID']['output'];
  /** Measurements of this operation */
  measurements: Array<Measurement>;
  /** Name of the operation */
  name: Scalars['String']['output'];
  /** Note for the operation */
  note?: Maybe<Scalars['String']['output']>;
  /** Is this operation optional */
  optional: Scalars['Boolean']['output'];
  /** Part no of the operation */
  partNo: Scalars['String']['output'];
  /** Part no alias of the operation */
  partNoAlias?: Maybe<Scalars['String']['output']>;
  /** Computed part no of the operation */
  partNoComputed: Scalars['String']['output'];
  /** Payment of this operation */
  payment?: Maybe<OperationPayment>;
  /** Price no of the operation */
  price: Scalars['Float']['output'];
  /** Sort no of the operation */
  sort: Scalars['Int']['output'];
  /** Total measurement of the folder */
  totalMeasurement: Scalars['Float']['output'];
  /** Unit type of the operation */
  unit?: Maybe<OperationUnit>;
  /** Indicates whether the viewer can clone the folder operation */
  viewerCanClone: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can give part no alias to folder */
  viewerCanGivePartNoAlias: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the folder operation */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the folder operation */
  viewerCanUpdate: Scalars['Boolean']['output'];
};

export type FollowProjectToggleInput = {
  projectId: Scalars['ID']['input'];
};

export type GenerateOfferPdfInput = {
  /** Id of the offer. */
  offer: Scalars['ID']['input'];
};

export type GenerateRiskAssessmentPdfInput = {
  /** Id of the risk assessment. */
  riskAssessment: Scalars['ID']['input'];
};

export type GetPspdfkitAuthTokenInput = {
  documentId: Scalars['ID']['input'];
  requestEdit?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetPspdfkitAuthTokenResponse = {
  __typename?: 'GetPspdfkitAuthTokenResponse';
  pspdfkitAuthToken: Scalars['String']['output'];
};

/** Project that comes from Heinrich-Schmid API */
export type HsProject = {
  __typename?: 'HsProject';
  /** HS ID of the project */
  id: Scalars['ID']['output'];
  /** Is the project already imported */
  isImported: Scalars['Boolean']['output'];
  /** Name of the project */
  name: Scalars['String']['output'];
  /** HS project number */
  projectNumber?: Maybe<Scalars['ID']['output']>;
  /** Source system which project imported from */
  sourceSystem: ProjectImportSourceSystem;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type ImportProjectInput = {
  /** HS id of the project */
  hsId: Scalars['String']['input'];
};

/** InCharge is the type for in charge of risk assessment */
export type InCharge = {
  __typename?: 'InCharge';
  /** Full name of the in charge */
  fullName: Scalars['String']['output'];
  /** ID of the in charge */
  id: Scalars['ID']['output'];
  /** Phone of the in charge */
  phone?: Maybe<Scalars['String']['output']>;
  /** Role of the in charge */
  role: InChargeRole;
};

/** In charge role enum */
export enum InChargeRole {
  FirstAider = 'FIRST_AIDER',
  OperationManager = 'OPERATION_MANAGER',
  RegionSecurityManager = 'REGION_SECURITY_MANAGER',
  SecurityManager = 'SECURITY_MANAGER',
  SideManager = 'SIDE_MANAGER'
}

/** Language. */
export type Language = {
  __typename?: 'Language';
  /** Language code */
  code: LanguageCode;
  /** Name of the language translated in the current preferred language */
  name: Scalars['String']['output'];
};

/** Language code enum in ISO 639-1 */
export enum LanguageCode {
  De = 'DE',
  En = 'EN',
  Fr = 'FR'
}

export type Log = {
  /** Id of the log */
  id: Scalars['ID']['output'];
};

export type LogResetUnreadCountInput = {
  projectId: Scalars['String']['input'];
};

export type LogWithActor = {
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
};

export type LogWithDate = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
};

export type LogWithEmail = {
  /** E-mail address that this event taken for */
  emails: Array<Scalars['String']['output']>;
};

export type Logs = {
  __typename?: 'Logs';
  logs: Array<Log>;
  pagination: Pagination;
};

/** Measurement for a folder. */
export type Measurement = {
  __typename?: 'Measurement';
  /** ID of the measurement */
  id: Scalars['ID']['output'];
  /** Meta for the measurement */
  meta: Array<Scalars['String']['output']>;
  /** Multiplier for the measurement */
  multiplier: Scalars['Float']['output'];
  /** Name of the measurement */
  name: Scalars['String']['output'];
  /** Is subtraction enabled */
  subtraction: Scalars['Boolean']['output'];
  /** Total measurement */
  total: Scalars['Float']['output'];
  /** Total measurement meta */
  totalMeta: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** It saves given acceptance report. */
  acceptanceReportSave: AcceptanceReport;
  /** It adds bulk operations to folder. */
  addBulkOperationToFolder: Array<FolderOperation>;
  /** It add operation to folder. If category is provided, then operation also saved to the given category. */
  addOperationToFolder: FolderOperation;
  /** It assigns a document to given project */
  assignDocumentToProject: DocumentInterface;
  /**
   * It assigns an offer to given project
   * @deprecated Please use assignDocumentToProject mutation instead of this mutation. It will be removed near feature.
   */
  assignOfferToProject: Offer;
  /** It clones the given folder. */
  cloneFolder: Folder;
  /** It clones the given operation. */
  cloneOperation: FolderOperation;
  /** it duplicate the given concern */
  concernDuplicate: Concern;
  /** Send Email to pdf concern */
  concernPdfEmailSend: Concern;
  /** it saves given concern */
  concernSave: Concern;
  /** it signes given concern */
  concernSign: Concern;
  /** It updates given construction note's canvas. */
  constructionNoteCanvasUpdate: ConstructionNote;
  /** It saves given construction note. */
  constructionNoteCreate: ConstructionNote;
  /** It updates given construction note. */
  constructionNoteUpdate: ConstructionNote;
  /** It adds a new contact person for given project */
  contactPersonAdd: ContactPerson;
  /** It removes the given contact person */
  contactPersonRemove: RemoveResult;
  /** It updates the given contact person */
  contactPersonUpdate: ContactPerson;
  /** It converts custom files to images */
  convertCustomFilesToImages: Array<Image>;
  /** It creates a folder in a framework offer. */
  createFolderForFramework: Folder;
  /** It creates a folder in a free form offer. */
  createFolderForFreeForm: Folder;
  /** It creates a new operation in the catalogue. */
  createOperation: Operation;
  /** It sends an e-mail for custom file */
  customFileEmailSend: EmailSentResponse;
  /** It uploads a new custom file */
  customFileUpload: CustomFile;
  driveBulkJobCancel: DriveBulkJob;
  driveBulkJobRemove: DriveBulkJobRemoveResult;
  driveDocumentArchive: DriveDocument;
  driveDocumentBulkArchive: DriveBulkJob;
  driveDocumentBulkDownload: DriveBulkJob;
  driveDocumentBulkSendEmail: DriveBulkJob;
  driveDocumentEmailSend: DriveDocument;
  driveDocumentGenerateId: Scalars['String']['output'];
  driveDocumentGenerateIdMulti: Array<Scalars['String']['output']>;
  driveDocumentNoteSave: DriveDocument;
  driveDocumentRemove: DriveDocumentRemoveResult;
  driveDocumentRename: DriveDocument;
  driveDocumentsExternalLinkEmailSend: Array<DriveDocument>;
  driveDocumentsLastUsedUpdate: Array<DriveDocument>;
  driveDocumentsMove: Array<DriveDocument>;
  driveDocumentsRemove: DriveDocumentsRemoveResult;
  /** It creates a new drive folder. */
  driveFolderCreate: DriveFolder;
  driveFolderCreateAndMoveDocuments: DriveFolder;
  /** It creates new drive folders. */
  driveFolderCreateMulti: Array<DriveFolder>;
  driveFolderLastUsedUpdate: DriveFolder;
  driveFolderNoteSave: DriveFolder;
  driveFolderRemove: DriveFolderRemoveResult;
  driveFolderUpdate: DriveFolder;
  drivePreferenceUpsert: ViewerProjectDrivePreferences;
  /** It duplicates the given acceptance report */
  duplicateAcceptanceReport: AcceptanceReport;
  /** It duplicates the given construction note */
  duplicateConstructionNote: ConstructionNote;
  duplicateOvertime: Overtime;
  /** It duplicates the given risk assessment */
  duplicateRiskAssessment: RiskAssessment;
  /** It exports the offer as GAEB x83 and x84 format via e-mail. */
  exportOffer: EmailSentResponse;
  followProjectToggle: Project;
  /** It generates PDF for the given offer. */
  generateOfferPdf: Offer;
  /** It generates PDF for the given risk assessment. */
  generateRiskAssessmentPdf: RiskAssessment;
  /** Imports the Heinrich-Schmid project to Baumappe */
  importProject: Project;
  logResetUnreadCount: Scalars['Boolean']['output'];
  obstructionDuplicate: Obstruction;
  /** It sends obstruction email. */
  obstructionEmailSend: Obstruction;
  /** It saves given obstruction. */
  obstructionSave: Obstruction;
  obstructionSign: Obstruction;
  /** It saves given framework offer. */
  offerFrameworkSave: Offer;
  /** It saves given free form offer. */
  offerFreeFormSave: Offer;
  overtimeSave: Overtime;
  /** It updates the given project date */
  projectDateUpdate: Project;
  qsstDeleteUploadedPdf: Qsst;
  qsstLogDownload: Scalars['Boolean']['output'];
  qsstSave: Qsst;
  qsstSendMail: Qsst;
  qsstSign: Qsst;
  qsstUploadPdf: Qsst;
  /** It removes a catalogue. */
  removeCatalogue: RemoveResult;
  /** It removes a folder. */
  removeFolder: RemoveResult;
  /** It removes a folder operation. */
  removeFolderOperation: RemoveResult;
  /** It removes manually uploaded pdf from an acceptance report. */
  removeManuallyUploadedAcceptanceReport: AcceptanceReport;
  removeManuallyUploadedOvertime: Overtime;
  /** It generates PDF for the given offer. */
  retrieveTokens: RetrieveTokensResult;
  /** It saves given risk assessment. */
  riskAssessmentSave: RiskAssessment;
  saveAnnotations: DriveDocument;
  /** It sends e-mail for the given acceptance report. */
  sendAcceptanceReportEmail: EmailSentResponse;
  /** It sends external link e-mail for the given acceptance report. */
  sendAcceptanceReportExternalLinkEmail: EmailSentResponse;
  /** It sends external link e-mail for the given construction note. */
  sendConstructionNoteEmail: EmailSentResponse;
  /** It send e-mail for the given offer. */
  sendOfferEmail: EmailSentResponse;
  sendOvertimeEmail: EmailSentResponse;
  sendOvertimeExternalLinkEmail: EmailSentResponse;
  /** It send e-mail for the given risk assessment. */
  sendRiskAssessmentEmail: EmailSentResponse;
  sivAdditionalOperationCreate: SivOperation;
  sivBillingAttachmentEmailSend: SivBillingAttachment;
  sivBillingAttachmentSave: SivBillingAttachment;
  sivCockpitDisplayPreferencesUpdate: Siv;
  sivDiscountToggle: Siv;
  sivDiscountUpdate: Siv;
  sivEmployeeProductivityToggle: Siv;
  sivGroupCreate: SivGroup;
  sivGroupDuplicate: Array<SivGroup>;
  sivGroupMove: Array<SivGroup>;
  sivGroupRemove: Siv;
  sivGroupSetIsCollapsed: SivGroup;
  sivGroupSortIndexUpdate: Siv;
  /** Transfer SIV Group recording values to planning values */
  sivGroupTransferRecordingValues: SivGroup;
  sivGroupUpdate: SivGroup;
  sivImportFromExternalOffer: Siv;
  sivImportFromOffer: Siv;
  sivIsCollapsedToggle: Siv;
  sivKeyDataSapTransmit: SivKeyDataSapTransmitResult;
  sivLvCopy: Siv;
  sivOperationConvertToRegular: SivOperation;
  sivOperationCreate: SivOperation;
  sivOperationDetach: SivOperation;
  sivOperationDuplicate: Array<SivOperation>;
  sivOperationMove: Array<SivOperation>;
  sivOperationPerformanceRecordingBatchUpdate: Array<SivOperation>;
  sivOperationPinToggle: SivOperation;
  sivOperationPlanningChargePerMinuteUpdate: SivOperation;
  sivOperationPlanningHourUpdate: SivOperation;
  sivOperationPlanningMaterialUpdate: SivOperation;
  sivOperationPlanningPerformanceValueUpdate: SivOperation;
  sivOperationRecordingHourUpdate: SivOperation;
  sivOperationRecordingMaterialUpdate: SivOperation;
  sivOperationRecordingTotalMeasurementUpdate: SivOperation;
  sivOperationRemove: Siv;
  sivOperationSortIndexUpdate: SivGroup;
  sivOperationSplitPreferenceUpdate: Siv;
  sivOperationUnitPriceUpdate: SivOperation;
  sivOperationUpdate: SivOperation;
  sivPlanningCostSectionFinish: Siv;
  sivPlanningEmployeesSet: Siv;
  sivPlanningFlatRateUpdate: Siv;
  sivPlanningFreelanceEmployeesSet: Siv;
  sivPlanningGroupFlatRateUpdate: Siv;
  sivPlanningSubcontractorCreate: SivSubcontractor;
  sivPlanningSubcontractorRemove: Siv;
  sivPlanningSubcontractorUpdate: SivSubcontractor;
  sivPlanningUpdate: Siv;
  sivRecordingFlatRateUpdate: Siv;
  sivRecordingGroupFlatRateUpdate: Siv;
  sivRecordingSubcontractorInvoicesSet: SivSubcontractor;
  sivRecordingUpdate: Siv;
  /** Syncs the Heinrich-Schmid project */
  syncProject: Project;
  timeTrackingAdditionalRowAdd: TimeTrackingRow;
  timeTrackingBatchOperation: Array<TimeTrackingBatchOperationResponse>;
  timeTrackingEntryIndicatorMultiSave: Array<TimeTrackingEntry>;
  timeTrackingEntryIndicatorSave: TimeTrackingEntry;
  timeTrackingEntryMultiRemove: Array<TimeTrackingEntry>;
  timeTrackingEntryNoteMultiSave: Array<TimeTrackingEntry>;
  timeTrackingEntryNoteSave: TimeTrackingEntry;
  timeTrackingEntryTimeMultiRemove: Array<TimeTrackingEntry>;
  timeTrackingEntryTimeMultiSave: TimeTrackingEntryTimeSaveResult;
  timeTrackingEntryTimeRemove: TimeTrackingEntry;
  timeTrackingEntryTimeSave: TimeTrackingEntryTimeSaveResult;
  timeTrackingProjectMultiApprove: Array<TimeTrackingApprove>;
  timeTrackingRemoveWeekYearProject: Scalars['Boolean']['output'];
  timeTrackingResponsibleToggle: TimeTrackingWeekRecord;
  timeTrackingRowRemove: Scalars['Boolean']['output'];
  timeTrackingSAPEmployeeWeekRecordAdd: TimeTrackingWeekRecord;
  timeTrackingSAPTransmit: TimeTrackingSapTransmitPayload;
  timeTrackingSwitchMultiSAPEmployee: TimeTrackingWeekYearSapEmployee;
  timeTrackingSwitchProject: TimeTrackingWeekYearProject;
  timeTrackingSwitchSAPEmployee: TimeTrackingWeekRecord;
  timeTrackingUnknownEmployeeUpdate: TimeTrackingWeekRecord;
  timeTrackingUnknownEmployeeWeekRecordAdd: TimeTrackingWeekRecord;
  timeTrackingWeekRecordSwitchProject: TimeTrackingWeekRecord;
  /** It toggles viewer's manual e-mail subscription preference. */
  toggleManualEmailSubscription: Scalars['Boolean']['output'];
  /** Toggles show sub project hours setting on a main project */
  toggleShowSubProjectHours: Project;
  /** It updates catalogue. */
  updateCatalogue: Catalogue;
  /** It updates a framework folder. */
  updateFolderForFramework: Folder;
  /** It updates a free form folder. */
  updateFolderForFreeForm: Folder;
  /** It updates a folder operation. */
  updateFolderOperation: FolderOperation;
  /** It updates an offer's calculation. */
  updateOfferCalculation: Offer;
  /** It updates sort for framework folders and framework operations. */
  updateOfferSortingForFramework: UpdateSortingResult;
  /** It updates sort for free form folders and operations. */
  updateOfferSortingForFreeForm: UpdateSortingResult;
  /** It updates a operation. */
  updateOperation: Operation;
  /** It updates the status of a project. */
  updateProjectStatus: Project;
  /**
   * It updates tax rate for the given offer.
   * @deprecated Please use updateOfferCalculation mutation instead of this mutation. It will be removed near feature.
   */
  updateTaxRate: Offer;
  /** It uploads signatures for an acceptance report */
  uploadAcceptanceReportSignature: AcceptanceReportSignature;
  /** It uploads to user catalogue. */
  uploadCatalogue: Catalogue;
  /** Uploads an image for editor */
  uploadEditorImage: EditorImage;
  /** Uploads an image */
  uploadImage: Image;
  /** It uploads manual pdf to acceptance report. */
  uploadManualPDFToAcceptanceReport: AcceptanceReport;
  uploadManualPDFToOvertime: Overtime;
  uploadOvertimeSignature: OvertimeSignature;
  /** upload signatures for an risk assessment */
  uploadRiskAssessmentSignature: RiskAssessmentSignature;
  /** Set preferred language */
  viewerLanguageUpdate: ViewerLanguageUpdateResult;
  watchEvents: Scalars['Boolean']['output'];
};


export type MutationAcceptanceReportSaveArgs = {
  input: AcceptanceReportSaveInput;
};


export type MutationAddBulkOperationToFolderArgs = {
  input: AddBulkOperationToFolderInput;
};


export type MutationAddOperationToFolderArgs = {
  input: AddOperationToFolderInput;
};


export type MutationAssignDocumentToProjectArgs = {
  input: AssignDocumentToProjectInput;
};


export type MutationAssignOfferToProjectArgs = {
  input: AssignOfferToProjectInput;
};


export type MutationCloneFolderArgs = {
  input: CloneFolderInput;
};


export type MutationCloneOperationArgs = {
  input: CloneOperationInput;
};


export type MutationConcernDuplicateArgs = {
  input: ConcernDuplicateInput;
};


export type MutationConcernPdfEmailSendArgs = {
  input: ConcernPdfEmailSendInput;
};


export type MutationConcernSaveArgs = {
  input: ConcernSaveInput;
};


export type MutationConcernSignArgs = {
  input: ConcernSignInput;
};


export type MutationConstructionNoteCanvasUpdateArgs = {
  file: Scalars['Upload']['input'];
  imagePresentation: Scalars['Upload']['input'];
  input: ConstructionNoteCanvasUpdateInput;
};


export type MutationConstructionNoteCreateArgs = {
  file: Scalars['Upload']['input'];
  imagePresentation: Scalars['Upload']['input'];
  input: ConstructionNoteCreateInput;
};


export type MutationConstructionNoteUpdateArgs = {
  input: ConstructionNoteUpdateInput;
};


export type MutationContactPersonAddArgs = {
  input: ContactPersonAddInput;
};


export type MutationContactPersonRemoveArgs = {
  input: ContactPersonRemoveInput;
};


export type MutationContactPersonUpdateArgs = {
  input: ContactPersonUpdateInput;
};


export type MutationConvertCustomFilesToImagesArgs = {
  input: Array<Scalars['ID']['input']>;
};


export type MutationCreateFolderForFrameworkArgs = {
  input: CreateFolderForFrameworkInput;
};


export type MutationCreateFolderForFreeFormArgs = {
  input: CreateFolderForFreeFormInput;
};


export type MutationCreateOperationArgs = {
  input: CreateOperationInput;
};


export type MutationCustomFileEmailSendArgs = {
  input: CustomFileEmailSendInput;
};


export type MutationCustomFileUploadArgs = {
  input: CustomFileUploadInput;
};


export type MutationDriveBulkJobCancelArgs = {
  input: DriveBulkJobCancelInput;
};


export type MutationDriveBulkJobRemoveArgs = {
  input: DriveBulkJobRemoveInput;
};


export type MutationDriveDocumentArchiveArgs = {
  input: DriveDocumentArchiveInput;
};


export type MutationDriveDocumentBulkArchiveArgs = {
  input: DriveDocumentBulkArchiveInput;
};


export type MutationDriveDocumentBulkDownloadArgs = {
  input: DriveDocumentBulkDownloadInput;
};


export type MutationDriveDocumentBulkSendEmailArgs = {
  input: DriveDocumentBulkSendEmailInput;
};


export type MutationDriveDocumentEmailSendArgs = {
  input: DriveDocumentEmailSendInput;
};


export type MutationDriveDocumentGenerateIdArgs = {
  input: DriveDocumentGenerateIdInput;
};


export type MutationDriveDocumentGenerateIdMultiArgs = {
  input: Array<DriveDocumentGenerateIdInput>;
};


export type MutationDriveDocumentNoteSaveArgs = {
  input: DriveDocumentNoteSaveInput;
};


export type MutationDriveDocumentRemoveArgs = {
  input: DriveDocumentRemoveInput;
};


export type MutationDriveDocumentRenameArgs = {
  input: DriveDocumentRenameInput;
};


export type MutationDriveDocumentsExternalLinkEmailSendArgs = {
  input: DriveDocumentsExternalLinkEmailSendInput;
};


export type MutationDriveDocumentsLastUsedUpdateArgs = {
  input: Array<DriveDocumentLastUsedUpdateInput>;
};


export type MutationDriveDocumentsMoveArgs = {
  input: DriveDocumentsMoveInput;
};


export type MutationDriveDocumentsRemoveArgs = {
  input: DriveDocumentsRemoveInput;
};


export type MutationDriveFolderCreateArgs = {
  input: DriveFolderCreateInput;
};


export type MutationDriveFolderCreateAndMoveDocumentsArgs = {
  input: DriveFolderCreateAndMoveDocumentsInput;
};


export type MutationDriveFolderCreateMultiArgs = {
  input: Array<DriveFolderCreateInput>;
};


export type MutationDriveFolderLastUsedUpdateArgs = {
  input: DriveFolderLastUsedUpdateInput;
};


export type MutationDriveFolderNoteSaveArgs = {
  input: DriveFolderNoteSaveInput;
};


export type MutationDriveFolderRemoveArgs = {
  input: DriveFolderRemoveInput;
};


export type MutationDriveFolderUpdateArgs = {
  input: DriveFolderUpdateInput;
};


export type MutationDrivePreferenceUpsertArgs = {
  input: ViewerProjectDrivePreferenceInput;
};


export type MutationDuplicateAcceptanceReportArgs = {
  input: DuplicateAcceptanceReportInput;
};


export type MutationDuplicateConstructionNoteArgs = {
  input: DuplicateConstructionNoteInput;
};


export type MutationDuplicateOvertimeArgs = {
  input: DuplicateOvertimeInput;
};


export type MutationDuplicateRiskAssessmentArgs = {
  input: DuplicateRiskAssessmentInput;
};


export type MutationExportOfferArgs = {
  input: ExportOfferInput;
};


export type MutationFollowProjectToggleArgs = {
  input: FollowProjectToggleInput;
};


export type MutationGenerateOfferPdfArgs = {
  input: GenerateOfferPdfInput;
};


export type MutationGenerateRiskAssessmentPdfArgs = {
  input: GenerateRiskAssessmentPdfInput;
};


export type MutationImportProjectArgs = {
  input: ImportProjectInput;
};


export type MutationLogResetUnreadCountArgs = {
  input: LogResetUnreadCountInput;
};


export type MutationObstructionDuplicateArgs = {
  input: ObstructionDuplicateInput;
};


export type MutationObstructionEmailSendArgs = {
  input: ObstructionEmailSendInput;
};


export type MutationObstructionSaveArgs = {
  input: ObstructionSaveInput;
};


export type MutationObstructionSignArgs = {
  input: ObstructionSignInput;
};


export type MutationOfferFrameworkSaveArgs = {
  input: OfferFrameworkSaveInput;
};


export type MutationOfferFreeFormSaveArgs = {
  input: OfferFreeFormSaveInput;
};


export type MutationOvertimeSaveArgs = {
  input: OvertimeSaveInput;
};


export type MutationProjectDateUpdateArgs = {
  input: ProjectDateUpdateInput;
};


export type MutationQsstDeleteUploadedPdfArgs = {
  id: Scalars['ID']['input'];
};


export type MutationQsstLogDownloadArgs = {
  input: QsstLogDownloadInput;
};


export type MutationQsstSaveArgs = {
  input: QsstSaveInput;
};


export type MutationQsstSendMailArgs = {
  input: QsstSendMailInput;
};


export type MutationQsstSignArgs = {
  input: QsstSignInput;
};


export type MutationQsstUploadPdfArgs = {
  input: QsstUploadPdfInput;
};


export type MutationRemoveCatalogueArgs = {
  input: RemoveCatalogueInput;
};


export type MutationRemoveFolderArgs = {
  input: RemoveFolderInput;
};


export type MutationRemoveFolderOperationArgs = {
  input: RemoveFolderOperationInput;
};


export type MutationRemoveManuallyUploadedAcceptanceReportArgs = {
  input: RemoveManuallyUploadedAcceptanceReportInput;
};


export type MutationRemoveManuallyUploadedOvertimeArgs = {
  input: RemoveManuallyUploadedOvertimeInput;
};


export type MutationRetrieveTokensArgs = {
  input: RetrieveTokensInput;
};


export type MutationRiskAssessmentSaveArgs = {
  input: RiskAssessmentSaveInput;
};


export type MutationSaveAnnotationsArgs = {
  input: SaveAnnotationsInput;
};


export type MutationSendAcceptanceReportEmailArgs = {
  input: SendAcceptanceReportEmailInput;
};


export type MutationSendAcceptanceReportExternalLinkEmailArgs = {
  input: SendAcceptanceReportExternalLinkEmailInput;
};


export type MutationSendConstructionNoteEmailArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  input: SendConstructionNoteEmailInput;
};


export type MutationSendOfferEmailArgs = {
  input: SendOfferEmailInput;
};


export type MutationSendOvertimeEmailArgs = {
  input: SendOvertimeEmailInput;
};


export type MutationSendOvertimeExternalLinkEmailArgs = {
  input: SendOvertimeExternalLinkEmailInput;
};


export type MutationSendRiskAssessmentEmailArgs = {
  input: SendRiskAssessmentEmailInput;
};


export type MutationSivAdditionalOperationCreateArgs = {
  input: SivAdditionalOperationCreateInput;
};


export type MutationSivBillingAttachmentEmailSendArgs = {
  input: SivBillingAttachmentEmailSendInput;
};


export type MutationSivBillingAttachmentSaveArgs = {
  input: SivBillingAttachmentSaveInput;
};


export type MutationSivCockpitDisplayPreferencesUpdateArgs = {
  input: SivCockpitDisplayPreferencesUpdateInput;
};


export type MutationSivDiscountToggleArgs = {
  input: SivDiscountToggleInput;
};


export type MutationSivDiscountUpdateArgs = {
  input: SivDiscountUpdateInput;
};


export type MutationSivEmployeeProductivityToggleArgs = {
  input: SivEmployeeProductivityToggleInput;
};


export type MutationSivGroupCreateArgs = {
  input: SivGroupCreateInput;
};


export type MutationSivGroupDuplicateArgs = {
  input: SivGroupDuplicateInput;
};


export type MutationSivGroupMoveArgs = {
  input: SivGroupMoveInput;
};


export type MutationSivGroupRemoveArgs = {
  input: SivGroupRemoveInput;
};


export type MutationSivGroupSetIsCollapsedArgs = {
  input: SivGroupSetIsCollapsedInput;
};


export type MutationSivGroupSortIndexUpdateArgs = {
  input: SivGroupSortIndexUpdateInput;
};


export type MutationSivGroupTransferRecordingValuesArgs = {
  input: SivGroupTransferRecordingValuesInput;
};


export type MutationSivGroupUpdateArgs = {
  input: SivGroupUpdateInput;
};


export type MutationSivImportFromExternalOfferArgs = {
  input: SivImportFromExternalOfferInput;
};


export type MutationSivImportFromOfferArgs = {
  input: SivImportFromOfferInput;
};


export type MutationSivIsCollapsedToggleArgs = {
  input: SivCollapseToggleInput;
};


export type MutationSivKeyDataSapTransmitArgs = {
  input: SivKeyDataSapTransmitInput;
};


export type MutationSivLvCopyArgs = {
  input: SivlvCopyInput;
};


export type MutationSivOperationConvertToRegularArgs = {
  input: SivOperationConvertToRegularInput;
};


export type MutationSivOperationCreateArgs = {
  input: SivOperationCreateInput;
};


export type MutationSivOperationDetachArgs = {
  input: SivOperationDetachInput;
};


export type MutationSivOperationDuplicateArgs = {
  input: SivOperationDuplicateInput;
};


export type MutationSivOperationMoveArgs = {
  input: SivOperationMoveInput;
};


export type MutationSivOperationPerformanceRecordingBatchUpdateArgs = {
  input: Array<SivOperationPerformanceRecordingBatchUpdateInput>;
};


export type MutationSivOperationPinToggleArgs = {
  input: SivOperationPinToggleInput;
};


export type MutationSivOperationPlanningChargePerMinuteUpdateArgs = {
  input: SivOperationPlanningChargePerMinuteUpdateInput;
};


export type MutationSivOperationPlanningHourUpdateArgs = {
  input: SivOperationPlanningHourUpdateInput;
};


export type MutationSivOperationPlanningMaterialUpdateArgs = {
  input: SivOperationPlanningMaterialUpdateInput;
};


export type MutationSivOperationPlanningPerformanceValueUpdateArgs = {
  input: SivOperationPlanningPerformanceValueUpdateInput;
};


export type MutationSivOperationRecordingHourUpdateArgs = {
  input: SivOperationRecordingHourUpdateInput;
};


export type MutationSivOperationRecordingMaterialUpdateArgs = {
  input: SivOperationRecordingMaterialUpdateInput;
};


export type MutationSivOperationRecordingTotalMeasurementUpdateArgs = {
  input: SivOperationRecordingTotalMeasurementUpdateInput;
};


export type MutationSivOperationRemoveArgs = {
  input: SivOperationRemoveInput;
};


export type MutationSivOperationSortIndexUpdateArgs = {
  input: SivOperationSortIndexUpdateInput;
};


export type MutationSivOperationSplitPreferenceUpdateArgs = {
  input: SivOperationSplitPreferenceUpdateInput;
};


export type MutationSivOperationUnitPriceUpdateArgs = {
  input: SivOperationUnitPriceUpdateInput;
};


export type MutationSivOperationUpdateArgs = {
  input: SivOperationUpdateInput;
};


export type MutationSivPlanningCostSectionFinishArgs = {
  input: SivPlanningCostSectionFinishInput;
};


export type MutationSivPlanningEmployeesSetArgs = {
  input: SivPlanningEmployeesSetInput;
};


export type MutationSivPlanningFlatRateUpdateArgs = {
  input: SivPlanningFlatRateUpdateInput;
};


export type MutationSivPlanningFreelanceEmployeesSetArgs = {
  input: SivPlanningFreelanceEmployeesSetInput;
};


export type MutationSivPlanningGroupFlatRateUpdateArgs = {
  input: SivPlanningGroupFlatRateUpdateInput;
};


export type MutationSivPlanningSubcontractorCreateArgs = {
  input: SivPlanningSubcontractorCreateInput;
};


export type MutationSivPlanningSubcontractorRemoveArgs = {
  input: SivPlanningSubcontractorRemoveInput;
};


export type MutationSivPlanningSubcontractorUpdateArgs = {
  input: SivPlanningSubcontractorUpdateInput;
};


export type MutationSivPlanningUpdateArgs = {
  input: SivPlanningUpdateInput;
};


export type MutationSivRecordingFlatRateUpdateArgs = {
  input: SivRecordingFlatRateUpdateInput;
};


export type MutationSivRecordingGroupFlatRateUpdateArgs = {
  input: SivRecordingGroupFlatRateUpdateInput;
};


export type MutationSivRecordingSubcontractorInvoicesSetArgs = {
  input: SivRecordingSubcontractorInvoicesSetInput;
};


export type MutationSivRecordingUpdateArgs = {
  input: SivRecordingUpdateInput;
};


export type MutationSyncProjectArgs = {
  input: SyncProjectInput;
};


export type MutationTimeTrackingAdditionalRowAddArgs = {
  input: TimeTrackingAdditionalRowAddInput;
};


export type MutationTimeTrackingBatchOperationArgs = {
  input: TimeTrackingBatchOperationInput;
};


export type MutationTimeTrackingEntryIndicatorMultiSaveArgs = {
  input: Array<TimeTrackingEntryIndicatorSaveInput>;
};


export type MutationTimeTrackingEntryIndicatorSaveArgs = {
  input: TimeTrackingEntryIndicatorSaveInput;
};


export type MutationTimeTrackingEntryMultiRemoveArgs = {
  input: TimeTrackingEntryMultiRemoveInput;
};


export type MutationTimeTrackingEntryNoteMultiSaveArgs = {
  input: Array<TimeTrackingEntryNoteSaveInput>;
};


export type MutationTimeTrackingEntryNoteSaveArgs = {
  input: TimeTrackingEntryNoteSaveInput;
};


export type MutationTimeTrackingEntryTimeMultiRemoveArgs = {
  input: TimeTrackingEntryTimeMultiRemoveInput;
};


export type MutationTimeTrackingEntryTimeMultiSaveArgs = {
  input: Array<TimeTrackingEntryTimeSaveInput>;
};


export type MutationTimeTrackingEntryTimeRemoveArgs = {
  input: TimeTrackingEntryTimeRemoveInput;
};


export type MutationTimeTrackingEntryTimeSaveArgs = {
  input: TimeTrackingEntryTimeSaveInput;
};


export type MutationTimeTrackingProjectMultiApproveArgs = {
  input: TimeTrackingProjectMultiApproveInput;
};


export type MutationTimeTrackingRemoveWeekYearProjectArgs = {
  input: TimeTrackingRemoveWeekYearProjectInput;
};


export type MutationTimeTrackingResponsibleToggleArgs = {
  input: TimeTrackingResponsibleToggleInput;
};


export type MutationTimeTrackingRowRemoveArgs = {
  input: TimeTrackingRowRemoveInput;
};


export type MutationTimeTrackingSapEmployeeWeekRecordAddArgs = {
  input: TimeTrackingSapEmployeeWeekRecordAddInput;
};


export type MutationTimeTrackingSapTransmitArgs = {
  input: TimeTrackingSapTransmitInput;
};


export type MutationTimeTrackingSwitchMultiSapEmployeeArgs = {
  input: TimeTrackingSwitchMultiSapEmployeeInput;
};


export type MutationTimeTrackingSwitchProjectArgs = {
  input: TimeTrackingSwitchProjectInput;
};


export type MutationTimeTrackingSwitchSapEmployeeArgs = {
  input: TimeTrackingSwitchSapEmployeeInput;
};


export type MutationTimeTrackingUnknownEmployeeUpdateArgs = {
  input: TimeTrackingUnknownEmployeeUpdateInput;
};


export type MutationTimeTrackingUnknownEmployeeWeekRecordAddArgs = {
  input: TimeTrackingUnknownEmployeeWeekRecordAddInput;
};


export type MutationTimeTrackingWeekRecordSwitchProjectArgs = {
  input: TimeTrackingWeekRecordSwitchProjectInput;
};


export type MutationToggleShowSubProjectHoursArgs = {
  input: ToggleShowSubProjectHoursInput;
};


export type MutationUpdateCatalogueArgs = {
  input: UpdateCatalogueInput;
};


export type MutationUpdateFolderForFrameworkArgs = {
  input: UpdateFolderForFrameworkInput;
};


export type MutationUpdateFolderForFreeFormArgs = {
  input: UpdateFolderForFreeFormInput;
};


export type MutationUpdateFolderOperationArgs = {
  input: UpdateFolderOperationInput;
};


export type MutationUpdateOfferCalculationArgs = {
  input: UpdateOfferCalculationInput;
};


export type MutationUpdateOfferSortingForFrameworkArgs = {
  input: UpdateOfferSortingForFrameworkInput;
};


export type MutationUpdateOfferSortingForFreeFormArgs = {
  input: UpdateOfferSortingForFreeFormInput;
};


export type MutationUpdateOperationArgs = {
  input: UpdateOperationInput;
};


export type MutationUpdateProjectStatusArgs = {
  input: UpdateProjectStatusInput;
};


export type MutationUpdateTaxRateArgs = {
  input: UpdateTaxRateInput;
};


export type MutationUploadAcceptanceReportSignatureArgs = {
  file: Scalars['Upload']['input'];
  input: UploadAcceptanceReportSignatureInput;
};


export type MutationUploadCatalogueArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUploadEditorImageArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUploadImageArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationUploadManualPdfToAcceptanceReportArgs = {
  file: Scalars['Upload']['input'];
  input: UploadManualPdfToAcceptanceReportInput;
};


export type MutationUploadManualPdfToOvertimeArgs = {
  file: Scalars['Upload']['input'];
  input: UploadManualPdfToOvertimeInput;
};


export type MutationUploadOvertimeSignatureArgs = {
  file: Scalars['Upload']['input'];
  input: UploadOvertimeSignatureInput;
};


export type MutationUploadRiskAssessmentSignatureArgs = {
  file: Scalars['Upload']['input'];
  input: UploadRiskAssessmentSignatureInput;
};


export type MutationViewerLanguageUpdateArgs = {
  input: ViewerLanguageUpdateInput;
};


export type MutationWatchEventsArgs = {
  input: WatchEventsInput;
};

export type Notable = {
  note?: Maybe<DriveEntryNote>;
};

export enum NumerableDocumentType {
  Overtime = 'OVERTIME'
}

export type Obstruction = DocumentInterface & {
  __typename?: 'Obstruction';
  /** The user who is the owner of this obstruction */
  addedBy: User;
  /** Name of the client. */
  clientName: Scalars['String']['output'];
  /** Creation date of the obstruction */
  createdAt: Scalars['Date']['output'];
  /** Date of the obstruction. */
  date?: Maybe<Scalars['Date']['output']>;
  /** Facts of the obstruction */
  facts: Array<ObstructionFact>;
  /** Document ID of the obstruction. */
  id: Scalars['ID']['output'];
  /** Images of the obstruction */
  images: Array<ObstructionImage>;
  /** Logs of the obstruction */
  logs?: Maybe<Logs>;
  /** Name of the obstruction. */
  name: Scalars['String']['output'];
  /** Obstruction pdf path */
  pdfPath: Scalars['String']['output'];
  /** Professions of the obstruction. */
  professions: Array<Scalars['String']['output']>;
  /** Project of the obstruction */
  project: Project;
  /** Signature of the obstruction */
  signatures: Array<ObstructionSignature>;
  /** Status of the obstruction */
  status: ObstructionStatus;
  /** Update date of the obstruction */
  updatedAt: Scalars['Date']['output'];
  /** Indicates whether the viewer can archive the obstruction */
  viewerCanArchive: Scalars['Boolean']['output'];
  /** indicates whether the obstruction is assignable to a project */
  viewerCanAssign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the obstruction */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can sign the obstruction */
  viewerCanSign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the obstruction */
  viewerCanUpdate: Scalars['Boolean']['output'];
};


export type ObstructionLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ObstructionArchiveInput = {
  /** Id of the obstruction. */
  documentId: Scalars['ID']['input'];
  /** SAP password */
  sapPassword: Scalars['String']['input'];
  /** SAP username */
  sapUsername: Scalars['String']['input'];
};

export type ObstructionArchivedLog = Log & LogWithActor & LogWithDate & ObstructionLog & {
  __typename?: 'ObstructionArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

export type ObstructionCreatedLog = Log & LogWithActor & LogWithDate & ObstructionLog & {
  __typename?: 'ObstructionCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

export type ObstructionDeletedLog = Log & LogWithActor & LogWithDate & ObstructionLog & {
  __typename?: 'ObstructionDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

export type ObstructionDuplicateInput = {
  /** Id of the obstruction */
  documentId: Scalars['ID']['input'];
};

export type ObstructionEmailSendInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the obstruction. */
  documentId: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type ObstructionEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & ObstructionLog & {
  __typename?: 'ObstructionEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that obstruction sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

export type ObstructionFact = {
  __typename?: 'ObstructionFact';
  /** Description of the obstruction fact */
  description: Scalars['String']['output'];
  /** Title of the obstruction fact */
  title: Scalars['String']['output'];
};

export type ObstructionFactSaveInput = {
  /** Description of the obstruction fact */
  description: Scalars['String']['input'];
  /** Title of the obstruction fact */
  title: Scalars['String']['input'];
};

/** An obstruction image */
export type ObstructionImage = {
  __typename?: 'ObstructionImage';
  /** ID of the image */
  id: Scalars['ID']['output'];
  /** Label of the image */
  label?: Maybe<Scalars['String']['output']>;
  /** Thumbnail of the image */
  thumbnail: Scalars['String']['output'];
  /** Full url of the image */
  url: Scalars['String']['output'];
};


/** An obstruction image */
export type ObstructionImageThumbnailArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type ObstructionImageInput = {
  /** Id of the image. */
  imageId: Scalars['ID']['input'];
  /** Label for the image. */
  label?: InputMaybe<Scalars['String']['input']>;
};

export type ObstructionLog = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

export type ObstructionSaveInput = {
  /** Name of the client. */
  clientName: Scalars['String']['input'];
  /** Date of the obstruction. */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** Document ID of the obstruction. */
  documentId: Scalars['ID']['input'];
  /** Facts of the obstruction */
  facts: Array<ObstructionFactSaveInput>;
  /** Images for the obstruction */
  images: Array<ObstructionImageInput>;
  /** Contractor of the obstruction. */
  professions: Array<Scalars['String']['input']>;
};

export type ObstructionSignInput = {
  /** ID of the obstruction */
  documentId: Scalars['ID']['input'];
  /** Full name of the signatory */
  fullName: Scalars['String']['input'];
  /** Image of the signature */
  image: Scalars['Upload']['input'];
};

export type ObstructionSignature = {
  __typename?: 'ObstructionSignature';
  /** Creation date of the signature */
  createdAt: Scalars['Date']['output'];
  /** Full name of the signatory */
  fullName: Scalars['String']['output'];
  /** Image path of the signature */
  imagePath: Scalars['String']['output'];
};

export type ObstructionSignedLog = Log & LogWithActor & LogWithDate & ObstructionLog & {
  __typename?: 'ObstructionSignedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

/** Obstruction status enum */
export enum ObstructionStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export type ObstructionUpdatedLog = Log & LogWithActor & LogWithDate & ObstructionLog & {
  __typename?: 'ObstructionUpdatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  obstructionId: Scalars['ID']['output'];
};

/** Offer is the type for offer. */
export type Offer = AssignableInterface & DocumentInterface & RemovableInterface & {
  __typename?: 'Offer';
  /** The user who is owner of this offer */
  addedBy: User;
  /** Catalogue of the offer if it is a framework offer. */
  catalogue?: Maybe<Catalogue>;
  /** It returns all folders and subfolder in this offer */
  folders: Array<Folder>;
  /** ID of the offer */
  id: Scalars['ID']['output'];
  /** Logs of the offer */
  logs?: Maybe<Logs>;
  /** Meta data for offer. */
  meta?: Maybe<OfferMeta>;
  /** Mode of the offer */
  mode: OfferMode;
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** Special Offer ID of the offer */
  offerId: Scalars['ID']['output'];
  /** Pdf path for the offer if it has been generated before. */
  pdfPath?: Maybe<Scalars['String']['output']>;
  /** Project of the offer */
  project: Project;
  /** Status of the offer */
  status: OfferStatus;
  /** Price summary of the offer */
  summary: OfferSummary;
  /** Type of the offer */
  type: OfferType;
  /** Last modified date for the offer */
  updatedAt: Scalars['Date']['output'];
  /** Indicates whether the viewer can assign the offer to a project */
  viewerCanAssign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can export the offer */
  viewerCanExport: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the offer */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the offer */
  viewerCanUpdate: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the offer tax rate */
  viewerCanUpdateTaxRate: Scalars['Boolean']['output'];
};


/** Offer is the type for offer. */
export type OfferLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type OfferCreatedLog = Log & LogWithActor & LogWithDate & OfferLog & {
  __typename?: 'OfferCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the offer */
  id: Scalars['ID']['output'];
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** ID of the offer */
  offerId: Scalars['ID']['output'];
};

export type OfferDeletedLog = Log & LogWithActor & LogWithDate & OfferLog & {
  __typename?: 'OfferDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the offer */
  id: Scalars['ID']['output'];
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** ID of the offer */
  offerId: Scalars['ID']['output'];
};

/** OfferDraftClientMetaData is the type for offer draft client meta data. */
export type OfferDraftClientMetaData = {
  __typename?: 'OfferDraftClientMetaData';
  /** Address of the draft client. */
  address?: Maybe<Scalars['String']['output']>;
  /** City of the draft client. */
  city?: Maybe<Scalars['String']['output']>;
  /** Name of the draft client. */
  name?: Maybe<Scalars['String']['output']>;
  /** Zip code of the draft client. */
  zip?: Maybe<Scalars['String']['output']>;
};

export type OfferDraftClientMetaDataInput = {
  /** Address of the draft client. */
  address?: InputMaybe<Scalars['String']['input']>;
  /** City of the draft client. */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Name of the draft client. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Zip code of the draft client. */
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** OfferDraftClientMetaData is the type for offer draft client meta data. */
export type OfferDraftConstructionSiteMetaData = {
  __typename?: 'OfferDraftConstructionSiteMetaData';
  /** Address of the draft client. */
  address?: Maybe<Scalars['String']['output']>;
  /** City of the draft client. */
  city?: Maybe<Scalars['String']['output']>;
  /** Name of the draft client. */
  name?: Maybe<Scalars['String']['output']>;
  /** Zip code of the draft client. */
  zip?: Maybe<Scalars['String']['output']>;
};

export type OfferEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & OfferLog & {
  __typename?: 'OfferEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Email addresses of the offer email */
  emails: Array<Scalars['String']['output']>;
  /** ID of the offer */
  id: Scalars['ID']['output'];
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** ID of the offer */
  offerId: Scalars['ID']['output'];
};

export type OfferExportedToBotLog = Log & LogWithActor & LogWithDate & OfferLog & {
  __typename?: 'OfferExportedToBotLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the offer */
  id: Scalars['ID']['output'];
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** ID of the offer */
  offerId: Scalars['ID']['output'];
};

export type OfferFrameworkSaveInput = {
  /** Catalogue ID of the offer. */
  catalogueId: Scalars['String']['input'];
  /** Document ID of the offer. */
  documentId: Scalars['ID']['input'];
  /** Meta data for offer. */
  meta?: InputMaybe<OfferMetaInput>;
  mode: OfferMode;
  /** Offer name. */
  name: Scalars['String']['input'];
};

export type OfferFreeFormSaveInput = {
  /** Document ID of the offer. */
  documentId: Scalars['ID']['input'];
  /** Meta data for offer. */
  meta?: InputMaybe<OfferMetaInput>;
  mode: OfferMode;
  /** Offer name. */
  name: Scalars['String']['input'];
};

export type OfferLog = {
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the offer */
  name: Scalars['String']['output'];
  /** ID of the offer */
  offerId: Scalars['ID']['output'];
};

/** OfferMeta is the type for offer meta. */
export type OfferMeta = {
  __typename?: 'OfferMeta';
  /** Draft client meta data. */
  draftClient?: Maybe<OfferDraftClientMetaData>;
  /** Draft construction site meta data. */
  draftConstructionSite?: Maybe<OfferDraftConstructionSiteMetaData>;
};

export type OfferMetaInput = {
  /** Draft client meta data. */
  draftClient?: InputMaybe<OfferDraftClientMetaDataInput>;
  /** Draft construction site meta data. */
  draftConstructionSite?: InputMaybe<OfferDraftClientMetaDataInput>;
};

/** Offer mode enum */
export enum OfferMode {
  AliasNumber = 'ALIAS_NUMBER',
  SystemNumber = 'SYSTEM_NUMBER'
}

/** Offer Sortable type enum */
export enum OfferSortableType {
  Folder = 'FOLDER',
  FolderOperation = 'FOLDER_OPERATION'
}

/** Offer status enum */
export enum OfferStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN'
}

/** Price summary of the offer */
export type OfferSummary = {
  __typename?: 'OfferSummary';
  /** Extra prices of the offer */
  extraPrices: Array<ExtraPrice>;
  /** Gross price of the offer */
  grossPrice: Scalars['Float']['output'];
  /** Tax price of the offer */
  taxPrice: Scalars['Float']['output'];
  /** Tax rate */
  taxRate: Scalars['Float']['output'];
  /** Total optional price of the offer */
  totalOptionalPrice: Scalars['Float']['output'];
  /** Total price of the offer */
  totalPrice: Scalars['Float']['output'];
};

/** Offer type enum */
export enum OfferType {
  Framework = 'FRAMEWORK',
  FreeForm = 'FREE_FORM'
}

/** Operation from a catalogue. */
export type Operation = {
  __typename?: 'Operation';
  /** Calculated part no of the operation */
  calculatedPartNo: Scalars['String']['output'];
  /** Category of the offer. */
  category: Category;
  /** Description for the operation */
  description?: Maybe<Scalars['String']['output']>;
  /** Whether the operation has any missing field or not */
  hasMissingFields: Scalars['Boolean']['output'];
  /** ID of the operation */
  id: Scalars['ID']['output'];
  /** Name of the operation */
  name?: Maybe<Scalars['String']['output']>;
  /** Part no of the operation */
  partNo: Scalars['String']['output'];
  /** Price no of the operation */
  price?: Maybe<Scalars['Float']['output']>;
  /** Unit of the operation */
  unit?: Maybe<OperationUnit>;
  /** Whether the operation can select as multiple operations or not */
  viewerCanSelectAsMultiple: Scalars['Boolean']['output'];
};

export type OperationFilter = {
  excludedTypes?: InputMaybe<Array<SivOperationType>>;
  includedTypes?: InputMaybe<Array<SivOperationType>>;
};

/** Operation Payment from a folder operation. */
export type OperationPayment = {
  __typename?: 'OperationPayment';
  /** Base price of the operation */
  basePrice?: Maybe<Scalars['Float']['output']>;
  /** Value of the the discount or surcharge. */
  discountOrSurchargeValue?: Maybe<Scalars['Float']['output']>;
  /** Type of the discount or surcharge value. */
  discountOrSurchargeValueType?: Maybe<DiscountOrSurchargeValueType>;
  /** Is total price flat */
  flat: Scalars['Boolean']['output'];
  /** Total price of the operation */
  totalPrice?: Maybe<Scalars['Float']['output']>;
};

export type OperationPaymentInput = {
  /** Value of the the discount or surcharge. */
  discountOrSurchargeValue?: InputMaybe<Scalars['Float']['input']>;
  /** Type of the discount or surcharge value. */
  discountOrSurchargeValueType?: InputMaybe<DiscountOrSurchargeValueType>;
  /** Is total price flat */
  flat: Scalars['Boolean']['input'];
  /** Total price of the operation. */
  totalPrice?: InputMaybe<Scalars['Float']['input']>;
};

/** Unit for the catalogue operation. */
export enum OperationUnit {
  CubicMeter = 'CUBIC_METER',
  Day = 'DAY',
  FlatRate = 'FLAT_RATE',
  Hour = 'HOUR',
  Kilogram = 'KILOGRAM',
  Liter = 'LITER',
  Meter = 'METER',
  Quantity = 'QUANTITY',
  SquareMeter = 'SQUARE_METER'
}

export type Overtime = AssignableInterface & DocumentInterface & RemovableInterface & {
  __typename?: 'Overtime';
  addedBy: User;
  alias?: Maybe<Scalars['String']['output']>;
  clientName?: Maybe<Scalars['String']['output']>;
  clientNameComputed?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  date: Scalars['Date']['output'];
  draftProjectNumber?: Maybe<Scalars['String']['output']>;
  employees: Array<OvertimeEmployee>;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  images: Array<OvertimeImage>;
  /** Logs of the overtime */
  logs?: Maybe<Logs>;
  manualPdfPath?: Maybe<Scalars['String']['output']>;
  meta: OvertimeMeta;
  name: Scalars['String']['output'];
  no: Scalars['Int']['output'];
  noComputed: Scalars['String']['output'];
  orderDate: Scalars['Date']['output'];
  pdfPath: Scalars['String']['output'];
  project: Project;
  projectNumberComputed?: Maybe<Scalars['String']['output']>;
  signatures: Array<OvertimeSignature>;
  status: OvertimeStatus;
  updatedAt: Scalars['Date']['output'];
  viewerCanArchive: Scalars['Boolean']['output'];
  viewerCanAssign: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanRemoveManualPdf: Scalars['Boolean']['output'];
  viewerCanSendExternalLink: Scalars['Boolean']['output'];
  viewerCanSign: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
  viewerCanUploadManualPdf: Scalars['Boolean']['output'];
  workDescription: Scalars['String']['output'];
  workName: Scalars['String']['output'];
};


export type OvertimeLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type OvertimeArchivedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeCreatedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeDeletedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & OvertimeLog & {
  __typename?: 'OvertimeEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that overtime sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeEmployee = {
  __typename?: 'OvertimeEmployee';
  extraHours?: Maybe<Scalars['Float']['output']>;
  fullName: Scalars['String']['output'];
  hours: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  machinesHours?: Maybe<Scalars['Float']['output']>;
  materialSurcharge?: Maybe<Scalars['String']['output']>;
  role: WorkerRole;
};

export type OvertimeEmployeeSaveInput = {
  extraHours?: InputMaybe<Scalars['Float']['input']>;
  fullName: Scalars['String']['input'];
  hours: Scalars['Float']['input'];
  machinesHours?: InputMaybe<Scalars['Float']['input']>;
  materialSurcharge?: InputMaybe<Scalars['String']['input']>;
  role: WorkerRole;
};

export type OvertimeExternalEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & OvertimeLog & {
  __typename?: 'OvertimeExternalEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that overtime sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeExternalLinkExpiredLog = Log & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeExternalLinkExpiredLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Expired date of the overtime external link in ISO-8601 format */
  expiredDate: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeImage = {
  __typename?: 'OvertimeImage';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  thumbnail: Scalars['String']['output'];
  url: Scalars['String']['output'];
};


export type OvertimeImageThumbnailArgs = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type OvertimeImageInput = {
  imageId: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type OvertimeLog = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeManuallySignedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeManuallySignedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeMeta = {
  __typename?: 'OvertimeMeta';
  totalHours: Scalars['Float']['output'];
};

export type OvertimeNotSignedLog = Log & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeNotSignedLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The day count of that document not signed */
  dayCount?: Maybe<Scalars['Int']['output']>;
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimePdfPath = {
  __typename?: 'OvertimePdfPath';
  manualPdfPath?: Maybe<Scalars['String']['output']>;
  overtimeId: Scalars['ID']['output'];
  pdfPath?: Maybe<Scalars['String']['output']>;
};

export type OvertimeRenamedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeRenamedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** Name of the overtime */
  oldName: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export type OvertimeSaveInput = {
  alias?: InputMaybe<Scalars['String']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  documentId: Scalars['ID']['input'];
  draftProjectNumber?: InputMaybe<Scalars['String']['input']>;
  employees: Array<OvertimeEmployeeSaveInput>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  images: Array<OvertimeImageInput>;
  orderDate: Scalars['Date']['input'];
  workDescription: Scalars['String']['input'];
  workName: Scalars['String']['input'];
};

export type OvertimeSignature = SignatureInterface & {
  __typename?: 'OvertimeSignature';
  createdAt: Scalars['Date']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imagePath: Scalars['String']['output'];
  overtime: Overtime;
  type: SignatureType;
};

export type OvertimeSignedByCustomerLog = Log & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeSignedByCustomerLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

export enum OvertimeStatus {
  Archived = 'ARCHIVED',
  ManuallySigned = 'MANUALLY_SIGNED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export type OvertimeUpdatedLog = Log & LogWithActor & LogWithDate & OvertimeLog & {
  __typename?: 'OvertimeUpdatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** ID of the overtime */
  overtimeId: Scalars['ID']['output'];
};

/** Pagination meta data */
export type Pagination = {
  __typename?: 'Pagination';
  /** Current page */
  current: Scalars['Int']['output'];
  /** Has next page or not */
  hasNextPage: Scalars['Boolean']['output'];
};

/** Precaution is the type for precaution. */
export type Precaution = PrecautionInterface & {
  __typename?: 'Precaution';
  /** Equipments of the precaution */
  equipments: Array<Equipment>;
  /** ID of the precaution */
  id: Scalars['ID']['output'];
  /** Precaution category of the precaution */
  precautionCategory: PrecautionCategory;
  /** Sort number of the precaution */
  sort: Scalars['Int']['output'];
  /** Title of the precaution */
  title: Scalars['String']['output'];
};

/** Precaution Category is the type for precaution category. */
export type PrecautionCategory = {
  __typename?: 'PrecautionCategory';
  /** Icon of the precaution category */
  icon: Scalars['String']['output'];
  /** ID of the precaution category */
  id: Scalars['ID']['output'];
  /** It returns all precautions of precaution category */
  precautions: Array<Precaution>;
  /** Sort number of the precaution category */
  sort: Scalars['Int']['output'];
  /** Title of the precaution category */
  title: Scalars['String']['output'];
};

/** Interface for precaution */
export type PrecautionInterface = {
  /** ID of the precaution */
  id: Scalars['ID']['output'];
  /** Precaution category of the precaution */
  precautionCategory: PrecautionCategory;
  /** Title of the precaution */
  title: Scalars['String']['output'];
};

/** It shows profit center information */
export type ProfitCenter = {
  __typename?: 'ProfitCenter';
  /** Address of the Profit Center */
  address?: Maybe<ProfitCenterAddress>;
  /** External ID of the Profit Center */
  hsExternalId?: Maybe<Scalars['ID']['output']>;
  /** ID of the Profit Center */
  id: Scalars['ID']['output'];
  /** The name of the profit center */
  name: Scalars['String']['output'];
  /** Parent of the Profit Center */
  parent?: Maybe<ProfitCenter>;
  projectCount: Scalars['Int']['output'];
};

/** Address of the profit center */
export type ProfitCenterAddress = {
  __typename?: 'ProfitCenterAddress';
  /** The city of the address */
  city: Scalars['String']['output'];
  /** The name of the address */
  name: Scalars['String']['output'];
  /** The street of the address */
  street: Scalars['String']['output'];
  /** The zip of the address */
  zip: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  /** The user who is owner of this project */
  addedBy: User;
  /** Additional name of the project */
  additionalName?: Maybe<Scalars['String']['output']>;
  /**
   * Address of the project
   * @deprecated This field deprecated. You should use addressLine field instead of address field.
   */
  address?: Maybe<Scalars['String']['output']>;
  /** Address line of the project */
  addressLine?: Maybe<Scalars['String']['output']>;
  /** City of the project */
  city?: Maybe<Scalars['String']['output']>;
  /** Client of the project */
  client?: Maybe<Client>;
  /** It returns contact people of the project */
  contactPeople: Array<ContactPerson>;
  contentUpdatedAt: Scalars['Date']['output'];
  /** Date of the project */
  date?: Maybe<Scalars['Date']['output']>;
  /** Description of the project */
  description: Scalars['String']['output'];
  /** Project is whether draft or not */
  draft: Scalars['Boolean']['output'];
  drive: Drive;
  /** Heinrich-Schmid project id */
  hsId?: Maybe<Scalars['String']['output']>;
  /** Heinrich-Schmid project number */
  hsProjectNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Whether this project is a main project */
  isMainProject: Scalars['Boolean']['output'];
  /** Logo of the project company */
  logo?: Maybe<Scalars['String']['output']>;
  /** Name of the project */
  name: Scalars['String']['output'];
  /** It returns all offers of the project */
  offers: Array<Offer>;
  /** ordered */
  ordered: Scalars['Boolean']['output'];
  /** part projects */
  partProjects: Array<PublicPartProject>;
  /** Post code of the project */
  postCode?: Maybe<Scalars['String']['output']>;
  /** Profit Center of the Project that belongs to */
  profitCenter?: Maybe<ProfitCenter>;
  /** Project number of the project */
  projectNumber: Scalars['ID']['output'];
  /** @deprecated Use `scopes` from ViewerUserScopes */
  scopes: ViewerProjectScopes;
  /** whether show sub project hour */
  showSubProjectHours: Scalars['Boolean']['output'];
  siv?: Maybe<Siv>;
  /** Source system which project imported from */
  sourceSystem: ProjectImportSourceSystem;
  /** Status of the project */
  status: ProjectStatus;
  /** Street of the project */
  street?: Maybe<Scalars['String']['output']>;
  /** Project is whether syncable with DataHub or not */
  syncable: Scalars['Boolean']['output'];
  /** Last modified date for the project */
  updatedAt: Scalars['Date']['output'];
  /** Indicates whether the viewer can update status of the project */
  viewerCanUpdateStatus: Scalars['Boolean']['output'];
  viewerFollowsProject: Scalars['Boolean']['output'];
  /** Unread log count of viewer */
  viewerLogUnreadCount: Scalars['Int']['output'];
};

export type ProjectAddressChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectAddressChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** Address of the project */
  address: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Old address of the project */
  oldAddress?: Maybe<Scalars['String']['output']>;
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectClientAddressChangedLog = Log & LogWithDate & ProjectLog & {
  __typename?: 'ProjectClientAddressChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** Client address of the project */
  clientAddress?: Maybe<Scalars['String']['output']>;
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the project client log */
  id: Scalars['ID']['output'];
  /** Old client address of the project */
  oldClientAddress?: Maybe<Scalars['String']['output']>;
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectClientNameChangedLog = Log & LogWithDate & ProjectLog & {
  __typename?: 'ProjectClientNameChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** Client name of the project */
  clientName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the project client log */
  id: Scalars['ID']['output'];
  /** Old client name of the project */
  oldClientName?: Maybe<Scalars['String']['output']>;
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectContractDateChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectContractDateChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** Contract date of the project in ISO-8601 format */
  contractDate: Scalars['Date']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Old contract date of the project in ISO-8601 format */
  oldContractDate?: Maybe<Scalars['Date']['output']>;
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectDateUpdateInput = {
  /** Date of project. */
  date?: InputMaybe<Scalars['Date']['input']>;
  /** ID of project. */
  id: Scalars['ID']['input'];
};

export enum ProjectExternalStatus {
  ActiveConstruction = 'ACTIVE_CONSTRUCTION',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Created = 'CREATED',
  Offered = 'OFFERED',
  Open = 'OPEN',
  Ordered = 'ORDERED',
  OrderNotReceived = 'ORDER_NOT_RECEIVED',
  Rejected = 'REJECTED',
  TerminatedWithoutInvoice = 'TERMINATED_WITHOUT_INVOICE'
}

export type ProjectExternalStatusChangedLog = Log & LogWithDate & ProjectLog & {
  __typename?: 'ProjectExternalStatusChangedLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** New status */
  externalStatus: ProjectExternalStatus;
  /** ID of the project */
  id: Scalars['ID']['output'];
  /** Old status */
  oldExternalStatus?: Maybe<ProjectExternalStatus>;
  /** Id of the project */
  projectId: Scalars['ID']['output'];
  /** Project's import source */
  projectImportSourceSystem: ProjectImportSourceSystem;
  /** Project is ordered */
  projectIsOrdered: Scalars['Boolean']['output'];
};

/** Project import source system enum */
export enum ProjectImportSourceSystem {
  Dds = 'DDS',
  Itworun = 'ITWORUN',
  Vendoc = 'VENDOC'
}

export type ProjectImportedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectImportedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Id of the project */
  projectId: Scalars['ID']['output'];
  /** Name of the project */
  projectName: Scalars['String']['output'];
  /** Number of the project */
  projectNumber: Scalars['ID']['output'];
  /** Source system which project imported from */
  sourceSystem: ProjectImportSourceSystem;
};

export type ProjectLog = {
  /** Id of the log */
  id: Scalars['ID']['output'];
  /** Id of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectNameChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectNameChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Name of the project */
  name: Scalars['String']['output'];
  /** Previous name of the project */
  oldName: Scalars['String']['output'];
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectOfferNumberChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectOfferNumberChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Offer number of the project */
  offerNumber: Scalars['String']['output'];
  /** Old offer number of the project */
  oldOfferNumber: Scalars['String']['output'];
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectOrderNumberChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectOrderNumberChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Offer number of the project */
  offerNumber: Scalars['String']['output'];
  /** Order number of the project */
  orderNumber: Scalars['String']['output'];
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectProfitCenterChangedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectProfitCenterChangedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the document log */
  id: Scalars['ID']['output'];
  /** Profit center id of the project */
  profitCenterId: Scalars['String']['output'];
  /** Profit center name of the project */
  profitCenterName: Scalars['String']['output'];
  /** ID of the project */
  projectId: Scalars['ID']['output'];
};

export type ProjectSorting = {
  /** Sorting direction */
  direction: SortingDirection;
  /** Field for sorting */
  field: ProjectSortingField;
};

/** The sorting field for project */
export enum ProjectSortingField {
  HsProjectNumber = 'HS_PROJECT_NUMBER',
  Name = 'NAME',
  ProfitCenter = 'PROFIT_CENTER',
  Status = 'STATUS',
  UpdatedAt = 'UPDATED_AT'
}

/** Project status enum */
export enum ProjectStatus {
  ActiveConstruction = 'ACTIVE_CONSTRUCTION',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  Offered = 'OFFERED',
  Ordered = 'ORDERED',
  Registered = 'REGISTERED'
}

export type ProjectStatusUpdatedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectStatusUpdatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Old status */
  from: ProjectStatus;
  /** ID of the project */
  id: Scalars['ID']['output'];
  /** Id of the project */
  projectId: Scalars['ID']['output'];
  /** New status */
  to: ProjectStatus;
};

export type ProjectSyncedLog = Log & LogWithActor & LogWithDate & ProjectLog & {
  __typename?: 'ProjectSyncedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Id of the project */
  projectId: Scalars['ID']['output'];
};

/** Projects payload */
export type Projects = {
  __typename?: 'Projects';
  /** Filtered result meta data */
  meta: ProjectsMeta;
  /** Pagination meta data */
  pagination: Pagination;
  /** Projects for given arguments */
  projects: Array<Project>;
};

/** Filtered projects meta on projects query */
export type ProjectsMeta = {
  __typename?: 'ProjectsMeta';
  /** Selected filters meta data */
  selectedFilters: ProjectsSelectedFiltersMeta;
  /** Total count by selected filters */
  totalCount: Scalars['Int']['output'];
};

/** Profit center filter meta on the projects query */
export type ProjectsProfitCenterFilterMeta = {
  __typename?: 'ProjectsProfitCenterFilterMeta';
  /** Count of profit center projects */
  count: Scalars['Int']['output'];
  /** Profit center */
  profitCenter: ProfitCenter;
};

/** Selected filters meta on the projects query */
export type ProjectsSelectedFiltersMeta = {
  __typename?: 'ProjectsSelectedFiltersMeta';
  /** Profit center filter meta  */
  profitCenter: Array<ProjectsProfitCenterFilterMeta>;
  /** Status filter meta  */
  status: Array<ProjectsStatusFilterMeta>;
};

/** Status filter meta on the projects query */
export type ProjectsStatusFilterMeta = {
  __typename?: 'ProjectsStatusFilterMeta';
  /** Count of projects by status */
  count: Scalars['Int']['output'];
  /** Status */
  status: ProjectStatus;
};

export type PublicAcceptanceReport = {
  __typename?: 'PublicAcceptanceReport';
  accessDueDate: Scalars['Date']['output'];
  addedBy: User;
  alias?: Maybe<Scalars['String']['output']>;
  contactPersonEmail: Scalars['String']['output'];
  /** Creation date of the acceptance report */
  createdAt: Scalars['Date']['output'];
  /** Date of the acceptance report */
  date: Scalars['Date']['output'];
  documentCanBeSigned: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  no: Scalars['Int']['output'];
  noComputed: Scalars['String']['output'];
  /** Logo of the project company */
  projectLogo?: Maybe<Scalars['String']['output']>;
  signedByCustomer: Scalars['Boolean']['output'];
  status: AcceptanceReportStatus;
};

export type PublicOvertime = {
  __typename?: 'PublicOvertime';
  accessDueDate: Scalars['Date']['output'];
  addedBy: User;
  alias?: Maybe<Scalars['String']['output']>;
  contactPersonEmail: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  date: Scalars['Date']['output'];
  documentCanBeSigned: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  no: Scalars['Int']['output'];
  noComputed: Scalars['String']['output'];
  projectLogo?: Maybe<Scalars['String']['output']>;
  signedByCustomer: Scalars['Boolean']['output'];
  status: OvertimeStatus;
};

export type PublicPartProject = {
  __typename?: 'PublicPartProject';
  id: Scalars['ID']['output'];
  /** Name of the project */
  name: Scalars['String']['output'];
  projectNumber: Scalars['ID']['output'];
  siv?: Maybe<PublicPartProjectSiv>;
};

export type PublicPartProjectSiv = {
  __typename?: 'PublicPartProjectSIV';
  cockpit: SivCockpit;
  id: Scalars['ID']['output'];
  recording: PublicPartProjectSivRecordingMeta;
};

export type PublicPartProjectSivRecordingMeta = {
  __typename?: 'PublicPartProjectSIVRecordingMeta';
  totalHourOfEmployees: Scalars['Float']['output'];
  totalHoursToBeDistributed: Scalars['Float']['output'];
};

export type Qsst = DocumentInterface & {
  __typename?: 'Qsst';
  addedBy: User;
  clientComments?: Maybe<Scalars['String']['output']>;
  clientName: Scalars['String']['output'];
  clientSignature?: Maybe<QsstClientSignature>;
  contractDate?: Maybe<Scalars['Date']['output']>;
  createdAt: Scalars['Date']['output'];
  hsContactInfo: QsstHsContactInfo;
  id: Scalars['ID']['output'];
  /** Logs of the qsst */
  logs?: Maybe<Logs>;
  manuallySignedPdfInfo?: Maybe<QsstPdfInfo>;
  name: Scalars['String']['output'];
  onsideEmployee: QsstOnsideEmployee;
  pdfInfo: QsstPdfInfo;
  project: Project;
  recommendationScore?: Maybe<Scalars['Int']['output']>;
  signedAt?: Maybe<Scalars['Date']['output']>;
  specificScore?: Maybe<QsstSpecificScore>;
  status: QsstStatus;
  updatedAt: Scalars['Date']['output'];
  updatedBy: User;
  viewerCanArchive: Scalars['Boolean']['output'];
  viewerCanAssign: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanRemoveUpload: Scalars['Boolean']['output'];
  viewerCanSign: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
  viewerCanUpload: Scalars['Boolean']['output'];
};


export type QsstLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QsstArchivedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstAssignedToProjectLog = Log & LogWithActor & LogWithDate & ProjectLog & QsstLog & {
  __typename?: 'QsstAssignedToProjectLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** Id of the project */
  projectId: Scalars['ID']['output'];
  /** name of the project */
  projectName: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstClientContact = {
  __typename?: 'QsstClientContact';
  city?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  streetName?: Maybe<Scalars['String']['output']>;
  streetNo?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type QsstClientSignature = {
  __typename?: 'QsstClientSignature';
  createdAt: Scalars['Date']['output'];
  fullName: Scalars['String']['output'];
  imagePath: Scalars['String']['output'];
};

export type QsstCreatedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstDeletedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstDownloadedLog = Log & LogWithDate & QsstLog & {
  __typename?: 'QsstDownloadedLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  qsstId: Scalars['ID']['output'];
};

export type QsstEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & QsstLog & {
  __typename?: 'QsstEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that qsst sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstEmailSentWithAttachmentLog = Log & LogWithActor & LogWithDate & LogWithEmail & QsstLog & {
  __typename?: 'QsstEmailSentWithAttachmentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that qsst sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstExternalLinkExpiredLog = Log & LogWithDate & QsstLog & {
  __typename?: 'QsstExternalLinkExpiredLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Expired date of the qsst external link in ISO-8601 format */
  expiredDate: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstForExternal = {
  __typename?: 'QsstForExternal';
  accessDueDate?: Maybe<Scalars['Date']['output']>;
  clientName: Scalars['String']['output'];
  hsContactInfo: QsstHsContactInfo;
  hsProjectNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pdfInfo?: Maybe<QsstPdfInfo>;
  projectAdditionalName?: Maybe<Scalars['String']['output']>;
  projectLogo?: Maybe<Scalars['String']['output']>;
  projectName?: Maybe<Scalars['String']['output']>;
  signedAt?: Maybe<Scalars['Date']['output']>;
};

export type QsstForExternalInput = {
  externalAuthToken?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/**
 * additional contact info of the hsEmployee
 * will be the info of the user that updated
 * the document the last time
 */
export type QsstHsContactInfo = {
  __typename?: 'QsstHSContactInfo';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type QsstLog = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstLogDownloadInput = {
  externalAuthToken?: InputMaybe<Scalars['String']['input']>;
  /** documentId */
  id: Scalars['ID']['input'];
};

export type QsstNotSignedLog = Log & LogWithDate & QsstLog & {
  __typename?: 'QsstNotSignedLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** The day count of that document not signed */
  dayCount?: Maybe<Scalars['Int']['output']>;
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstOnsideEmployee = {
  __typename?: 'QsstOnsideEmployee';
  email?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  phoneNo?: Maybe<Scalars['String']['output']>;
};

export type QsstPdfInfo = {
  __typename?: 'QsstPDFInfo';
  path?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  versionedUrl?: Maybe<Scalars['String']['output']>;
};

export type QsstSaveInput = {
  clientName: Scalars['String']['input'];
  contractDate?: InputMaybe<Scalars['Date']['input']>;
  /** documentId */
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  onsideEmployee: QsstSaveInputOnsideEmployee;
};

export type QsstSaveInputClientContact = {
  city?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  streetName?: InputMaybe<Scalars['String']['input']>;
  streetNo?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type QsstSaveInputOnsideEmployee = {
  email?: InputMaybe<Scalars['String']['input']>;
  fullName: Scalars['String']['input'];
  phoneNo?: InputMaybe<Scalars['String']['input']>;
};

export type QsstSaveInputSpecificScore = {
  cleanliness?: InputMaybe<Scalars['Int']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  service?: InputMaybe<Scalars['Int']['input']>;
  timeliness?: InputMaybe<Scalars['Int']['input']>;
};

export type QsstSendMailInput = {
  attachDocument?: InputMaybe<Scalars['Boolean']['input']>;
  body: Scalars['String']['input'];
  /** Carbon copies of the mail */
  ccs?: InputMaybe<Array<Scalars['String']['input']>>;
  /** documentId */
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type QsstSignInput = {
  clientComments: Scalars['String']['input'];
  clientSignatureFullName: Scalars['String']['input'];
  clientSignatureUpload: Scalars['Upload']['input'];
  externalAuthToken?: InputMaybe<Scalars['String']['input']>;
  /** documentId */
  id: Scalars['ID']['input'];
  recommendationScore: Scalars['Int']['input'];
  specificScore: QsstSaveInputSpecificScore;
};

export type QsstSignedLog = Log & LogWithDate & QsstLog & {
  __typename?: 'QsstSignedLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstSignedOnSideLog = Log & LogWithDate & QsstLog & {
  __typename?: 'QsstSignedOnSideLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the qsst */
  id: Scalars['ID']['output'];
  /** Name of the qsst */
  name: Scalars['String']['output'];
  /** ID of the qsst */
  qsstId: Scalars['ID']['output'];
};

export type QsstSpecificScore = {
  __typename?: 'QsstSpecificScore';
  cleanliness?: Maybe<Scalars['Int']['output']>;
  quality?: Maybe<Scalars['Int']['output']>;
  service?: Maybe<Scalars['Int']['output']>;
  timeliness?: Maybe<Scalars['Int']['output']>;
};

export enum QsstStatus {
  Archived = 'ARCHIVED',
  ManuallySigned = 'MANUALLY_SIGNED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export type QsstUpdatedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstUpdatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  qsstId: Scalars['ID']['output'];
};

export type QsstUploadPdfInput = {
  /** documentId */
  id: Scalars['ID']['input'];
  pdf: Scalars['Upload']['input'];
};

export type QsstUploadedDeletedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstUploadedDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  qsstId: Scalars['ID']['output'];
};

export type QsstUploadedLog = Log & LogWithActor & LogWithDate & QsstLog & {
  __typename?: 'QsstUploadedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the obstruction */
  id: Scalars['ID']['output'];
  /** Name of the obstruction */
  name: Scalars['String']['output'];
  /** ID of the obstruction */
  qsstId: Scalars['ID']['output'];
};

export type Quality = {
  __typename?: 'Quality';
  /** Description of quality */
  description: Scalars['String']['output'];
  /** Name of quality */
  name: Scalars['String']['output'];
};

export type QualitySaveInput = {
  /** Description of quality */
  description: Scalars['String']['input'];
  /** Name of quality */
  name: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** It returns the given acceptance report detail. */
  acceptanceReport: AcceptanceReport;
  acceptanceReportPdfPath: AcceptanceReportPdfPath;
  /** It returns the catalogue for the current user */
  catalogue?: Maybe<Catalogue>;
  /** It returns the all catalogues for user in name ascending format. */
  catalogues: Array<Catalogue>;
  /** It returns the given category detail. */
  category: Category;
  /** it returns the concern */
  concern?: Maybe<Concern>;
  /** It returns the given construction note detail. */
  constructionNote: ConstructionNote;
  /** It returns contact people of given project */
  contactPeople: Array<ContactPerson>;
  /** It returns a contact person by given id */
  contactPerson?: Maybe<ContactPerson>;
  customFile?: Maybe<CustomFile>;
  /** It returns the apps */
  driveApps: Array<DriveApp>;
  driveBulkJob?: Maybe<DriveBulkJob>;
  driveBulkJobs: Array<DriveBulkJob>;
  /** It returns document */
  driveDocument?: Maybe<DriveDocument>;
  /** Get next suggested number for a drive document */
  driveDocumentNextSuggestedNumber: Scalars['Int']['output'];
  /** It returns documents */
  driveDocuments: Array<DriveDocument>;
  /** It returns the given folder detail. */
  driveFolder?: Maybe<DriveFolder>;
  /** It returns flattened tree of folders */
  driveFolders: Array<DriveFolder>;
  /** It returns e-mail suggestions for a project. */
  emailSuggestions: EmailSuggestions;
  employeesForProject: Array<Employee>;
  /** It returns the equipments */
  equipments: Array<Equipment>;
  externalOffers: Array<ExternalOffer>;
  /** It returns the given folder detail. */
  folder: Folder;
  /** It returns the folders for the given offer. */
  folders: Array<Folder>;
  getPspdfkitAuthToken: GetPspdfkitAuthTokenResponse;
  heartbeat: Scalars['String']['output'];
  /** Returns the list of supported languages. */
  languages: Array<Language>;
  /** It returns the available categories for given folder and offer. */
  listAvailableCategoriesForFolderCreation: Array<Category>;
  /** Get logs for given project */
  logs: Logs;
  /** It returns the given obstruction detail. */
  obstruction?: Maybe<Obstruction>;
  /** It returns the given offer detail. */
  offer: Offer;
  /** It returns the given operation detail. */
  operation: Operation;
  overtime: Overtime;
  overtimeNextSuggestedNumber: Scalars['Int']['output'];
  overtimePdfPath: OvertimePdfPath;
  /** It returns the precaution categories */
  precautionCategories: Array<PrecautionCategory>;
  /** Returns the list of professions. */
  professions: Array<Scalars['String']['output']>;
  /** It returns the given project detail. */
  project: Project;
  /** It returns the given project detail. */
  projectByHsProjectNumber: Project;
  /** It returns the all available projects for the current user. */
  projects: Projects;
  publicAcceptanceReport?: Maybe<PublicAcceptanceReport>;
  publicOvertime?: Maybe<PublicOvertime>;
  qsst?: Maybe<Qsst>;
  qsstForExternal?: Maybe<QsstForExternal>;
  qsstGenerateExternalAuthToken: ExternalAuthToken;
  qssts: Array<Qsst>;
  /** Returns regional security managers */
  regionalSecurityManagers: Array<RegionalSecurityManager>;
  /** It returns the given risk assessment */
  riskAssessment: RiskAssessment;
  /** It returns the available projects for sap transmission. */
  sapTransmissionProjects: SapTransmissionProjects;
  /** It returns the projects from HS projects by given term. */
  searchHsProjects: SearchHsProjectsResult;
  siv?: Maybe<Siv>;
  sivBillingAttachment?: Maybe<SivBillingAttachment>;
  sivEmployees: Array<SivhsEmployee>;
  sivExternalOfferDiff: SivExternalOfferDiff;
  sivGroup?: Maybe<SivGroup>;
  sivOperation?: Maybe<SivOperation>;
  sivOperationSplitOperations: Array<SivOperation>;
  sivOperationSplitSystemTemplates: Array<SivOperationSplitSystemTemplate>;
  sivSubcontractor?: Maybe<SivSubcontractor>;
  /** It returns 5 most used folder names for given offer. */
  suggestedFolderNames: Array<Scalars['String']['output']>;
  timeTrackingCountsForProfitCenter: Array<TimeTrackingCount>;
  timeTrackingCountsForProject: Array<TimeTrackingCount>;
  timeTrackingIndicators: Array<TimeTrackingIndicatorWithDescription>;
  timeTrackingProject: TimeTrackingWeekYearProject;
  timeTrackingProjectsInRange: Array<TimeTrackingWeekYearProject>;
  timeTrackingSAPEmployees: Array<TimeTrackingWeekYearSapEmployee>;
  /** It returns the given user detail. */
  user: User;
  /** It returns the current user. */
  viewer: Viewer;
};


export type QueryAcceptanceReportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAcceptanceReportPdfPathArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCatalogueArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConcernArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConstructionNoteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactPeopleArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryContactPersonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDriveBulkJobArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDriveDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDriveDocumentNextSuggestedNumberArgs = {
  projectId: Scalars['ID']['input'];
  type: NumerableDocumentType;
};


export type QueryDriveDocumentsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryDriveFolderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDriveFoldersArgs = {
  projectId: Scalars['ID']['input'];
  sorting?: InputMaybe<DriveFolderSortingInput>;
  viewPreferences?: InputMaybe<DriveViewPreferencesInput>;
};


export type QueryEmailSuggestionsArgs = {
  project: Scalars['ID']['input'];
};


export type QueryEmployeesForProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEquipmentsArgs = {
  precautions?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryExternalOffersArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryFolderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFoldersArgs = {
  offer: Scalars['ID']['input'];
};


export type QueryGetPspdfkitAuthTokenArgs = {
  input: GetPspdfkitAuthTokenInput;
};


export type QueryListAvailableCategoriesForFolderCreationArgs = {
  offer: Scalars['ID']['input'];
  parent?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ID']['input'];
};


export type QueryObstructionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOfferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOperationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOvertimeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOvertimeNextSuggestedNumberArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryOvertimePdfPathArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectByHsProjectNumberArgs = {
  hsProjectNumber: Scalars['ID']['input'];
};


export type QueryProjectsArgs = {
  excludeDraft?: InputMaybe<Scalars['Boolean']['input']>;
  excludeStatus?: InputMaybe<Array<ProjectStatus>>;
  includeStatus?: InputMaybe<Array<ProjectStatus>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyFollowed?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  profitCenters?: InputMaybe<Array<Scalars['ID']['input']>>;
  sorting?: InputMaybe<ProjectSorting>;
  status?: InputMaybe<Array<ProjectStatus>>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPublicAcceptanceReportArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPublicOvertimeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQsstArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQsstForExternalArgs = {
  input: QsstForExternalInput;
};


export type QueryQsstGenerateExternalAuthTokenArgs = {
  qsstIds: Array<Scalars['ID']['input']>;
};


export type QueryQsstsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryRiskAssessmentArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySapTransmissionProjectsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  profitCenters?: InputMaybe<Array<Scalars['ID']['input']>>;
  statuses?: InputMaybe<Array<ProjectStatus>>;
};


export type QuerySearchHsProjectsArgs = {
  continuationToken?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  term: Scalars['String']['input'];
};


export type QuerySivArgs = {
  sivId: Scalars['ID']['input'];
};


export type QuerySivBillingAttachmentArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySivEmployeesArgs = {
  sivId: Scalars['ID']['input'];
};


export type QuerySivExternalOfferDiffArgs = {
  externalOfferId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
};


export type QuerySivGroupArgs = {
  groupId: Scalars['ID']['input'];
};


export type QuerySivOperationArgs = {
  operationId: Scalars['ID']['input'];
};


export type QuerySivOperationSplitOperationsArgs = {
  operationId: Scalars['ID']['input'];
};


export type QuerySivSubcontractorArgs = {
  subcontractorId: Scalars['ID']['input'];
};


export type QuerySuggestedFolderNamesArgs = {
  offer: Scalars['ID']['input'];
};


export type QueryTimeTrackingCountsForProfitCenterArgs = {
  endDate: Scalars['Date']['input'];
  profitCenterId: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
};


export type QueryTimeTrackingCountsForProjectArgs = {
  endDate: Scalars['Date']['input'];
  projectId: Scalars['ID']['input'];
  startDate: Scalars['Date']['input'];
};


export type QueryTimeTrackingProjectArgs = {
  projectId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};


export type QueryTimeTrackingProjectsInRangeArgs = {
  endWeek: TimeTrackingWeekRangeInput;
  projectId: Scalars['ID']['input'];
  startWeek: TimeTrackingWeekRangeInput;
};


export type QueryTimeTrackingSapEmployeesArgs = {
  profitCenterId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

/** RegionalSecurityManager is the return type for regionalSecurityManagers query */
export type RegionalSecurityManager = {
  __typename?: 'RegionalSecurityManager';
  /** Full name of the in charge */
  fullName: Scalars['String']['output'];
  /** Phone of the in charge */
  phone: Scalars['String']['output'];
};

export type RemovableInterface = {
  id: Scalars['ID']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
};

export type RemoveCatalogueInput = {
  /** Id of the catalogue. */
  id: Scalars['ID']['input'];
};

export type RemoveFolderInput = {
  /** Id of the folder. */
  id: Scalars['ID']['input'];
};

export type RemoveFolderOperationInput = {
  /** Id of the folder. */
  id: Scalars['ID']['input'];
};

export type RemoveManuallyUploadedAcceptanceReportInput = {
  /** Id of the acceptance report. */
  acceptanceReportId: Scalars['ID']['input'];
};

export type RemoveManuallyUploadedOvertimeInput = {
  overtime: Scalars['ID']['input'];
};

/** Response for a remove action. */
export type RemoveResult = {
  __typename?: 'RemoveResult';
  /** Status of the action. */
  status: Scalars['Boolean']['output'];
};

export type RetrieveTokensInput = {
  /** The refresh token */
  refreshToken: Scalars['String']['input'];
};

/** Project is the type for projects. */
export type RetrieveTokensResult = {
  __typename?: 'RetrieveTokensResult';
  /** The new access token */
  accessToken: Scalars['String']['output'];
  /** The new refresh token */
  refreshToken: Scalars['String']['output'];
  /** The currently authenticated user */
  viewer: Viewer;
};

/** RiskAssessment is the type for risk assessment */
export type RiskAssessment = AssignableInterface & DocumentInterface & RemovableInterface & {
  __typename?: 'RiskAssessment';
  /** The user who is the owner of this risk assessment */
  addedBy: User;
  /** Name of the client for the risk assessment */
  clientName?: Maybe<Scalars['String']['output']>;
  /** Computed client name value for risk assessment */
  clientNameComputed?: Maybe<Scalars['String']['output']>;
  /** Date of the risk assessment */
  date: Scalars['Date']['output'];
  /** Description of the risk assessment */
  description: Scalars['String']['output'];
  /** Draft project number */
  draftProjectNumber?: Maybe<Scalars['String']['output']>;
  /** Equipments of the risk assessment */
  equipments: Array<Equipment>;
  /** ID of the risk assessment */
  id: Scalars['ID']['output'];
  /** In charges of the risk assessment */
  inCharges: Array<InCharge>;
  /** Logs of the risk assessment */
  logs?: Maybe<Logs>;
  /** Name of the risk assessment */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['Int']['output'];
  /**
   * Order date of the risk assessment
   * @deprecated This field deprecated. You should use date field instead of orderDate field.
   */
  orderDate?: Maybe<Scalars['Date']['output']>;
  /** Pdf path for the risk assessment if it has been generated before. */
  pdfPath?: Maybe<Scalars['String']['output']>;
  /** Precautions of the risk assessment */
  precautions: Array<Precaution>;
  /** Project of the risk assessment */
  project: Project;
  /** Computed project number for risk assessment */
  projectNumberComputed?: Maybe<Scalars['String']['output']>;
  /** Signatures of the risk assessment */
  signatures: Array<RiskAssessmentSignature>;
  /** Special Precautions of the risk assessment */
  specialPrecautions: Array<SpecialPrecaution>;
  /** Status of risk assessment */
  status: RiskAssessmentStatus;
  /** Update date of the risk assessment */
  updatedAt: Scalars['Date']['output'];
  /** Indicates whether the viewer can archive the risk assessment */
  viewerCanArchive: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can assign the risk assessment to a project */
  viewerCanAssign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can remove the risk assessment */
  viewerCanRemove: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can send pdf email of the risk assessment */
  viewerCanSendRiskAssessmentPDFEmail: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can sign the risk assessment */
  viewerCanSign: Scalars['Boolean']['output'];
  /** Indicates whether the viewer can update the risk assessment */
  viewerCanUpdate: Scalars['Boolean']['output'];
};


/** RiskAssessment is the type for risk assessment */
export type RiskAssessmentLogsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type RiskAssessmentArchivedLog = Log & LogWithActor & LogWithDate & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentArchivedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentCreatedLog = Log & LogWithActor & LogWithDate & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentCreatedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentDeletedLog = Log & LogWithActor & LogWithDate & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentDeletedLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentEmailSentLog = Log & LogWithActor & LogWithDate & LogWithEmail & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentEmailSentLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** E-mails that overtime sent */
  emails: Array<Scalars['String']['output']>;
  /** ID of the risk assessment */
  id: Scalars['ID']['output'];
  /** Name of the risk assessment */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentInChargeSaveInput = {
  /** Full name of the in charge. */
  fullName: Scalars['String']['input'];
  /** Phone of the in charge. */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Role of the in charge */
  role: InChargeRole;
};

export type RiskAssessmentLog = {
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the log */
  id: Scalars['ID']['output'];
  /** Name of the risk assessment */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentSaveInput = {
  /** Name of the client */
  clientName?: InputMaybe<Scalars['String']['input']>;
  /** Date of the risk assessment. */
  date: Scalars['Date']['input'];
  /** Description of the risk assessment. */
  description: Scalars['String']['input'];
  /** Document ID of the risk assessment. */
  documentId: Scalars['ID']['input'];
  /** Draft project number. It can be applied only for draft projects */
  draftProjectNumber?: InputMaybe<Scalars['String']['input']>;
  /** Equipments of the risk assessment. */
  equipmentIds: Array<Scalars['String']['input']>;
  /** In charges of the risk assessment. */
  inCharges: Array<RiskAssessmentInChargeSaveInput>;
  /** Precautions of the risk assessment. */
  selectedPrecautions: Array<Scalars['String']['input']>;
  /** Special precautions of the risk assessment. */
  specialPrecautions: Array<RiskAssessmentSpecialPrecautionSaveInput>;
};

/** RiskAssessmentSignature is the type for risk assessment signatures. */
export type RiskAssessmentSignature = SignatureInterface & {
  __typename?: 'RiskAssessmentSignature';
  /** Creation date of the signature */
  createdAt: Scalars['Date']['output'];
  /** Full name of the signatory */
  fullName: Scalars['String']['output'];
  /** ID of the signature */
  id: Scalars['ID']['output'];
  /** Image path of the signature */
  imagePath: Scalars['String']['output'];
  /** RiskAssessment of the signature */
  riskAssessment: RiskAssessment;
  /** Type of the signature */
  type: RiskAssessmentSignatureType;
};

/** Risk Assessment Signature type enum */
export enum RiskAssessmentSignatureType {
  Employee = 'EMPLOYEE',
  Manager = 'MANAGER'
}

export type RiskAssessmentSignedByCreatorLog = Log & LogWithActor & LogWithDate & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentSignedByCreatorLog';
  /** First name of the actor who did this action */
  actorFirstName: Scalars['String']['output'];
  /** ID of the actor who did this action */
  actorId: Scalars['String']['output'];
  /** Last name of the actor who did this action */
  actorLastName: Scalars['String']['output'];
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentSignedByEmployeeLog = Log & LogWithDate & RiskAssessmentLog & {
  __typename?: 'RiskAssessmentSignedByEmployeeLog';
  /** The date this event has been happened in ISO-8601 format */
  date: Scalars['Date']['output'];
  /** Name of the signee of the risk assessment */
  employeeName: Scalars['String']['output'];
  /** ID of the overtime */
  id: Scalars['ID']['output'];
  /** Name of the overtime */
  name: Scalars['String']['output'];
  /** No of the risk assessment */
  no: Scalars['String']['output'];
  /** ID of the risk assessment */
  riskAssessmentId: Scalars['ID']['output'];
};

export type RiskAssessmentSpecialPrecautionSaveInput = {
  /** Id of the precaution category. */
  precautionCategoryId: Scalars['ID']['input'];
  /** Title of the special precaution. */
  title: Scalars['String']['input'];
};

/** Risk assessment status enum */
export enum RiskAssessmentStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN',
  Sent = 'SENT',
  Signed = 'SIGNED'
}

export type Siv = {
  __typename?: 'SIV';
  cockpit: SivCockpit;
  createdAt: Scalars['Date']['output'];
  discount: SivDiscount;
  groups: Array<SivGroup>;
  hasBillableOperations: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated This field is deprecated. You should use cockpit.displayPreferences.collapsed field instead of isCollapsed field. */
  isCollapsed: Scalars['Boolean']['output'];
  operationSplitPreference: SivOperationSplitPreference;
  planning: SivPlanningMeta;
  project: Project;
  recording: SivRecordingMeta;
  /** @deprecated Use `scopes` from ViewerUserScopes */
  scopes: ViewerSivScopes;
  totalHourForecast: Scalars['Float']['output'];
  traineeProductivity: Scalars['Boolean']['output'];
  trainees: Array<SivhsTraineeEmployee>;
  updatedAt: Scalars['Date']['output'];
  viewerCanCreateGroup: Scalars['Boolean']['output'];
  viewerCanImport: Scalars['Boolean']['output'];
};

export type SivAdditionalOperationCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  sivId: Scalars['ID']['input'];
  unit?: InputMaybe<SivOperationUnit>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SivBillingAttachment = DocumentInterface & RemovableInterface & {
  __typename?: 'SIVBillingAttachment';
  addedBy: User;
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  documentNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pdfPath: Scalars['String']['output'];
  project: Project;
  status: SivBillingAttachmentStatus;
  viewerCanArchive: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
};

export type SivBillingAttachmentEmailSendInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  documentId: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SivBillingAttachmentOperationInput = {
  id: Scalars['ID']['input'];
  recordedMeasurement: Scalars['Float']['input'];
};

export type SivBillingAttachmentSaveInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  documentId: Scalars['ID']['input'];
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  operations: Array<SivBillingAttachmentOperationInput>;
};

export enum SivBillingAttachmentStatus {
  Archived = 'ARCHIVED',
  Open = 'OPEN',
  Sent = 'SENT'
}

export type SivCockpit = {
  __typename?: 'SIVCockpit';
  /** Values are inside the cockpit based on this selection */
  displayPreferences: SivCockpitDisplayPreferences;
  /**
   * Calculated by:
   *   total of planned performance discounted – total of planned costs
   */
  plannedProfit: SivCockpitProfit;
  /**
   * Calculated by:
   *   total of recorded hours – (recorded mass / LW planned)
   */
  plusMinusHours: Scalars['Float']['output'];
  /**
   * Calculated by:
   *   total of recorded performance discounted – total of recorded costs
   */
  recordedProfit: SivCockpitProfit;
  totalCost: SivCockpitTotalCost;
  /**
   * Total recorded employee costs calculated by:
   *   total recorded hours * (hourly wage * (1 + overhead rate) + hourly surcharge)
   *
   * Total planned employee costs calculated by:
   *   (average hourly cost + hourly surcharge) * total planned hours
   */
  totalEmployeeCost: SivCockpitTotalCost;
  /**
   * Calculated by:
   *   (planningTotalMeasurement - recordingTotalMeasurement) / recordingPerformanceValue + recordingHour
   */
  totalHourForecast: Scalars['Float']['output'];
  totalHourOfEmployees: Scalars['Float']['output'];
  /**
   * Total of recorded hours, that have not yet been assigned to any performance
   * Calculated by:
   *   totalHourOfEmployees - totalUnproductiveHours - recordingTotalHour
   */
  totalHoursToBeDistributed: Scalars['Float']['output'];
  totalMaterialCost: SivCockpitTotalCost;
  /**
   * Calculated by:
   *   operationTotalCost += totalMeasurement * operation.unitPrice
   *   operationTotal * discount
   */
  totalPerformanceCost: SivCockpitPerformanceTotalCost;
  totalPlannedHours: Scalars['Float']['output'];
  totalSubcontractorCost: SivCockpitTotalCost;
};

export type SivCockpitDisplayPreferences = {
  __typename?: 'SIVCockpitDisplayPreferences';
  collapsed: Scalars['Boolean']['output'];
  unit: SivCockpitDisplayUnit;
};

export type SivCockpitDisplayPreferencesUpdateInput = {
  collapsed?: InputMaybe<Scalars['Boolean']['input']>;
  sivId: Scalars['ID']['input'];
  unit?: InputMaybe<SivCockpitDisplayUnit>;
};

export enum SivCockpitDisplayUnit {
  Eur = 'EUR',
  Percentage = 'PERCENTAGE'
}

export type SivCockpitPerformanceTotalCost = {
  __typename?: 'SIVCockpitPerformanceTotalCost';
  percentage: Scalars['Float']['output'];
  /** planned value with discount applied */
  planned: Scalars['Float']['output'];
  plannedRaw: Scalars['Float']['output'];
  recorded: Scalars['Float']['output'];
};

export type SivCockpitProfit = {
  __typename?: 'SIVCockpitProfit';
  monetary: Scalars['Float']['output'];
  percentage: Scalars['Float']['output'];
};

export type SivCockpitTotalCost = {
  __typename?: 'SIVCockpitTotalCost';
  percentage: Scalars['Float']['output'];
  planned: Scalars['Float']['output'];
  recorded: Scalars['Float']['output'];
};

export type SivCollapseToggleInput = {
  sivId: Scalars['ID']['input'];
};

export type SivDiscount = {
  __typename?: 'SIVDiscount';
  active: Scalars['Boolean']['output'];
  /** Discount percentage for SIV */
  amount?: Maybe<Scalars['Float']['output']>;
};

export type SivDiscountToggleInput = {
  sivId: Scalars['ID']['input'];
};

export type SivDiscountUpdateInput = {
  discount?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivEmployee = {
  hour?: Maybe<Scalars['Float']['output']>;
  /** If the employee has experience level, we'll use that along with the id. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
  wage?: Maybe<Scalars['Float']['output']>;
};

export enum SivEmployeeExperienceLevel {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3'
}

export type SivEmployeeProductivityToggleInput = {
  sivId: Scalars['ID']['input'];
};

export type SivEmployees = {
  __typename?: 'SIVEmployees';
  freelanceEmployees: Array<SivFreelanceEmployee>;
  hsEmployees: Array<SivhsEmployee>;
};

export type SivExternalOfferDiff = {
  __typename?: 'SIVExternalOfferDiff';
  alreadyImported: Scalars['Boolean']['output'];
  externalOfferId: Scalars['ID']['output'];
  groupDiffs: Array<SivExternalOfferGroupDiff>;
  offerName: Scalars['String']['output'];
  operationDiffs: Array<SivExternalOfferOperationDiff>;
  sivId: Scalars['ID']['output'];
};

export type SivExternalOfferGroupDiff = {
  __typename?: 'SIVExternalOfferGroupDiff';
  externalOfferGroupId: Scalars['ID']['output'];
  fieldDiffs: Array<SivExternalOfferGroupFieldDiff>;
  groupId?: Maybe<Scalars['ID']['output']>;
  hasSplitOperations: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  no: Scalars['String']['output'];
  type: SivExternalOfferGroupDiffType;
};

export enum SivExternalOfferGroupDiffType {
  Added = 'ADDED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

export enum SivExternalOfferGroupField {
  Name = 'NAME',
  No = 'NO'
}

export type SivExternalOfferGroupFieldDiff = {
  __typename?: 'SIVExternalOfferGroupFieldDiff';
  field: SivExternalOfferGroupField;
  oldValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SivExternalOfferOperationDiff = {
  __typename?: 'SIVExternalOfferOperationDiff';
  externalOfferOperationId: Scalars['ID']['output'];
  fieldDiffs: Array<SivExternalOfferOperationFieldDiff>;
  hasRecordingData: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  no: Scalars['String']['output'];
  operationId?: Maybe<Scalars['ID']['output']>;
  split: Scalars['Boolean']['output'];
  type: SivExternalOfferOperationDiffType;
};

export enum SivExternalOfferOperationDiffType {
  Added = 'ADDED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

export enum SivExternalOfferOperationField {
  ChargePerMinute = 'CHARGE_PER_MINUTE',
  Description = 'DESCRIPTION',
  Material = 'MATERIAL',
  Name = 'NAME',
  No = 'NO',
  PerformanceValue = 'PERFORMANCE_VALUE',
  PlanningTotalMeasurement = 'PLANNING_TOTAL_MEASUREMENT',
  UnitPrice = 'UNIT_PRICE'
}

export type SivExternalOfferOperationFieldDiff = {
  __typename?: 'SIVExternalOfferOperationFieldDiff';
  field: SivExternalOfferOperationField;
  oldValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SivFieldHistoryRecord = {
  __typename?: 'SIVFieldHistoryRecord';
  actor: User;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SivFreelanceEmployee = {
  __typename?: 'SIVFreelanceEmployee';
  hour?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
};

export type SivFreelanceEmployeeInput = {
  name: Scalars['String']['input'];
};

export type SivGroup = {
  __typename?: 'SIVGroup';
  /** @deprecated This field is deprecated. You should use no field instead of computedNo field. */
  computedNo: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  depth: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isCollapsed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** After free numbering changes, this field will treat as computedNo */
  no: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  operations: Array<SivOperation>;
  parentGroup?: Maybe<SivGroup>;
  planning: SivGroupPlanningMeta;
  recording: SivGroupRecordingMeta;
  siv: Siv;
  sortIndex: Scalars['Int']['output'];
  type: SivGroupType;
  updatedAt: Scalars['Date']['output'];
  viewerCanCreateGroup: Scalars['Boolean']['output'];
  viewerCanCreateOperation: Scalars['Boolean']['output'];
  viewerCanDuplicate: Scalars['Boolean']['output'];
  viewerCanMove: Scalars['Boolean']['output'];
  viewerCanRemoveGroup: Scalars['Boolean']['output'];
};


export type SivGroupOperationsArgs = {
  filter?: InputMaybe<OperationFilter>;
};

export type SivGroupCreateInput = {
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  parentGroupId?: InputMaybe<Scalars['ID']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivGroupDuplicateInput = {
  groupIds: Array<Scalars['ID']['input']>;
};

export type SivGroupMoveInput = {
  groupIds: Array<Scalars['ID']['input']>;
  targetGroupId: Scalars['ID']['input'];
};

export type SivGroupPlanningMeta = {
  __typename?: 'SIVGroupPlanningMeta';
  calculatedFlatRate?: Maybe<Scalars['Float']['output']>;
  flatRate?: Maybe<Scalars['Float']['output']>;
  totalCostByMeasurement: Scalars['Float']['output'];
  totalHour: Scalars['Float']['output'];
  totalMaterial: Scalars['Float']['output'];
  totalMaterialCost: Scalars['Float']['output'];
};

export type SivGroupRecordingMeta = {
  __typename?: 'SIVGroupRecordingMeta';
  calculatedFlatRate?: Maybe<Scalars['Float']['output']>;
  flatRate?: Maybe<Scalars['Float']['output']>;
  totalCostByMeasurement?: Maybe<Scalars['Float']['output']>;
  totalHour: Scalars['Float']['output'];
  totalMaterial: Scalars['Float']['output'];
  totalMaterialCost: Scalars['Float']['output'];
};

export type SivGroupRemoveInput = {
  groupIds: Array<Scalars['ID']['input']>;
};

export type SivGroupSetIsCollapsedInput = {
  groupId: Scalars['ID']['input'];
  isCollapsed: Scalars['Boolean']['input'];
};

export type SivGroupSortIndexUpdateInput = {
  groupIds: Array<Scalars['ID']['input']>;
  targetSortIndex: Scalars['Int']['input'];
};

export type SivGroupTransferRecordingValuesInput = {
  groupId: Scalars['ID']['input'];
};

export enum SivGroupType {
  Additional = 'ADDITIONAL',
  Regular = 'REGULAR'
}

export type SivGroupUpdateInput = {
  groupId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};

export type SivhsEmployee = SivEmployee & {
  __typename?: 'SIVHSEmployee';
  hour?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  role: Scalars['String']['output'];
  wage?: Maybe<Scalars['Float']['output']>;
};

export type SivhsTraineeEmployee = SivEmployee & {
  __typename?: 'SIVHSTraineeEmployee';
  experienceLevel?: Maybe<SivEmployeeExperienceLevel>;
  hour?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  productiveHours?: Maybe<Scalars['Float']['output']>;
  productivityRatio?: Maybe<Scalars['Float']['output']>;
  role: Scalars['String']['output'];
  unproductiveHours?: Maybe<Scalars['Float']['output']>;
  wage?: Maybe<Scalars['Float']['output']>;
};

export type SivImportFromExternalOfferInput = {
  externalOfferId: Scalars['ID']['input'];
  ignorePerformanceValueAndChargePerMinute: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
};

export type SivImportFromOfferInput = {
  offerId: Scalars['ID']['input'];
};

export type SivKeyDataSapTransmitInput = {
  projectIds: Array<Scalars['ID']['input']>;
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export type SivKeyDataSapTransmitResult = {
  __typename?: 'SIVKeyDataSapTransmitResult';
  completedTransmissions: Array<Project>;
  failedTransmissions: Array<Project>;
  status: SivKeyDataSapTransmitStatus;
};

export enum SivKeyDataSapTransmitStatus {
  Failed = 'FAILED',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  PartialSuccess = 'PARTIAL_SUCCESS',
  Success = 'SUCCESS'
}

export type SivlvCopyInput = {
  fromProjectId: Scalars['ID']['input'];
  toProjectId: Scalars['ID']['input'];
};

export type SivOperation = {
  __typename?: 'SIVOperation';
  alternatives: Array<SivOperation>;
  /** @deprecated This field is deprecated. You should use no field instead of computedNo field. */
  computedNo: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  depth: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  group: SivGroup;
  id: Scalars['ID']['output'];
  main?: Maybe<SivOperation>;
  measurements: Array<SivOperationMeasurement>;
  name?: Maybe<Scalars['String']['output']>;
  /** After free numbering changes, this field will treat as computedNo */
  no: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  pinned: Scalars['Boolean']['output'];
  planning: SivOperationPlanningMeta;
  recording: SivOperationRecordingMeta;
  sortIndex: Scalars['Int']['output'];
  split: Scalars['Boolean']['output'];
  splitOrigin?: Maybe<SivOperation>;
  type: SivOperationType;
  unit?: Maybe<SivOperationUnit>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Date']['output'];
  viewerCanDuplicate: Scalars['Boolean']['output'];
  viewerCanMove: Scalars['Boolean']['output'];
  viewerCanRemoveOperation: Scalars['Boolean']['output'];
};

export type SivOperationConvertToRegularInput = {
  operationId: Scalars['ID']['input'];
};

export type SivOperationCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['ID']['input'];
  measurements: Array<SivOperationMeasurementInput>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<SivOperationUnit>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SivOperationDeleteInput = {
  id: Scalars['ID']['input'];
};

export type SivOperationDetachInput = {
  operationId: Scalars['ID']['input'];
};

export type SivOperationDuplicateInput = {
  operationIds: Array<Scalars['ID']['input']>;
};

export type SivOperationMeasurement = {
  __typename?: 'SIVOperationMeasurement';
  meta: Array<Scalars['String']['output']>;
  multiplier: Scalars['String']['output'];
  name: Scalars['String']['output'];
  operation: SivOperation;
  subtraction: Scalars['Boolean']['output'];
  totalMeta?: Maybe<Scalars['String']['output']>;
};

export type SivOperationMeasurementInput = {
  meta: Array<Scalars['String']['input']>;
  multiplier: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subtraction: Scalars['Boolean']['input'];
  totalMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SivOperationMoveInput = {
  operationIds: Array<Scalars['ID']['input']>;
  targetGroupId: Scalars['ID']['input'];
};

export type SivOperationPerformanceRecordingBatchUpdateHourInput = {
  hour?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  hourMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SivOperationPerformanceRecordingBatchUpdateInput = {
  hour?: InputMaybe<SivOperationPerformanceRecordingBatchUpdateHourInput>;
  operationId: Scalars['ID']['input'];
  totalMeasurement?: InputMaybe<SivOperationPerformanceRecordingBatchUpdateTotalMeasurementInput>;
};

export type SivOperationPerformanceRecordingBatchUpdateTotalMeasurementInput = {
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SivOperationPinToggleInput = {
  operationId: Scalars['ID']['input'];
};

export type SivOperationPlanningChargePerMinuteUpdateInput = {
  chargePerMinute?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SivOperationPlanningHourUpdateInput = {
  hour?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SivOperationPlanningMaterialUpdateInput = {
  material?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SivOperationPlanningMeta = {
  __typename?: 'SIVOperationPlanningMeta';
  calculatedMaterial?: Maybe<Scalars['Float']['output']>;
  chargePerMinute?: Maybe<Scalars['Float']['output']>;
  hour?: Maybe<Scalars['Float']['output']>;
  material?: Maybe<Scalars['Float']['output']>;
  performanceValue?: Maybe<Scalars['Float']['output']>;
  totalMeasurement?: Maybe<Scalars['Float']['output']>;
  totalMeasurementMeta?: Maybe<Scalars['String']['output']>;
};

export type SivOperationPlanningPerformanceValueUpdateInput = {
  operationId: Scalars['ID']['input'];
  performanceValue?: InputMaybe<Scalars['Float']['input']>;
};

export type SivOperationRecordingHourUpdateInput = {
  hour?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  hourMeta?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SivOperationRecordingMaterialUpdateInput = {
  material?: InputMaybe<Scalars['Float']['input']>;
  operationId: Scalars['ID']['input'];
};

export type SivOperationRecordingMeta = {
  __typename?: 'SIVOperationRecordingMeta';
  calculatedMaterial?: Maybe<Scalars['Float']['output']>;
  chargePerMinute?: Maybe<Scalars['Float']['output']>;
  hour?: Maybe<Scalars['Float']['output']>;
  hourHistory: Array<SivFieldHistoryRecord>;
  material?: Maybe<Scalars['Float']['output']>;
  materialCost: Scalars['Float']['output'];
  performanceValue?: Maybe<Scalars['Float']['output']>;
  totalMeasurement?: Maybe<Scalars['Float']['output']>;
  totalMeasurementHistory: Array<SivFieldHistoryRecord>;
};

export type SivOperationRecordingTotalMeasurementUpdateInput = {
  operationId: Scalars['ID']['input'];
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  /** Please use commas for decimals. */
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
};

export type SivOperationRemoveInput = {
  operationIds: Array<Scalars['ID']['input']>;
};

export type SivOperationSortIndexUpdateInput = {
  operationIds: Array<Scalars['ID']['input']>;
  targetSortIndex: Scalars['Int']['input'];
};

export type SivOperationSplitField = {
  __typename?: 'SIVOperationSplitField';
  percentage?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type SivOperationSplitInsertInput = {
  chargePerMinute?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export enum SivOperationSplitPreference {
  Custom = 'CUSTOM',
  Template = 'TEMPLATE'
}

export type SivOperationSplitPreferenceUpdateInput = {
  operationSplitPreference: SivOperationSplitPreference;
  sivId: Scalars['ID']['input'];
};

export type SivOperationSplitSystemTemplate = SivOperationSplitTemplate & {
  __typename?: 'SIVOperationSplitSystemTemplate';
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  splits: Array<SivOperationSplitTemplateValues>;
  templateNo: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type SivOperationSplitTemplate = {
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  splits: Array<SivOperationSplitTemplateValues>;
  templateNo: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type SivOperationSplitTemplateValues = {
  __typename?: 'SIVOperationSplitTemplateValues';
  chargePerMinute: SivOperationSplitField;
  name: Scalars['String']['output'];
  unitPrice: SivOperationSplitField;
};

export type SivOperationSplitUpdateInput = {
  chargePerMinute?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  no?: InputMaybe<Scalars['String']['input']>;
  totalMeasurement?: InputMaybe<Scalars['Float']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SivOperationSplitsInput = {
  deletes: Array<SivOperationDeleteInput>;
  inserts: Array<SivOperationSplitInsertInput>;
  restored: Scalars['Boolean']['input'];
  saveAsTemplate: Scalars['Boolean']['input'];
  templateName?: InputMaybe<Scalars['String']['input']>;
  updates: Array<SivOperationSplitUpdateInput>;
};

export enum SivOperationType {
  Additional = 'ADDITIONAL',
  Alternative = 'ALTERNATIVE',
  Main = 'MAIN',
  Optional = 'OPTIONAL',
  Regular = 'REGULAR'
}

export enum SivOperationUnit {
  Centimeter = 'CENTIMETER',
  CubicCentimeter = 'CUBIC_CENTIMETER',
  CubicMeter = 'CUBIC_METER',
  Day = 'DAY',
  Decimeter = 'DECIMETER',
  Eur = 'EUR',
  Fixed = 'FIXED',
  FlatRate = 'FLAT_RATE',
  Gram = 'GRAM',
  Hour = 'HOUR',
  Kilogram = 'KILOGRAM',
  Kilometer = 'KILOMETER',
  KilowattHour = 'KILOWATT_HOUR',
  Liter = 'LITER',
  Meter = 'METER',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Quantity = 'QUANTITY',
  SquareCentimeter = 'SQUARE_CENTIMETER',
  SquareDecimeter = 'SQUARE_DECIMETER',
  SquareMeter = 'SQUARE_METER',
  SquareMeterWeek = 'SQUARE_METER_WEEK',
  Ton = 'TON',
  UnitOfAccount = 'UNIT_OF_ACCOUNT',
  Unknown = 'UNKNOWN',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type SivOperationUnitPriceUpdateInput = {
  operationId: Scalars['ID']['input'];
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SivOperationUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  measurements: Array<SivOperationMeasurementInput>;
  name: Scalars['String']['input'];
  no: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['ID']['input'];
  splits: SivOperationSplitsInput;
  totalMeasurementMeta?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<SivOperationUnit>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type SivPlanningCostSectionFinishInput = {
  sivId: Scalars['ID']['input'];
};

export type SivPlanningEmployeesSetInput = {
  employeeHSIds: Array<Scalars['ID']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivPlanningFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivPlanningFreelanceEmployeesSetInput = {
  employees: Array<SivFreelanceEmployeeInput>;
  sivId: Scalars['ID']['input'];
};

export type SivPlanningGroupFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  groupId: Scalars['ID']['input'];
};

export type SivPlanningMeta = {
  __typename?: 'SIVPlanningMeta';
  averageWage?: Maybe<Scalars['Float']['output']>;
  employees: SivEmployees;
  flatRate?: Maybe<Scalars['Float']['output']>;
  hourlyRateTemporaryWorker?: Maybe<Scalars['Float']['output']>;
  hourlySurcharge?: Maybe<Scalars['Float']['output']>;
  isCostSectionFinished: Scalars['Boolean']['output'];
  overheadRateTimeWorker?: Maybe<Scalars['Float']['output']>;
  overheadRateTrainee?: Maybe<Scalars['Float']['output']>;
  overheadRateWage?: Maybe<Scalars['Float']['output']>;
  subcontractors: Array<SivSubcontractor>;
  totalCost?: Maybe<Scalars['Float']['output']>;
  totalCostByMeasurement: Scalars['Float']['output'];
  totalHour: Scalars['Float']['output'];
  totalMaterial: Scalars['Float']['output'];
  totalMaterialCost: Scalars['Float']['output'];
};

export type SivPlanningSubcontractorCreateInput = {
  cashback?: InputMaybe<Scalars['Float']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  overhead?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
  totalOrderShare: Scalars['Float']['input'];
};

export type SivPlanningSubcontractorRemoveInput = {
  subcontractorId: Scalars['ID']['input'];
};

export type SivPlanningSubcontractorUpdateInput = {
  cashback?: InputMaybe<Scalars['Float']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  overhead?: InputMaybe<Scalars['Float']['input']>;
  subcontractorId: Scalars['ID']['input'];
  totalOrderShare: Scalars['Float']['input'];
};

export type SivPlanningUpdateInput = {
  averageWage?: InputMaybe<Scalars['Float']['input']>;
  hourlyRateTemporaryWorker?: InputMaybe<Scalars['Float']['input']>;
  hourlySurcharge?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTimeWorker?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTrainee?: InputMaybe<Scalars['Float']['input']>;
  overheadRateWage?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivRecordingEmployeesSetInput = {
  employeeHSIds: Array<Scalars['ID']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivRecordingFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivRecordingFreelanceEmployeesSetInput = {
  employees: Array<SivFreelanceEmployeeInput>;
  sivId: Scalars['ID']['input'];
};

export type SivRecordingGroupFlatRateUpdateInput = {
  flatRate?: InputMaybe<Scalars['Float']['input']>;
  groupId: Scalars['ID']['input'];
};

export type SivRecordingMeta = {
  __typename?: 'SIVRecordingMeta';
  averageWage?: Maybe<Scalars['Float']['output']>;
  employees: SivEmployees;
  flatRate?: Maybe<Scalars['Float']['output']>;
  hourlyRateTemporaryWorker?: Maybe<Scalars['Float']['output']>;
  hourlySurcharge?: Maybe<Scalars['Float']['output']>;
  overheadRateTimeWorker?: Maybe<Scalars['Float']['output']>;
  overheadRateTrainee?: Maybe<Scalars['Float']['output']>;
  overheadRateWage?: Maybe<Scalars['Float']['output']>;
  subcontractors: Array<SivSubcontractor>;
  totalCost?: Maybe<Scalars['Float']['output']>;
  totalCostByMeasurement: Scalars['Float']['output'];
  totalHour: Scalars['Float']['output'];
  totalHourOfEmployees: Scalars['Float']['output'];
  totalHoursToBeDistributed: Scalars['Float']['output'];
  totalMaterial: Scalars['Float']['output'];
  totalMaterialCost: Scalars['Float']['output'];
};

export type SivRecordingSubcontractorInvoiceInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  surchargeRate?: InputMaybe<Scalars['Float']['input']>;
};

export type SivRecordingSubcontractorInvoicesSetInput = {
  invoices: Array<SivRecordingSubcontractorInvoiceInput>;
  subcontractorId: Scalars['ID']['input'];
};

export type SivRecordingUpdateInput = {
  averageWage?: InputMaybe<Scalars['Float']['input']>;
  hourlyRateTemporaryWorker?: InputMaybe<Scalars['Float']['input']>;
  hourlySurcharge?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTimeWorker?: InputMaybe<Scalars['Float']['input']>;
  overheadRateTrainee?: InputMaybe<Scalars['Float']['input']>;
  overheadRateWage?: InputMaybe<Scalars['Float']['input']>;
  sivId: Scalars['ID']['input'];
};

export type SivSubcontractor = {
  __typename?: 'SIVSubcontractor';
  cashPrice: Scalars['Float']['output'];
  cashback?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  invoices: Array<SivSubcontractorInvoice>;
  maximumInvoiceAmount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  overhead?: Maybe<Scalars['Float']['output']>;
  siv: Siv;
  totalOrderShare: Scalars['Float']['output'];
};

export type SivSubcontractorInvoice = {
  __typename?: 'SIVSubcontractorInvoice';
  amount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  surchargeRate?: Maybe<Scalars['Float']['output']>;
  totalInvoice: Scalars['Float']['output'];
};

export type SapTransmissionProject = {
  __typename?: 'SapTransmissionProject';
  /** Additional name of the project */
  additionalName?: Maybe<Scalars['String']['output']>;
  /** Date of the project */
  date?: Maybe<Scalars['Date']['output']>;
  /** Description of the project */
  description: Scalars['String']['output'];
  /** Heinrich-Schmid project number */
  hsProjectNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isSynced: Scalars['Boolean']['output'];
  /** Name of the project */
  name: Scalars['String']['output'];
  /** Profit Center of the Project that belongs to */
  profitCenter?: Maybe<ProfitCenter>;
  /** Project number of the project */
  projectNumber: Scalars['ID']['output'];
  sivKeyData: SapTransmissionSivKeyData;
};

/** SapTransmissionProjects payload */
export type SapTransmissionProjects = {
  __typename?: 'SapTransmissionProjects';
  /** Pagination meta data */
  pagination: Pagination;
  /** Projects for given arguments */
  projects: Array<SapTransmissionProject>;
  /** Filtered result meta data */
  selectedProfitCenters: Array<ProfitCenter>;
  /** Project counts by status */
  selectedStatuses: Array<ProjectsStatusFilterMeta>;
};

export type SapTransmissionSivKeyData = {
  __typename?: 'SapTransmissionSIVKeyData';
  planningTotalHour: Scalars['Float']['output'];
  recordingTotalCostByMeasurement: Scalars['Float']['output'];
  totalHourForecast: Scalars['Float']['output'];
};

export type SaveAnnotationsInput = {
  documentId: Scalars['ID']['input'];
};

/** SearchHsProjectsResult is the type for searchHsProjects. */
export type SearchHsProjectsResult = {
  __typename?: 'SearchHsProjectsResult';
  /** Token for next HsProject search results */
  continuationToken?: Maybe<Scalars['String']['output']>;
  /** HsProject search results */
  projects: Array<HsProject>;
};

export type SendAcceptanceReportEmailInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the acceptance report. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type SendAcceptanceReportExternalLinkEmailInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the acceptance report. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type SendConstructionNoteEmailInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the construction note. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type SendOfferEmailInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the offer. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type SendOvertimeEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SendOvertimeExternalLinkEmailInput = {
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  recipients: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type SendRiskAssessmentEmailInput = {
  /** Carbon copies of the mail */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Content of the email. */
  content: Scalars['String']['input'];
  /** Id of the risk assessment. */
  id: Scalars['ID']['input'];
  /** Recipients of the email. */
  recipients: Array<Scalars['String']['input']>;
  /** Subject of the email. */
  subject: Scalars['String']['input'];
};

export type SignatureInterface = {
  createdAt: Scalars['Date']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imagePath: Scalars['String']['output'];
};

export enum SignatureType {
  Customer = 'CUSTOMER',
  HeinrichSchmid = 'HEINRICH_SCHMID'
}

export type Sortable = {
  lastChangedDate: Scalars['Date']['output'];
  lastUsedDate?: Maybe<Scalars['Date']['output']>;
};

/** The sorting direction */
export enum SortingDirection {
  /** Lowest is the first */
  Asc = 'ASC',
  /** Greatest is the first */
  Desc = 'DESC'
}

/** SpecialPrecaution is the type for special precaution */
export type SpecialPrecaution = PrecautionInterface & {
  __typename?: 'SpecialPrecaution';
  /** ID of the special precaution */
  id: Scalars['ID']['output'];
  /** Precaution category of the special precaution */
  precautionCategory: PrecautionCategory;
  /** Risk assessment of the special precaution */
  riskAssessment: RiskAssessment;
  /** Title of the special precaution */
  title: Scalars['String']['output'];
};

export type SyncProjectInput = {
  /** ID of the project */
  id: Scalars['ID']['input'];
};

export type TimeTrackingAdditionalRowAddInput = {
  recordId: Scalars['ID']['input'];
};

export type TimeTrackingApprove = {
  __typename?: 'TimeTrackingApprove';
  approveType: TimeTrackingApproveType;
  approvedBy: User;
  createdAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  weekYearProject: TimeTrackingWeekYearProject;
};

export enum TimeTrackingApproveType {
  CommercialApprove = 'COMMERCIAL_APPROVE',
  TechnicalApprove = 'TECHNICAL_APPROVE'
}

export type TimeTrackingBatchDeleteOperationInput = {
  entryId: Scalars['ID']['input'];
  lastUpdatedAt: Scalars['Date']['input'];
  /** This id can be anything. It's not related with SIV or something. It will be returned back in the response to match corresponding operation/action on client side. */
  operationId: Scalars['ID']['input'];
};

export type TimeTrackingBatchEmployeeInput = {
  employeeType: TimeTrackingBatchEmployeeType;
  /** It must be provided if the type is NOT_FROM_SAP and TEMPORARY_WORKER */
  name?: InputMaybe<Scalars['String']['input']>;
  /** It must be provided if the type is SAP */
  sapId?: InputMaybe<Scalars['String']['input']>;
};

export enum TimeTrackingBatchEmployeeType {
  NotFromSap = 'NOT_FROM_SAP',
  Sap = 'SAP',
  TemporaryWorker = 'TEMPORARY_WORKER'
}

export type TimeTrackingBatchInsertOperationInput = {
  break?: InputMaybe<Scalars['Int']['input']>;
  date: Scalars['Date']['input'];
  employee: TimeTrackingBatchEmployeeInput;
  endTime?: InputMaybe<Scalars['Int']['input']>;
  indicator?: InputMaybe<TimeTrackingIndicator>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** This id can be anything. It's not related with SIV or something. It will be returned back in the response to match corresponding operation/action on client side. */
  operationId: Scalars['ID']['input'];
  projectId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeTrackingBatchOperationDeleteSuccessResponse = TimeTrackingBatchOperationResponse & {
  __typename?: 'TimeTrackingBatchOperationDeleteSuccessResponse';
  entryId: Scalars['ID']['output'];
  operationId: Scalars['ID']['output'];
};

export type TimeTrackingBatchOperationError = TimeTrackingBatchOperationForbiddenError | TimeTrackingBatchOperationOverlapError | TimeTrackingBatchOperationUnExpectedError | TimeTrackingBatchOperationValidationError;

export type TimeTrackingBatchOperationErrorResponse = TimeTrackingBatchOperationResponse & {
  __typename?: 'TimeTrackingBatchOperationErrorResponse';
  errors: Array<TimeTrackingBatchOperationError>;
  operationId: Scalars['ID']['output'];
};

export type TimeTrackingBatchOperationForbiddenError = {
  __typename?: 'TimeTrackingBatchOperationForbiddenError';
  /** It can be such as already approved error, stale last updated at error, no project access error etc. */
  reason: Scalars['String']['output'];
};

export type TimeTrackingBatchOperationInput = {
  deletes?: InputMaybe<Array<TimeTrackingBatchDeleteOperationInput>>;
  inserts?: InputMaybe<Array<TimeTrackingBatchInsertOperationInput>>;
  updates?: InputMaybe<Array<TimeTrackingBatchUpdateOperationInput>>;
};

export type TimeTrackingBatchOperationInsertSuccessResponse = TimeTrackingBatchOperationResponse & {
  __typename?: 'TimeTrackingBatchOperationInsertSuccessResponse';
  entry: TimeTrackingEntry;
  operationId: Scalars['ID']['output'];
};

export type TimeTrackingBatchOperationOverlapError = {
  __typename?: 'TimeTrackingBatchOperationOverlapError';
  overlaps: Array<TimeTrackingEntry>;
};

export type TimeTrackingBatchOperationResponse = {
  operationId: Scalars['ID']['output'];
};

export enum TimeTrackingBatchOperationType {
  Delete = 'DELETE',
  Insert = 'INSERT',
  Update = 'UPDATE'
}

export type TimeTrackingBatchOperationUnExpectedError = {
  __typename?: 'TimeTrackingBatchOperationUnExpectedError';
  message: Scalars['String']['output'];
};

export type TimeTrackingBatchOperationUpdateSuccessResponse = TimeTrackingBatchOperationResponse & {
  __typename?: 'TimeTrackingBatchOperationUpdateSuccessResponse';
  entry: TimeTrackingEntry;
  operationId: Scalars['ID']['output'];
};

export type TimeTrackingBatchOperationValidationError = {
  __typename?: 'TimeTrackingBatchOperationValidationError';
  field: TimeTrackingEntryField;
  message: Scalars['String']['output'];
};

export type TimeTrackingBatchUpdateOperationInput = {
  break?: InputMaybe<Scalars['Int']['input']>;
  endTime?: InputMaybe<Scalars['Int']['input']>;
  entryId: Scalars['ID']['input'];
  indicator?: InputMaybe<TimeTrackingIndicator>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** This id can be anything. It's not related with SIV or something. It will be returned back in the response to match corresponding operation/action on client side. */
  operationId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['Int']['input']>;
};

export type TimeTrackingCount = {
  __typename?: 'TimeTrackingCount';
  count: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
};

export type TimeTrackingEmployee = {
  name: Scalars['String']['output'];
};

export type TimeTrackingEmployeeAndEntry = {
  __typename?: 'TimeTrackingEmployeeAndEntry';
  employee: TimeTrackingEmployee;
  entry: TimeTrackingEntry;
  /** This id doesn't exist in reality. It will just identify the employee with random id */
  virtualEmployeeId: Scalars['ID']['output'];
};

export type TimeTrackingEntry = {
  __typename?: 'TimeTrackingEntry';
  /** Break in minutes */
  break?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  /** The day of the entry */
  date: Scalars['Date']['output'];
  /** The end time of the entry in minutes. As example, the 00:00 is 0, 09:00 is 540, 13:30 is 810 and so on. */
  endTime?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** Indicator of the entry */
  indicator?: Maybe<TimeTrackingIndicator>;
  /** Last SAP Transmission of this entry */
  lastSAPTransmission?: Maybe<TimeTrackingSapTransmission>;
  /** The latest revision list that have been made for this entry */
  latestRevisions: Array<TimeTrackingRevision>;
  /** The note of the entry */
  note?: Maybe<TimeTrackingNote>;
  /** The row that this entry belongs to */
  row: TimeTrackingRow;
  /** The starting time of the entry in minutes. As example, the 00:00 is 0, 09:00 is 540, 13:30 is 810 and so on. */
  startTime?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Date']['output'];
  viewerCanRemoveValues: Scalars['Boolean']['output'];
  viewerCanTransmitToSAP: Scalars['Boolean']['output'];
  viewerCanUpdate: Scalars['Boolean']['output'];
};

export enum TimeTrackingEntryField {
  Break = 'BREAK',
  EndTime = 'END_TIME',
  Indicator = 'INDICATOR',
  StartTime = 'START_TIME'
}

export type TimeTrackingEntryIndicatorSaveInput = {
  date: Scalars['Date']['input'];
  /** Send as null to remove an existing indicator */
  indicator?: InputMaybe<TimeTrackingIndicator>;
  rowId: Scalars['ID']['input'];
};

export type TimeTrackingEntryMultiRemoveInput = {
  entryIds: Array<Scalars['ID']['input']>;
};

export type TimeTrackingEntryNoteSaveInput = {
  /** Send as null to remove an existing note */
  content?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['Date']['input'];
  rowId: Scalars['ID']['input'];
};

export enum TimeTrackingEntryTimeField {
  Break = 'BREAK',
  EndTime = 'END_TIME',
  StartTime = 'START_TIME'
}

export type TimeTrackingEntryTimeMultiRemoveInput = {
  entryIds: Array<Scalars['ID']['input']>;
  field: TimeTrackingEntryTimeField;
};

export type TimeTrackingEntryTimeRemoveInput = {
  entryId: Scalars['ID']['input'];
  field: TimeTrackingEntryTimeField;
};

export type TimeTrackingEntryTimeSaveInput = {
  date: Scalars['Date']['input'];
  field: TimeTrackingEntryTimeField;
  rowId: Scalars['ID']['input'];
  value: Scalars['Int']['input'];
};

export type TimeTrackingEntryTimeSaveResult = {
  __typename?: 'TimeTrackingEntryTimeSaveResult';
  entries: Array<TimeTrackingEntry>;
  overlapped: Scalars['Boolean']['output'];
};

export enum TimeTrackingIndicator {
  A = 'A',
  A0 = 'A0',
  A1 = 'A1',
  A2 = 'A2',
  B = 'B',
  E = 'E',
  E5 = 'E5',
  E6 = 'E6',
  F = 'F',
  K = 'K',
  K2 = 'K2',
  K6 = 'K6',
  K9 = 'K9',
  O = 'O',
  O1 = 'O1',
  O3 = 'O3',
  O4 = 'O4',
  O5 = 'O5',
  O6 = 'O6',
  O7 = 'O7',
  P = 'P',
  Rb = 'RB',
  Rc = 'RC',
  Rd = 'RD',
  Re = 'RE',
  Rf = 'RF',
  Rg = 'RG',
  Rk = 'RK',
  Rl = 'RL',
  Rm = 'RM',
  Rn = 'RN',
  Ro = 'RO',
  Rp = 'RP',
  S = 'S',
  S1 = 'S1',
  S2 = 'S2',
  T = 'T',
  T8 = 'T8',
  T9 = 'T9',
  U = 'U',
  U1 = 'U1',
  U2 = 'U2',
  U3 = 'U3',
  U6 = 'U6',
  U7 = 'U7',
  V = 'V',
  V5 = 'V5',
  V9 = 'V9',
  W = 'W'
}

export type TimeTrackingIndicatorWithDescription = {
  __typename?: 'TimeTrackingIndicatorWithDescription';
  description: Scalars['String']['output'];
  indicator: TimeTrackingIndicator;
};

export type TimeTrackingNote = {
  __typename?: 'TimeTrackingNote';
  content: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
  user: User;
};

export type TimeTrackingProjectMultiApproveInput = {
  approveType: TimeTrackingApproveType;
  projectIds: Array<Scalars['ID']['input']>;
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingRemoveWeekYearProjectInput = {
  projectId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingResponsibleToggleInput = {
  weekRecordId: Scalars['ID']['input'];
};

export type TimeTrackingRevision = {
  __typename?: 'TimeTrackingRevision';
  createdAt: Scalars['Date']['output'];
  entry: TimeTrackingEntry;
  field: TimeTrackingEntryField;
  id: Scalars['ID']['output'];
  user: User;
};

export type TimeTrackingRow = {
  __typename?: 'TimeTrackingRow';
  entries: Array<TimeTrackingEntry>;
  id: Scalars['ID']['output'];
  record: TimeTrackingWeekRecord;
  viewerCanAddEntry: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
};

export type TimeTrackingRowRemoveInput = {
  rowId: Scalars['ID']['input'];
};

export type TimeTrackingSapEmployee = TimeTrackingEmployee & {
  __typename?: 'TimeTrackingSAPEmployee';
  avatar?: Maybe<Scalars['String']['output']>;
  employeeId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  profitCenterId?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  sapId: Scalars['String']['output'];
};

export type TimeTrackingSapEmployeeWeekRecordAddInput = {
  projectId: Scalars['ID']['input'];
  sapEmployeeId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingSapTransmission = {
  __typename?: 'TimeTrackingSAPTransmission';
  createdAt: Scalars['Date']['output'];
  entry: TimeTrackingEntry;
  id: Scalars['ID']['output'];
  user: User;
};

export type TimeTrackingSapTransmitErrorResponse = {
  __typename?: 'TimeTrackingSAPTransmitErrorResponse';
  message: Scalars['String']['output'];
};

export type TimeTrackingSapTransmitInput = {
  entryIds: Array<Scalars['ID']['input']>;
  sapPassword: Scalars['String']['input'];
  sapUsername: Scalars['String']['input'];
};

export type TimeTrackingSapTransmitInvalidCredentials = {
  __typename?: 'TimeTrackingSAPTransmitInvalidCredentials';
  reason: Scalars['String']['output'];
};

export type TimeTrackingSapTransmitPayload = TimeTrackingSapTransmitErrorResponse | TimeTrackingSapTransmitInvalidCredentials | TimeTrackingSapTransmitResult;

export type TimeTrackingSapTransmitResult = {
  __typename?: 'TimeTrackingSAPTransmitResult';
  failed: Array<TimeTrackingEntry>;
  succeed: Array<TimeTrackingEntry>;
};

export type TimeTrackingSwitchMultiSapEmployeeInput = {
  currentEmployeeId: Scalars['ID']['input'];
  newEmployeeId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingSwitchProjectInput = {
  currentProjectId: Scalars['ID']['input'];
  newProjectId: Scalars['ID']['input'];
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingSwitchSapEmployeeInput = {
  recordId: Scalars['ID']['input'];
  sapEmployeeId: Scalars['ID']['input'];
};

export type TimeTrackingUnknownEmployee = TimeTrackingEmployee & {
  __typename?: 'TimeTrackingUnknownEmployee';
  name: Scalars['String']['output'];
  type: TimeTrackingUnknownEmployeeType;
};

export enum TimeTrackingUnknownEmployeeType {
  NotFromSap = 'NOT_FROM_SAP',
  TemporaryWorker = 'TEMPORARY_WORKER'
}

export type TimeTrackingUnknownEmployeeUpdateInput = {
  name: Scalars['String']['input'];
  recordId: Scalars['ID']['input'];
};

export type TimeTrackingUnknownEmployeeWeekRecordAddInput = {
  name: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
  type: TimeTrackingUnknownEmployeeType;
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingWeekRangeInput = {
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type TimeTrackingWeekRecord = {
  __typename?: 'TimeTrackingWeekRecord';
  employee: TimeTrackingEmployee;
  id: Scalars['ID']['output'];
  project: Project;
  responsible: Scalars['Boolean']['output'];
  rows: Array<TimeTrackingRow>;
  viewerCanAddRow: Scalars['Boolean']['output'];
  viewerCanRemoveRow: Scalars['Boolean']['output'];
  viewerCanSwitchProject: Scalars['Boolean']['output'];
  viewerCanToggleResponsible: Scalars['Boolean']['output'];
  viewerCanUpdateEmployee: Scalars['Boolean']['output'];
  week: Scalars['Int']['output'];
  weekYearProject: TimeTrackingWeekYearProject;
  weekYearSAPEmployee?: Maybe<TimeTrackingWeekYearSapEmployee>;
  year: Scalars['Int']['output'];
};

export type TimeTrackingWeekRecordSwitchProjectInput = {
  newProjectId: Scalars['ID']['input'];
  weekRecordId: Scalars['ID']['input'];
};

export type TimeTrackingWeekYearProject = {
  __typename?: 'TimeTrackingWeekYearProject';
  approvals: Array<TimeTrackingApprove>;
  digitalSalaryHistory: Array<DigitalSalaryHistoryItem>;
  employeeEntries: Array<TimeTrackingEmployeeAndEntry>;
  id: Scalars['ID']['output'];
  project: Project;
  records: Array<TimeTrackingWeekRecord>;
  viewerCanAccessProject: Scalars['Boolean']['output'];
  viewerCanAddWeekRecord: Scalars['Boolean']['output'];
  viewerCanMakeCommercialApprove: Scalars['Boolean']['output'];
  viewerCanMakeTechnicalApprove: Scalars['Boolean']['output'];
  viewerCanRemove: Scalars['Boolean']['output'];
  viewerCanSwitchProject: Scalars['Boolean']['output'];
  week: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type TimeTrackingWeekYearSapEmployee = {
  __typename?: 'TimeTrackingWeekYearSAPEmployee';
  employee: TimeTrackingSapEmployee;
  id: Scalars['ID']['output'];
  projectApprovals: Array<TimeTrackingApprove>;
  records: Array<TimeTrackingWeekRecord>;
  viewerCanAddWeekRecord: Scalars['Boolean']['output'];
  viewerCanRemoveWeekRecord: Scalars['Boolean']['output'];
  viewerCanSwitchEmployee: Scalars['Boolean']['output'];
  week: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type ToggleShowSubProjectHoursInput = {
  /** Id of the project. */
  id: Scalars['ID']['input'];
};

export type UpdateCatalogueInput = {
  /** ID of the catalogue. */
  catalogue: Scalars['String']['input'];
  /** Catalogue name. */
  name: Scalars['String']['input'];
};

export type UpdateFolderForFrameworkInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Category of the offer. */
  category: Scalars['ID']['input'];
  /** Id of the offer. */
  folder: Scalars['ID']['input'];
  /** Measurements of folder */
  measurements: Array<UpdateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type UpdateFolderForFreeFormInput = {
  /** Is this folder's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of the offer. */
  folder: Scalars['ID']['input'];
  /** Measurements of folder */
  measurements: Array<UpdateMeasurementInput>;
  /** Name of the folder. */
  name: Scalars['String']['input'];
  /** Note for the folder */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<OperationUnit>;
};

export type UpdateFolderOperationInput = {
  /** Is this operation's calculations are approximate? */
  approximate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Measurements of operation */
  measurements: Array<UpdateMeasurementInput>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Note for the operation */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Id of the operation. */
  operation: Scalars['ID']['input'];
  /** Is this operation optional */
  optional?: InputMaybe<Scalars['Boolean']['input']>;
  /** Alias for the part no */
  partNoAlias?: InputMaybe<Scalars['String']['input']>;
  /** Operation payment input. Please send totalPrice flat is true, otherwise please send discount/surcharge value and type. If you do not have any price adjustment please do not send this input at all. */
  payment?: InputMaybe<OperationPaymentInput>;
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type UpdateMeasurementInput = {
  /** ID of the measurement. */
  id?: InputMaybe<Scalars['String']['input']>;
  meta: Array<Scalars['String']['input']>;
  multiplier?: InputMaybe<Scalars['Float']['input']>;
  /** Offer name. */
  name: Scalars['String']['input'];
  /** Is subtraction enabled */
  subtraction: Scalars['Boolean']['input'];
  /** Total measurement */
  total: Scalars['Float']['input'];
  /** Total measurement meta for the operation. */
  totalMeta: Scalars['String']['input'];
};

export type UpdateOfferCalculationInput = {
  /** Extra prices of the offer. */
  extraPrices: Array<ExtraPriceInput>;
  /** ID of the offer. */
  offer: Scalars['ID']['input'];
  /** Tax rate of the offer. */
  taxRate: Scalars['Float']['input'];
};

export type UpdateOfferSortingForFrameworkInput = {
  /** Id of the item needs to be updated */
  id: Scalars['String']['input'];
  /** Sort of the item */
  sort: Scalars['Int']['input'];
  /** Type of the sorted items. */
  type: OfferSortableType;
};

export type UpdateOfferSortingForFreeFormInput = {
  /** Drop destination of item */
  destination?: InputMaybe<Scalars['String']['input']>;
  /** Id of the item needs to be updated */
  id: Scalars['String']['input'];
  /** Sort of the item */
  sort: Scalars['Int']['input'];
  /** Type of the sorted item. */
  type: OfferSortableType;
};

export type UpdateOperationInput = {
  /** Description of the operation */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of the operation. */
  name: Scalars['String']['input'];
  /** Id of the operation. */
  operation: Scalars['ID']['input'];
  /** Price for the operation. */
  price: Scalars['Float']['input'];
  unit: OperationUnit;
};

export type UpdateProjectStatusInput = {
  /** Id of the project. */
  project: Scalars['ID']['input'];
  /** Status of the project. */
  status: ProjectStatus;
};

/** Response for a update sorting action. */
export type UpdateSortingResult = {
  __typename?: 'UpdateSortingResult';
  /** Status of the action. */
  status: Scalars['Boolean']['output'];
};

export type UpdateTaxRateInput = {
  /** ID of the offer. */
  offer: Scalars['String']['input'];
  /** TaxRate for the offer. */
  taxRate: Scalars['Float']['input'];
};

export type UploadAcceptanceReportSignatureInput = {
  /** Id of the acceptance report. */
  acceptanceReport: Scalars['ID']['input'];
  /** Full name of the signatory. */
  fullName: Scalars['String']['input'];
  /** Type of the signature */
  type: AcceptanceReportSignatureType;
};

export type UploadManualPdfToAcceptanceReportInput = {
  /** Id of the acceptanceReport. */
  acceptanceReport: Scalars['ID']['input'];
};

export type UploadManualPdfToOvertimeInput = {
  overtime: Scalars['ID']['input'];
};

export type UploadOvertimeSignatureInput = {
  fullName: Scalars['String']['input'];
  overtime: Scalars['ID']['input'];
  type: SignatureType;
};

export type UploadRiskAssessmentSignatureInput = {
  /** Full name of the signatory. */
  fullName: Scalars['String']['input'];
  /** Id of the risk assessment. */
  riskAssessment: Scalars['ID']['input'];
  /** Type of the signature */
  type: RiskAssessmentSignatureType;
};

export type User = {
  __typename?: 'User';
  /** First name of the user */
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Last name of the user */
  lastName: Scalars['String']['output'];
};

export type Viewer = {
  __typename?: 'Viewer';
  /** Access token for the viewer. */
  accessToken: Scalars['String']['output'];
  bulkJobs: Array<DriveBulkJob>;
  digitalSalaryRole?: Maybe<DigitalSalaryRole>;
  /** Display name of the user */
  displayName: Scalars['String']['output'];
  drivePreference?: Maybe<ViewerProjectDrivePreferences>;
  /** E-mail of the viewer. */
  email: Scalars['String']['output'];
  /** Fax number of the viewer. */
  faxNumber?: Maybe<Scalars['String']['output']>;
  /** First name of the user */
  firstName: Scalars['String']['output'];
  /** HS id of the viewer. */
  hsId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Job Title of the viewer. */
  jobTitle?: Maybe<Scalars['String']['output']>;
  /** Last name of the user */
  lastName: Scalars['String']['output'];
  /** User main profit center */
  mainProfitCenter?: Maybe<ProfitCenter>;
  /** Mobile number of the viewer. */
  mobile?: Maybe<Scalars['String']['output']>;
  /** Landline number of the viewer. */
  phone?: Maybe<Scalars['String']['output']>;
  /** User's preferred language */
  preferredLanguageCode: LanguageCode;
  /** Profit centers of which user has access */
  profitCenters: Array<ProfitCenter>;
  /** User's projects' counts by their statuses */
  projectCountsByStatus: Array<ProjectsStatusFilterMeta>;
  /** Available projects for viewer */
  projects: Array<Project>;
  /** This field returns hs person id of the viewer. This field is just placeholder field. It does not give you the real SAP id */
  sapId?: Maybe<Scalars['String']['output']>;
  scopes: ViewerUserScopes;
  /** Signature url of the viewer. */
  signature?: Maybe<Scalars['String']['output']>;
  /** Signature email of the viewer. */
  signatureEmail?: Maybe<Scalars['String']['output']>;
  /** Whether this user subscribed to manual e-mails or not */
  subscribedToManualEmails: Scalars['Boolean']['output'];
  timeTrackingProjects: Array<TimeTrackingWeekYearProject>;
  watchedEvents: Array<Event>;
};


export type ViewerDrivePreferenceArgs = {
  projectId: Scalars['ID']['input'];
};


export type ViewerProfitCentersArgs = {
  excludeNonBranchesDepartmentsWorkGroups?: InputMaybe<Scalars['Boolean']['input']>;
  excludeOlds?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ViewerTimeTrackingProjectsArgs = {
  profitCenterId?: InputMaybe<Scalars['ID']['input']>;
  week: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type ViewerDigitalSalaryScopes = {
  __typename?: 'ViewerDigitalSalaryScopes';
  /** @deprecated Use `approveCommercial` from ViewerUserScopes */
  approveCommercial: ViewerScope;
  /** @deprecated Use `approveTechnical` from ViewerUserScopes */
  approveTechnical: ViewerScope;
  /** @deprecated Use `transmitToSAP` from ViewerUserScopes */
  transmitToSAP: ViewerScope;
};

export type ViewerDriveScopes = {
  __typename?: 'ViewerDriveScopes';
  /** @deprecated Use `createAcceptanceReportDocument` from ViewerUserScopes */
  createAcceptanceReportDocument: ViewerScope;
  /** @deprecated Use `createConcernDocument`  from ViewerUserScopes */
  createConcernDocument: ViewerScope;
  /** @deprecated Use `createConstructionNoteDocument`  from ViewerUserScopes */
  createConstructionNoteDocument: ViewerScope;
  /** @deprecated Use `createCustomFileDocument`  from ViewerUserScopes */
  createCustomFileDocument: ViewerScope;
  /** @deprecated Use `createCustomFolder`  from ViewerUserScopes */
  createCustomFolder: ViewerScope;
  /** @deprecated Use `createObstructionDocument`  from ViewerUserScopes */
  createObstructionDocument: ViewerScope;
  /** @deprecated Use `createOfferDocument`  from ViewerUserScopes */
  createOfferDocument: ViewerScope;
  /** @deprecated Use `createOvertimeDocument`  from ViewerUserScopes */
  createOvertimeDocument: ViewerScope;
  /** @deprecated Use `createQsstDocument`  from ViewerUserScopes */
  createQsstDocument: ViewerScope;
  /** @deprecated Use `createRiskAssessmentDocument`  from ViewerUserScopes */
  createRiskAssessmentDocument: ViewerScope;
  /** @deprecated Use `createSIVBillingAttachmentDocument`  from ViewerUserScopes */
  createSIVBillingAttachmentDocument: ViewerScope;
  /** @deprecated Use `downloadAcceptanceReportDocument` from ViewerUserScopes */
  downloadAcceptanceReportDocument: ViewerScope;
  /** @deprecated Use `downloadConcernDocument`  from ViewerUserScopes */
  downloadConcernDocument: ViewerScope;
  /** @deprecated Use `downloadConstructionNoteDocument`  from ViewerUserScopes */
  downloadConstructionNoteDocument: ViewerScope;
  /** @deprecated Use `downloadCustomFileDocument`  from ViewerUserScopes */
  downloadCustomFileDocument: ViewerScope;
  /** @deprecated Use `downloadObstructionDocument`  from ViewerUserScopes */
  downloadObstructionDocument: ViewerScope;
  /** @deprecated Use `downloadOfferDocument`  from ViewerUserScopes */
  downloadOfferDocument: ViewerScope;
  /** @deprecated Use `downloadOvertimeDocument`  from ViewerUserScopes */
  downloadOvertimeDocument: ViewerScope;
  /** @deprecated Use `downloadQsstDocument`  from ViewerUserScopes */
  downloadQsstDocument: ViewerScope;
  /** @deprecated Use `downloadRiskAssessmentDocument`  from ViewerUserScopes */
  downloadRiskAssessmentDocument: ViewerScope;
  /** @deprecated Use `downloadSIVBillingAttachmentDocument`  from ViewerUserScopes */
  downloadSIVBillingAttachmentDocument: ViewerScope;
  /** @deprecated Use `moveCustomFileDocument`  from ViewerUserScopes */
  moveCustomFileDocument: ViewerScope;
  /** @deprecated Use `removeDocumentsAdvanced`  from ViewerUserScopes */
  removeDocumentsAdvanced: ViewerScope;
  /** @deprecated Use `removeDocumentsSimple`  from ViewerUserScopes */
  removeDocumentsSimple: ViewerScope;
  /** @deprecated Use `sendAcceptanceReportDocumentAttachment` from ViewerUserScopes */
  sendAcceptanceReportDocumentAttachment: ViewerScope;
  /** @deprecated Use `sendAcceptanceReportDocumentSignatureMail` from ViewerUserScopes */
  sendAcceptanceReportDocumentSignatureMail: ViewerScope;
  /** @deprecated Use `sendConcernDocumentAttachment`  from ViewerUserScopes */
  sendConcernDocumentAttachment: ViewerScope;
  /** @deprecated Use `sendConstructionNoteDocumentAttachment`  from ViewerUserScopes */
  sendConstructionNoteDocumentAttachment: ViewerScope;
  /** @deprecated Use `sendCustomFileDocumentAttachment`  from ViewerUserScopes */
  sendCustomFileDocumentAttachment: ViewerScope;
  /** @deprecated Use `sendObstructionDocumentAttachment`  from ViewerUserScopes */
  sendObstructionDocumentAttachment: ViewerScope;
  /** @deprecated Use `sendOfferDocumentAttachment`  from ViewerUserScopes */
  sendOfferDocumentAttachment: ViewerScope;
  /** @deprecated Use `sendOvertimeDocumentAttachment`  from ViewerUserScopes */
  sendOvertimeDocumentAttachment: ViewerScope;
  /** @deprecated Use `sendOvertimeDocumentSignatureMail`  from ViewerUserScopes */
  sendOvertimeDocumentSignatureMail: ViewerScope;
  /** @deprecated Use `sendQsstDocumentAttachment`  from ViewerUserScopes */
  sendQsstDocumentAttachment: ViewerScope;
  /** @deprecated Use `sendQsstDocumentSignatureMail`  from ViewerUserScopes */
  sendQsstDocumentSignatureMail: ViewerScope;
  /** @deprecated Use `sendRiskAssessmentDocumentAttachment`  from ViewerUserScopes */
  sendRiskAssessmentDocumentAttachment: ViewerScope;
  /** @deprecated Use `sendSIVBillingAttachmentDocumentAttachment`  from ViewerUserScopes */
  sendSIVBillingAttachmentDocumentAttachment: ViewerScope;
  /** @deprecated Use `signAcceptanceReportDocument` from ViewerUserScopes */
  signAcceptanceReportDocument: ViewerScope;
  /** @deprecated Use `signConcernDocument`  from ViewerUserScopes */
  signConcernDocument: ViewerScope;
  /** @deprecated Use `signObstructionDocument`  from ViewerUserScopes */
  signObstructionDocument: ViewerScope;
  /** @deprecated Use `signOvertimeDocument`  from ViewerUserScopes */
  signOvertimeDocument: ViewerScope;
  /** @deprecated Use `signRiskAssessmentDocument`  from ViewerUserScopes */
  signRiskAssessmentDocument: ViewerScope;
  /** @deprecated Use `updateAcceptanceReportDocument` from ViewerUserScopes */
  updateAcceptanceReportDocument: ViewerScope;
  /** @deprecated Use `updateConcernDocument`  from ViewerUserScopes */
  updateConcernDocument: ViewerScope;
  /** @deprecated Use `updateConstructionNoteDocument`  from ViewerUserScopes */
  updateConstructionNoteDocument: ViewerScope;
  /** @deprecated Use `updateCustomFileDocument`  from ViewerUserScopes */
  updateCustomFileDocument: ViewerScope;
  /** @deprecated Use `updateObstructionDocument`  from ViewerUserScopes */
  updateObstructionDocument: ViewerScope;
  /** @deprecated Use `updateOfferDocument`  from ViewerUserScopes */
  updateOfferDocument: ViewerScope;
  /** @deprecated Use `updateOvertimeDocument`  from ViewerUserScopes */
  updateOvertimeDocument: ViewerScope;
  /** @deprecated Use `updateQsstDocument`  from ViewerUserScopes */
  updateQsstDocument: ViewerScope;
  /** @deprecated Use `updateRiskAssessmentDocument`  from ViewerUserScopes */
  updateRiskAssessmentDocument: ViewerScope;
  /** @deprecated Use `viewAcceptanceReportDocument` from ViewerUserScopes */
  viewAcceptanceReportDocument: ViewerScope;
  /** @deprecated Use `viewConcernDocument`  from ViewerUserScopes */
  viewConcernDocument: ViewerScope;
  /** @deprecated Use `viewConstructionNoteDocument`  from ViewerUserScopes */
  viewConstructionNoteDocument: ViewerScope;
  /** @deprecated Use `viewCustomFileDocument`  from ViewerUserScopes */
  viewCustomFileDocument: ViewerScope;
  /** @deprecated Use `viewObstructionDocument`  from ViewerUserScopes */
  viewObstructionDocument: ViewerScope;
  /** @deprecated Use `viewOfferDocument`  from ViewerUserScopes */
  viewOfferDocument: ViewerScope;
  /** @deprecated Use `viewOvertimeDocument`  from ViewerUserScopes */
  viewOvertimeDocument: ViewerScope;
  /** @deprecated Use `viewQsstDocument`  from ViewerUserScopes */
  viewQsstDocument: ViewerScope;
  /** @deprecated Use `viewRiskAssessmentDocument`  from ViewerUserScopes */
  viewRiskAssessmentDocument: ViewerScope;
  /** @deprecated Use `viewSIVBillingAttachmentDocument`  from ViewerUserScopes */
  viewSIVBillingAttachmentDocument: ViewerScope;
};

export type ViewerLanguageUpdateInput = {
  /** Language code */
  code: LanguageCode;
};

/** Preferred language update result. */
export type ViewerLanguageUpdateResult = {
  __typename?: 'ViewerLanguageUpdateResult';
  /** List of supported languages. */
  languages: Array<Language>;
  /** The currently authenticated user */
  viewer: Viewer;
};

export type ViewerProjectDrivePreferenceInput = {
  entrySorting?: InputMaybe<DriveEntrySortingInput>;
  folderSorting?: InputMaybe<DriveFolderSortingInput>;
  projectId: Scalars['ID']['input'];
  selectedFolderId?: InputMaybe<Scalars['ID']['input']>;
  viewPreferences?: InputMaybe<DriveViewPreferencesInput>;
};

export type ViewerProjectDrivePreferences = {
  __typename?: 'ViewerProjectDrivePreferences';
  entrySorting: DriveEntrySorting;
  folderSorting: DriveFolderSorting;
  id: Scalars['ID']['output'];
  selectedFolder?: Maybe<DriveFolder>;
  viewPreferences: DriveViewPreferences;
};

export type ViewerProjectScopes = {
  __typename?: 'ViewerProjectScopes';
  /** @deprecated Use `addOfferOperationFromTLK` from ViewerUserScopes */
  addOfferOperationFromTLK: ViewerScope;
  /** @deprecated Use `signAcceptanceReportDocument` from ViewerUserScopes */
  signAcceptanceReport: ViewerScope;
  /** @deprecated Use `signConcernDocument` from ViewerUserScopes */
  signConcern: ViewerScope;
  /** @deprecated Use `signObstructionDocument` from ViewerUserScopes */
  signObstruction: ViewerScope;
  /** @deprecated Use `signOvertimeDocument` from ViewerUserScopes */
  signOvertime: ViewerScope;
  /** @deprecated Use `signRiskAssessmentDocument` from ViewerUserScopes */
  signRiskAssessment: ViewerScope;
  /** @deprecated Use `updateProject` from ViewerUserScopes */
  updateProject: ViewerScope;
  /** @deprecated Use `updateProjectStatus` from ViewerUserScopes */
  updateProjectStatus: ViewerScope;
};

export type ViewerSivScopes = {
  __typename?: 'ViewerSIVScopes';
  /** @deprecated Use `accessSIVEmployeePlanning` from ViewerUserScopes */
  accessSIVEmployeePlanning: ViewerScope;
  /** @deprecated Use `accessSIVEmployeeRecording` from ViewerUserScopes */
  accessSIVEmployeeRecording: ViewerScope;
  /** @deprecated Use `accessSIVMaterialPlanning` from ViewerUserScopes */
  accessSIVMaterialPlanning: ViewerScope;
  /** @deprecated Use `accessSIVMaterialRecording` from ViewerUserScopes */
  accessSIVMaterialRecording: ViewerScope;
  /** @deprecated Use `accessSIVPerformancePlanning` from ViewerUserScopes */
  accessSIVPerformancePlanning: ViewerScope;
  /** @deprecated Use `accessSIVPerformanceRecording` from ViewerUserScopes */
  accessSIVPerformanceRecording: ViewerScope;
  /** @deprecated Use `accessSIVSubcontractorPlanning` from ViewerUserScopes */
  accessSIVSubcontractorPlanning: ViewerScope;
  /** @deprecated Use `accessSIVSubcontractorRecording` from ViewerUserScopes */
  accessSIVSubcontractorRecording: ViewerScope;
  /** @deprecated Use `addSIVOperationFromTLK` from ViewerUserScopes */
  addSIVOperationFromTLK: ViewerScope;
  /** @deprecated Use `createSIVAdditionalOperation` from ViewerUserScopes */
  createSIVAdditionalOperation: ViewerScope;
  /** @deprecated Use `importSIV` from ViewerUserScopes */
  importSIV: ViewerScope;
  /** @deprecated Use `updateSIVDiscount` from ViewerUserScopes */
  updateSIVDiscount: ViewerScope;
  /** @deprecated Use `updateSIVPartProjects` from ViewerUserScopes */
  updateSIVPartProjects: ViewerScope;
  /** @deprecated Use `updateSIVPerformancePlanning` from ViewerUserScopes */
  updateSIVPerformancePlanning: ViewerScope;
  /** @deprecated Use `updateSIVPerformanceRecording` from ViewerUserScopes */
  updateSIVPerformanceRecording: ViewerScope;
  /** @deprecated Use `viewSIVCostBar` from ViewerUserScopes */
  viewSIVCostBar: ViewerScope;
  /** @deprecated Use `viewSIVCostOverhead` from ViewerUserScopes */
  viewSIVCostOverhead: ViewerScope;
  /** @deprecated Use `viewSIVDiscount` from ViewerUserScopes */
  viewSIVDiscount: ViewerScope;
  /** @deprecated Use `viewSIVPartProjects` from ViewerUserScopes */
  viewSIVPartProjects: ViewerScope;
  /** @deprecated Use `viewSIVPerformanceStatusBar` from ViewerUserScopes */
  viewSIVPerformanceStatusBar: ViewerScope;
  /** @deprecated Use `viewSIVRevenueBar` from ViewerUserScopes */
  viewSIVRevenueBar: ViewerScope;
};

export type ViewerScope = {
  __typename?: 'ViewerScope';
  isPermitted: Scalars['Boolean']['output'];
  missingScopeNames: Array<Scalars['String']['output']>;
};

export type ViewerUserScopes = {
  __typename?: 'ViewerUserScopes';
  accessSIVEmployeePlanning: ViewerScope;
  accessSIVEmployeeRecording: ViewerScope;
  accessSIVMaterialPlanning: ViewerScope;
  accessSIVMaterialRecording: ViewerScope;
  accessSIVPerformancePlanning: ViewerScope;
  accessSIVPerformanceRecording: ViewerScope;
  accessSIVSubcontractorPlanning: ViewerScope;
  accessSIVSubcontractorRecording: ViewerScope;
  accessTLK: ViewerScope;
  addOfferOperationFromTLK: ViewerScope;
  addSIVOperationFromTLK: ViewerScope;
  approveCommercial: ViewerScope;
  approveTechnical: ViewerScope;
  createAcceptanceReportDocument: ViewerScope;
  createConcernDocument: ViewerScope;
  createConstructionNoteDocument: ViewerScope;
  createCustomFileDocument: ViewerScope;
  createCustomFolder: ViewerScope;
  createObstructionDocument: ViewerScope;
  createOfferDocument: ViewerScope;
  createOvertimeDocument: ViewerScope;
  createQsstDocument: ViewerScope;
  createRiskAssessmentDocument: ViewerScope;
  createSIVAdditionalOperation: ViewerScope;
  createSIVBillingAttachmentDocument: ViewerScope;
  downloadAcceptanceReportDocument: ViewerScope;
  downloadConcernDocument: ViewerScope;
  downloadConstructionNoteDocument: ViewerScope;
  downloadCustomFileDocument: ViewerScope;
  downloadObstructionDocument: ViewerScope;
  downloadOfferDocument: ViewerScope;
  downloadOvertimeDocument: ViewerScope;
  downloadQsstDocument: ViewerScope;
  downloadRiskAssessmentDocument: ViewerScope;
  downloadSIVBillingAttachmentDocument: ViewerScope;
  importProject: ViewerScope;
  importSIV: ViewerScope;
  moveCustomFileDocument: ViewerScope;
  removeDocumentsAdvanced: ViewerScope;
  removeDocumentsSimple: ViewerScope;
  sendAcceptanceReportDocumentAttachment: ViewerScope;
  sendAcceptanceReportDocumentSignatureMail: ViewerScope;
  sendConcernDocumentAttachment: ViewerScope;
  sendConstructionNoteDocumentAttachment: ViewerScope;
  sendCustomFileDocumentAttachment: ViewerScope;
  sendObstructionDocumentAttachment: ViewerScope;
  sendOfferDocumentAttachment: ViewerScope;
  sendOvertimeDocumentAttachment: ViewerScope;
  sendOvertimeDocumentSignatureMail: ViewerScope;
  sendQsstDocumentAttachment: ViewerScope;
  sendQsstDocumentSignatureMail: ViewerScope;
  sendRiskAssessmentDocumentAttachment: ViewerScope;
  sendSIVBillingAttachmentDocumentAttachment: ViewerScope;
  signAcceptanceReportDocument: ViewerScope;
  signConcernDocument: ViewerScope;
  signObstructionDocument: ViewerScope;
  signOvertimeDocument: ViewerScope;
  signRiskAssessmentDocument: ViewerScope;
  transmitKeyDataToSap: ViewerScope;
  transmitToSAP: ViewerScope;
  updateAcceptanceReportDocument: ViewerScope;
  updateConcernDocument: ViewerScope;
  updateConstructionNoteDocument: ViewerScope;
  updateCustomFileDocument: ViewerScope;
  updateObstructionDocument: ViewerScope;
  updateOfferDocument: ViewerScope;
  updateOvertimeDocument: ViewerScope;
  updateProject: ViewerScope;
  updateProjectStatus: ViewerScope;
  updateQsstDocument: ViewerScope;
  updateRiskAssessmentDocument: ViewerScope;
  updateSIVDiscount: ViewerScope;
  updateSIVPartProjects: ViewerScope;
  updateSIVPerformancePlanning: ViewerScope;
  updateSIVPerformanceRecording: ViewerScope;
  viewAcceptanceReportDocument: ViewerScope;
  viewConcernDocument: ViewerScope;
  viewConstructionNoteDocument: ViewerScope;
  viewCustomFileDocument: ViewerScope;
  viewDigitalSalaryOverview: ViewerScope;
  viewObstructionDocument: ViewerScope;
  viewOfferDocument: ViewerScope;
  viewOvertimeDocument: ViewerScope;
  viewQsstDocument: ViewerScope;
  viewRiskAssessmentDocument: ViewerScope;
  viewSIVBillingAttachmentDocument: ViewerScope;
  viewSIVCostBar: ViewerScope;
  viewSIVCostOverhead: ViewerScope;
  viewSIVDiscount: ViewerScope;
  viewSIVOverview: ViewerScope;
  viewSIVPartProjects: ViewerScope;
  viewSIVPerformanceStatusBar: ViewerScope;
  viewSIVPrices: ViewerScope;
  viewSIVRevenueBar: ViewerScope;
  viewTimeTrackingOverview: ViewerScope;
};

export type WatchEventsInput = {
  events: Array<Event>;
};

export enum WorkerRole {
  Fa = 'FA',
  Helper = 'HELPER',
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  Master = 'MASTER',
  Va = 'VA'
}
