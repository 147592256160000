import { WorkerRole } from '../../../../../../globalTypes';

interface SelectOption {
  label: string;
  value?: string;
}

export const ROLE_TYPE_OPTIONS: SelectOption[] = [
  {
    label: 'employeeRole.default',
    value: undefined,
  },
  {
    label: `employeeRole.${WorkerRole.Fa}`,
    value: WorkerRole.Fa,
  },
  {
    label: `employeeRole.${WorkerRole.Va}`,
    value: WorkerRole.Va,
  },
  {
    label: `employeeRole.${WorkerRole.Helper}`,
    value: WorkerRole.Helper,
  },
  {
    label: `employeeRole.${WorkerRole.Master}`,
    value: WorkerRole.Master,
  },
  {
    label: `employeeRole.${WorkerRole.L1}`,
    value: WorkerRole.L1,
  },
  {
    label: `employeeRole.${WorkerRole.L2}`,
    value: WorkerRole.L2,
  },
  {
    label: `employeeRole.${WorkerRole.L3}`,
    value: WorkerRole.L3,
  },
];
