import { gql } from '@apollo/client';

export const PublicOvertimeFragment = gql`
  fragment PublicOvertimeFragment on PublicOvertime {
    id
    no
    noComputed
    alias
    status
    name
    accessDueDate
    addedBy {
      id
      firstName
      lastName
    }
    contactPersonEmail
    signedByCustomer
    documentCanBeSigned
    projectLogo
    date
    createdAt
  }
`;
