import { useQuery } from '@apollo/client';
import OvertimeProjectEmployeeNamesQuery from './graphql/OvertimeProjectEmployeeNames.query';

export default function useProjectEmployeeNames(projectId: string): string[] {
  const { data: employeesData } = useQuery(OvertimeProjectEmployeeNamesQuery, {
    variables: {
      projectId,
    },
  });

  return employeesData?.employeesForProject.map((employee) => employee.name) ?? [];
}
