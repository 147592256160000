import { gql, TypedDocumentNode } from '@apollo/client';
import { UploadEditorImage, UploadEditorImageVariables } from './__generated__/graphql';

const UPLOAD_EDITOR_IMAGE: TypedDocumentNode<UploadEditorImage, UploadEditorImageVariables> = gql`
  mutation UploadEditorImage($file: Upload!) {
    uploadEditorImage(file: $file) {
      id
      path
    }
  }
`;

export default UPLOAD_EDITOR_IMAGE;
