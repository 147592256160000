import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const DUPLICATE_OVERTIME = gql`
  mutation DuplicateOvertime($input: DuplicateOvertimeInput!) {
    duplicateOvertime(input: $input) {
      id
    }
  }
`;
