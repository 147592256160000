import { useTranslation } from 'react-i18next';
import { Box, Text } from '@hs-baumappe/legacy-ui';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';

import DocumentDetailBreadcrumbs from '../../../../components/DocumentDetailBreadcrumbs';
import { getOvertimeStatusColor } from './OvertimeDetailHeader.utils';
import { OvertimeStatus } from '../../../../globalTypes';
import { OvertimeProject } from '../../../../graphql/__generated__/OvertimeProject.fragment';
import { routes } from '../../../routes';
import { DriveDocumentParentFolders } from '../../../../hooks/useDriveDocumentParentFolders';

interface OvertimeDetailHeaderProps {
  name: string;
  status: OvertimeStatus;
  signed: boolean;
  project: OvertimeProject;
  parentFolders: DriveDocumentParentFolders;
}

export default function OvertimeDetailHeader({
  name,
  status,
  signed,
  project,
  parentFolders,
}: OvertimeDetailHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  return (
    <>
      <DocumentDetailBreadcrumbs
        projectId={project.id}
        projectNumber={project.projectNumber}
        parentFolders={parentFolders}
      />
      <AppHeader
        title={
          <Box className="qa-overtime-detail-title">
            <Text component="h1" variant="body-large" color="muted" stylex={{ marginBottom: '0' }}>
              {name}
            </Text>
            <Text color={signed ? 'success' : getOvertimeStatusColor(status)}>
              {t('overtimeDetail.status.title', { status: t(status) })}
            </Text>
          </Box>
        }
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />
    </>
  );
}
