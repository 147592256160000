import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikErrors, FormikHelpers } from 'formik/dist/types';
import { useState } from 'react';
import { createPath, useNavigate } from 'react-router-dom';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import { mapBadUserInputErrorValues } from '@hs-baumappe/redkit';
import ArchiveOvertimeMutation from './graphql/ArchiveOvertime.mutation';
import { DriveDocumentArchiveInput } from '../../globalTypes';
import OvertimeDetailQuery from '../../hooks/useOvertime/graphql/OvertimeDetail.query';
import { ArchiveFormValues } from '../../components/ArchiveForm';
import overtimeRoutes from '../overtimeRoutes';
import {
  OvertimeDetailAlert,
  OvertimeDetailSearchParams,
} from '../OvertimeDetail/OvertimeDetail.utils';

interface UseOvertimeArchiveResponse {
  archive: (
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useOvertimeArchive(overtimeId: string): UseOvertimeArchiveResponse {
  const navigate = useNavigate();
  const { setSapCredentials } = useSapCredentials();
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [mutation, { loading }] = useMutation(ArchiveOvertimeMutation, {
    refetchQueries: [OvertimeDetailQuery],
  });

  async function archive(
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) {
    if (loading) {
      return;
    }

    setError(undefined);

    try {
      const { data } = await mutation({
        variables: {
          input: {
            id: overtimeId,
            sapUsername: values.username,
            sapPassword: values.password,
          },
        },
      });

      if (data) {
        setSapCredentials({ username: values.username, password: values.password });
      }

      const searchParams = new URLSearchParams({
        [OvertimeDetailSearchParams.ALERT]: OvertimeDetailAlert.ARCHIVED,
      });

      navigate(
        createPath({
          pathname: overtimeRoutes.detail.generatePath({ overtimeId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = mapBadUserInputErrorValues<
        DriveDocumentArchiveInput,
        FormikErrors<ArchiveFormValues>
      >(e, (errors) => ({
        ...errors,
        username: errors.sapUsername,
        password: errors.sapPassword,
      }));

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return { archive, loading, error };
}
