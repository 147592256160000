import { PropsWithChildren, Suspense, lazy } from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import { Text } from '@hs-baumappe/legacy-ui';

// eslint-disable-next-line import/no-unresolved
const DocumentDetailNote = lazy(() => import('driveWeb/DocumentDetailNote'));

interface DriveDocumentNoteContainerProps {
  id: string;
  name: string;
}

export default function DriveDocumentNoteContainer({
  id,
  name,
  children,
}: PropsWithChildren<DriveDocumentNoteContainerProps>): JSX.Element {
  const fallback = (
    <>
      <Text variant="title-small" title={name} truncate>
        {name}
      </Text>

      {children}
    </>
  );

  return (
    <ErrorBoundary fallback={fallback}>
      <Suspense fallback={fallback}>
        <DocumentDetailNote documentId={id}>{children}</DocumentDetailNote>
      </Suspense>
    </ErrorBoundary>
  );
}
