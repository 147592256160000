import { ApolloError, useMutation } from '@apollo/client';
import { createPath, useNavigate } from 'react-router-dom';
import UploadManualPDFToOvertimeMutation from './graphql';
import {
  OvertimeDetailAlert,
  OvertimeDetailSearchParams,
} from '../OvertimeDetail/OvertimeDetail.utils';
import { UploadManuallySignedReportFormValues } from './components/UploadManuallySignedReportForm/UploadManuallySignedReportForm';
import overtimeRoutes from '../overtimeRoutes';

interface UseUploadManualPDFOvertimeProps {
  error?: ApolloError;
  uploadFile: (values: UploadManuallySignedReportFormValues) => void;
  loading: boolean;
}

interface UseUploadManualPDFOvertimeParams {
  overtimeId: string;
}

export default function useUploadManualPDFOvertime({
  overtimeId,
}: UseUploadManualPDFOvertimeParams): UseUploadManualPDFOvertimeProps {
  const navigate = useNavigate();
  const [mutation, { loading, error }] = useMutation(UploadManualPDFToOvertimeMutation);

  async function uploadFile({ manuallySignedReport }: UploadManuallySignedReportFormValues) {
    if (loading) {
      return;
    }

    await mutation({
      variables: {
        file: manuallySignedReport,
        input: {
          overtime: overtimeId,
        },
      },
    });

    const searchParams = new URLSearchParams({
      [OvertimeDetailSearchParams.ALERT]: OvertimeDetailAlert.SIGNED_MANUALLY,
    });

    navigate(
      createPath({
        pathname: overtimeRoutes.detail.generatePath({
          overtimeId,
        }),
        search: searchParams.toString(),
      }),
    );
  }

  return { uploadFile, error, loading };
}
