import { ChangeEvent, useRef, useState } from 'react';
import { Details, Summary, Text } from '@hs-baumappe/legacy-ui';
import { useFormikContext } from 'formik';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import OvertimeUploadImageWidgetEntry from './OvertimeUploadImageWidgetEntry';
import OvertimeUploadImageWidgetMenu from './OvertimeUploadImageWidgetMenu';
import {
  createEmptyImageWithLabel,
  ImageUploadWithLabelFormValues,
} from './OvertimeUploadImageWidgetEntry/components/ImageUploadWithLabel';
import OvertimeConvertImageAttachmentsMutation from './graphql/OvertimeConvertImageAttachments.mutation';
import ErrorState from '../../../ErrorState';
import DriveImageGalleryContainer, {
  DriveImageGalleryDocument,
} from '../../../DriveImageGalleryContainer';

export interface OvertimeUploadImageWidgetFormValues {
  images: ImageUploadWithLabelFormValues[];
}

interface OvertimeUploadImageWidgetProps {
  projectId: string;
}

export default function OvertimeUploadImageWidget({
  projectId,
}: OvertimeUploadImageWidgetProps): JSX.Element {
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [driveImageGalleryOpen, setDriveImageGalleryOpen] = useState(false);
  const formik = useFormikContext<OvertimeUploadImageWidgetFormValues>();
  const [convertDriveImagesMutation, { loading, error }] = useMutation(
    OvertimeConvertImageAttachmentsMutation,
  );

  if (!formik) {
    throw new Error(
      'The OvertimeUploadImageWidget component should be wrapped with a Formik Context',
    );
  }

  const { values, setFieldValue, initialValues } = formik;

  function removeEntryById(index: number) {
    const images = [...values.images];

    images.splice(index, 1);

    setFieldValue('images', images);
  }

  function handleAddImagesFileInputChange(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      return;
    }

    const images = [...values.images];
    const files = Array.from(event.target.files);
    const newImages = files.map((file) => createEmptyImageWithLabel({ file }));

    images.push(...newImages);

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    setFieldValue('images', images);
  }

  async function convertImages(selectedDocuments: string[]) {
    const { data } = await convertDriveImagesMutation({
      variables: {
        input: selectedDocuments,
      },
    });

    if (!data) {
      return;
    }

    return data.convertCustomFilesToImages;
  }

  async function handleDocumentsSelected(selectedDocuments: DriveImageGalleryDocument[]) {
    setDriveImageGalleryOpen(false);

    const documentIds = selectedDocuments.map(({ id }) => id);
    const convertedImages = await convertImages(documentIds);

    if (!convertedImages) {
      return;
    }

    const imageAttachments: ImageUploadWithLabelFormValues[] = convertedImages.map(
      (convertedImage, index) => {
        const selectedDocument = selectedDocuments[index];

        return createEmptyImageWithLabel({
          imageId: convertedImage.id,
          label: selectedDocument?.name || '',
          imageUrl: convertedImage.url,
          imageThumbnailUrl: convertedImage.url,
        });
      },
    );

    setFieldValue('images', [...values.images, ...imageAttachments]);
    setDriveImageGalleryOpen(false);
  }

  function handleUploadImageButtonClick() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  const hasImageAttachmentsOnInitial = initialValues.images.length > 0;
  const hasImageAttachments = values.images.length > 0;
  const showRemoveButton = values.images.length > 1;

  return (
    <Details
      className="qa-overtime-create-upload-image"
      defaultOpen={hasImageAttachmentsOnInitial}
      renderSummary={({ onClick }) => (
        <Summary onClick={onClick}>
          <Text variant="title-small" color="muted" component="h3" className="u-margin-bottom-0">
            {t('overtimeUploadImageWidget.title')}
          </Text>
        </Summary>
      )}
    >
      {error && <ErrorState error={error} />}

      <div className="row">
        <div className="col col--sm-5">
          <Text component="p">{t('overtimeUploadImageWidget.helperText')}</Text>
        </div>
      </div>

      <div className="u-margin-top">
        {values.images.map((image, index) => {
          return (
            <OvertimeUploadImageWidgetEntry
              name={`images.${index}`}
              key={image.orderId}
              onRequestRemove={() => removeEntryById(index)}
              showRemoveButton={showRemoveButton}
            />
          );
        })}
      </div>

      <div className="row">
        <div className="col col--sm-5">
          <OvertimeUploadImageWidgetMenu
            hasImageAttachments={hasImageAttachments}
            onUploadImageButtonClick={handleUploadImageButtonClick}
            onSelectImageButtonClick={() => setDriveImageGalleryOpen(true)}
            loading={loading}
          />

          <input
            ref={fileInputRef}
            type="file"
            id="overtime-image-attachment-upload"
            className="u-visually-hidden"
            accept="image/jpeg, image/png, image/jpg"
            multiple
            onChange={handleAddImagesFileInputChange}
          />
        </div>
      </div>

      <DriveImageGalleryContainer
        open={driveImageGalleryOpen}
        projectId={projectId}
        onDialogClose={() => setDriveImageGalleryOpen(false)}
        onDocumentsSelected={handleDocumentsSelected}
      />
    </Details>
  );
}
