import { ApolloError, useQuery } from '@apollo/client';
import ProjectEmailSuggestionsQuery from './graphql/ProjectEmailSuggestions.query';
import { ProjectEmailSuggestions } from './graphql/__generated__/ProjectEmailSuggestions.query';

const EMPTY_EMAIL_SUGGESTIONS: ProjectEmailSuggestions['emailSuggestions'] = {
  contactPeople: [],
  recipients: [],
  __typename: 'EmailSuggestions',
};

interface UseProjectEmailSuggestionsResponse {
  emailSuggestions: ProjectEmailSuggestions['emailSuggestions'];
  loading: boolean;
  error?: ApolloError;
}

export default function useProjectEmailSuggestions(
  projectId: string,
): UseProjectEmailSuggestionsResponse {
  const { data, loading, error } = useQuery(ProjectEmailSuggestionsQuery, {
    variables: {
      projectId,
    },
  });

  return {
    emailSuggestions: data?.emailSuggestions || EMPTY_EMAIL_SUGGESTIONS,
    loading,
    error,
  };
}
