import { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Button, Divider, Flex, FlexGrid, FlexGridColumn, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';

import OvertimeLogsQuery from '../../graphql/OvertimeLogs.query';
import ErrorState from '../../../../../../components/ErrorState';
import { groupLogsByDate, mergeIncomingLogs } from './OvertimeDetailLogsDetail.utils';
import OvertimeLog from '../OvertimeLog';

import styles from '../OvertimeLog/OvertimeLog.module.scss';

interface OvertimeDetailLogsDetailProps {
  overtimeId: string;
}

export default function OvertimeDetailLogsDetail({
  overtimeId,
}: OvertimeDetailLogsDetailProps): JSX.Element {
  const { t } = useTranslation();

  const { data, loading, error, fetchMore } = useQuery(OvertimeLogsQuery, {
    variables: {
      overtimeId,
      page: 1,
      limit: 10,
    },
  });

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorState error={error} />;
  }

  const logs = data?.overtime.logs?.logs ?? [];
  const hasNextPage = data?.overtime?.logs?.pagination.hasNextPage ?? false;

  if (logs.length === 0) {
    return (
      <Flex justifyContent="center" alignItems="center" stylex={{ height: '100%' }}>
        <Text variant="body-small">{t('logContainer.noLogFound')}</Text>
      </Flex>
    );
  }

  const groupedLogs = groupLogsByDate(logs);

  async function handleMoreLoadButtonClick() {
    const nextPage = (data?.overtime.logs?.pagination?.current || 1) + 1;

    await fetchMore({
      variables: { page: nextPage },
      updateQuery: (previousQueryResult, { fetchMoreResult }) =>
        mergeIncomingLogs(previousQueryResult, fetchMoreResult),
    });
  }

  return (
    <>
      {groupedLogs.map((logGroup, index) => (
        <Fragment key={index}>
          <Box className={styles['overtime-log']}>
            {logGroup.map((log, logIndex) => (
              <OvertimeLog log={log} showDate={logIndex === 0} key={logIndex} />
            ))}
          </Box>
          {groupedLogs.length > 1 && index !== logGroup.length - 1 && (
            <Divider stylex={{ marginEnds: 'small-x' }} />
          )}
        </Fragment>
      ))}

      {hasNextPage && (
        <FlexGrid stylex={{ marginTop: 'medium' }} justifyContent="center">
          <FlexGridColumn sm={5} xs={12}>
            <Button onClick={handleMoreLoadButtonClick} fullWidth>
              {t('logContainer.moreLoadButton')}
            </Button>
          </FlexGridColumn>
        </FlexGrid>
      )}
    </>
  );
}
