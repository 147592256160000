import { useState } from 'react';
import { Button, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import { downloadDocument } from './PublicOvertimeDetailThankYou.utils';
import { GetPublicOvertimePdfPath } from '../../graphql/__generated__/GetPublicOvertimePdfPath.query';
import { GetPublicOvertimeDetail } from '../../../graphql/__generated__/GetPublicOvertimeDetail.query';

interface PublicOvertimeDetailThankYouProps {
  daysRemaining: number;
  overtimePdfPath: GetPublicOvertimePdfPath['overtimePdfPath'];
  overtime: NonNullable<GetPublicOvertimeDetail['publicOvertime']>;
}

export default function PublicOvertimeDetailThankYou({
  daysRemaining,
  overtimePdfPath,
  overtime,
}: PublicOvertimeDetailThankYouProps): JSX.Element {
  const { t } = useTranslation();

  const [pdfDownloading, setPdfDownloading] = useState(false);

  const { name } = overtime;
  const { pdfPath } = overtimePdfPath;

  async function handleDownloadPdfButtonClick() {
    if (!pdfPath) {
      return;
    }

    setPdfDownloading(true);

    await downloadDocument(pdfPath, `${name}.pdf`);
    setPdfDownloading(false);
  }

  return (
    <div className="row u-justify-content-center">
      <div className="col col--sm-8 u-display-flex u-flex-direction-column u-text-align-center">
        <Text component="h2" variant="title-medium">
          {t('overtimeCustomerThankYou.thankYouTitle')}
        </Text>

        <Text component="p">
          {t('overtimeCustomerThankYou.thankYouRemainingDays', { date: daysRemaining })}
        </Text>

        <Text variant="label-medium" color="muted">
          {t('overtimeCustomerThankYou.informationText')}
        </Text>
        <div className="row u-justify-content-center u-margin-top">
          <div className="col col--sm-6">
            <Button
              onClick={handleDownloadPdfButtonClick}
              loading={pdfDownloading}
              endIcon={<Icon name="cloud-download" />}
              color="primary"
              fullWidth
            >
              {t('overtimeCustomerThankYou.downloadPdfButtonText')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
