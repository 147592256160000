import { Box } from '@hs-baumappe/legacy-ui';
import externalLogo from '../../static/img/external-logo.svg';

import styles from './project-logo.module.scss';

interface ExternalHeaderProps {
  logoSrc?: string;
}

function ExternalHeader({ logoSrc = externalLogo }: ExternalHeaderProps): JSX.Element {
  return (
    <Box stylex={{ paddingTop: 'large-2x' }}>
      <img src={logoSrc} alt="logo" className={styles['project-logo']} />
    </Box>
  );
}

export default ExternalHeader;
