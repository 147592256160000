import { FormikHelpers } from 'formik';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { useState } from 'react';
import { FormikErrors } from 'formik/dist/types';
import { createPath, useNavigate } from 'react-router-dom';
import { mapBadUserInputErrorValues } from '@hs-baumappe/redkit';
import OvertimeSendExternalEmailMutation from './graphql/OvertimeSendExternalEmail.mutation';
import {
  OvertimeDetailAlert,
  OvertimeDetailSearchParams,
} from '../OvertimeDetail/OvertimeDetail.utils';
import { EmailFormValues } from '../../components/EmailForm';
import {
  OvertimeSendExternalEmail,
  OvertimeSendExternalEmailVariables,
} from './graphql/__generated__/OvertimeSendExternalEmail.mutation';
import OvertimeDetailQuery from '../../hooks/useOvertime/graphql/OvertimeDetail.query';
import overtimeRoutes from '../overtimeRoutes';
import { SendOvertimeExternalLinkEmailInput } from '../../globalTypes';

interface UseOvertimeSendExternalEmailResponse {
  sendExternalEmail: (
    values: EmailFormValues,
    formikHelpers: FormikHelpers<EmailFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useOvertimeSendExternalEmail(
  overtimeId: string,
): UseOvertimeSendExternalEmailResponse {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const [mutation, { loading }] = useMutation<
    OvertimeSendExternalEmail,
    OvertimeSendExternalEmailVariables
  >(OvertimeSendExternalEmailMutation, {
    // TODO: Remove that refetch Queries once the server-side mutation return type is fixed into Overtime!
    refetchQueries: [OvertimeDetailQuery],
  });

  async function sendExternalEmail(
    values: EmailFormValues,
    formikHelpers: FormikHelpers<EmailFormValues>,
  ) {
    if (loading) {
      return;
    }

    try {
      await mutation({
        variables: {
          input: {
            id: overtimeId,
            recipients: values.toEmails,
            subject: values.subject,
            content: values.content,
            cc: values.ccEmails,
          },
        },
      });

      const searchParams = new URLSearchParams({
        [OvertimeDetailSearchParams.ALERT]: OvertimeDetailAlert.SEND,
      });

      navigate(
        createPath({
          pathname: overtimeRoutes.detail.generatePath({ overtimeId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = mapBadUserInputErrorValues<
        SendOvertimeExternalLinkEmailInput,
        FormikErrors<EmailFormValues>
      >(e, (errors) => ({
        ...errors,
        ccEmails: errors.cc,
        toEmails: errors.recipients,
      }));

      if (!formErrors) {
        setError(e);
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return {
    sendExternalEmail,
    loading,
    error,
  };
}
