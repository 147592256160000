import { gql, TypedDocumentNode } from '@apollo/client/core';
import { OvertimeRemove, OvertimeRemoveVariables } from './__generated__/OvertimeRemove.mutation';

const OvertimeRemoveMutation: TypedDocumentNode<OvertimeRemove, OvertimeRemoveVariables> = gql`
  mutation OvertimeRemove($input: DriveDocumentRemoveInput!) {
    driveDocumentRemove(input: $input) {
      status
    }
  }
`;

export default OvertimeRemoveMutation;
