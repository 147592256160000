import { route } from './route.utils';
import {
  AssignDocumentToProjectImportRouteParams,
  AssignDocumentToProjectRouteParams,
  ProjectDetailRouteParams,
  ProjectDetailWithFolderRouteParams,
} from './routes.types';

export const routes = {
  project: route<ProjectDetailRouteParams>('/project/:projectId'),
  projects: route('/projects'),
  logout: route('/logout'),
};

export const projectRoutes = {
  detail: routes.project,
  driveWithFolder: route<ProjectDetailWithFolderRouteParams>(`${routes.project.path}/:folderId`),
  assignDocumentToProject: route<AssignDocumentToProjectRouteParams>(
    `${routes.project.path}/d/:documentId/assign-document-to-project`,
  ),
  assignDocumentToProjectImport: route<AssignDocumentToProjectImportRouteParams>(
    `${routes.project.path}/d/:documentId/assign-document-to-project-import`,
  ),
};
