import { useState } from 'react';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikErrors, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { mapBadUserInputErrorValues } from '@hs-baumappe/redkit';
import OvertimeSaveMutation from './graphql/OvertimeSave.mutation';
import { OvertimeFormValues } from '../../components/OvertimeForm/form';
import { createOvertimeSaveInput } from './useOvertimeSave.utils';
import overtimeRoutes from '../../routes/overtimeRoutes';
import { OvertimeSaveInput } from '../../globalTypes';

interface UseOvertimeSaveResponse {
  save: (
    formValues: OvertimeFormValues,
    formikHelpers: FormikHelpers<OvertimeFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

interface UseOvertimeSaveOptions {
  overtimeId: string;
  draftProject: boolean;
}

export default function useOvertimeSave({
  overtimeId,
  draftProject,
}: UseOvertimeSaveOptions): UseOvertimeSaveResponse {
  const navigate = useNavigate();
  const [mutate, { loading }] = useMutation(OvertimeSaveMutation);
  const [error, setError] = useState<ApolloError | undefined>(undefined);

  async function save(
    formValues: OvertimeFormValues,
    formikHelpers: FormikHelpers<OvertimeFormValues>,
  ) {
    setError(undefined);

    try {
      await mutate({
        variables: {
          input: createOvertimeSaveInput({
            formValues,
            draftProject,
            overtimeId,
          }),
        },
      });

      navigate(overtimeRoutes.detail.generatePath({ overtimeId }));
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = mapBadUserInputErrorValues<
        OvertimeSaveInput,
        FormikErrors<OvertimeFormValues>
      >(e, (errors) => ({
        ...errors,
        workName: errors.workName,
      }));

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return {
    save,
    loading,
    error,
  };
}
