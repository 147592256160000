import { format } from 'date-fns';
import { Log } from '../../OvertimeDetailLogs.types';
import { OvertimeLogs } from '../../graphql/__generated__/OvertimeLogs.query';

export function groupLogsByDate(logs: Log[]): Log[][] {
  const result: Record<string, Log[]> = {};

  logs.forEach((log) => {
    const date = 'date' in log ? format(new Date(log.date), 'dd.MM.yyyy') : '';
    if (!date) {
      return;
    }

    if (result[date]) {
      result[date].push(log);
    } else {
      result[date] = [log];
    }
  });

  return Object.values(result);
}

export function mergeIncomingLogs(
  previousQueryResult: OvertimeLogs,
  incomingQueryResult: OvertimeLogs,
): OvertimeLogs {
  return {
    ...incomingQueryResult,
    overtime: {
      ...incomingQueryResult.overtime,
      logs: incomingQueryResult.overtime.logs
        ? {
            ...incomingQueryResult.overtime.logs,
            logs: [
              ...(previousQueryResult.overtime.logs?.logs ?? []),
              ...(incomingQueryResult.overtime.logs.logs ?? []),
            ],
          }
        : undefined,
    },
  };
}
