import { useCallback, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AlertDialog, Icon, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import OvertimeSendExternalEmailBody from './components/OvertimeSendExternalEmailBody';
import { Overtime } from '../../graphql/__generated__/OvertimeFragment';
import { OvertimeProject } from '../../graphql/__generated__/OvertimeProject.fragment';
import { OvertimeScopes } from '../../Overtime.types';
import { OvertimeLocationState } from '../Overtime.route-types';
import overtimeRoutes from '../overtimeRoutes';
import RouteDialog from '../../components/RouteDialog';
import { MissingScopeError } from '@hs-baumappe/redkit';

interface OvertimeSendExternalEmailProps {
  overtime: Overtime;
  project: OvertimeProject;
  scopes: OvertimeScopes;
}

export default function OvertimeSendExternalEmail({
  overtime,
  project,
  scopes,
}: OvertimeSendExternalEmailProps): JSX.Element {
  const { t } = useTranslation();

  const location = useLocation<OvertimeLocationState>();
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(true);
  const [formDirty, setFormDirty] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  function handleDialogRequestClose() {
    if (formDirty) {
      setShowConfirmationModal(true);
      return;
    }

    setDialogOpen(false);
  }

  const handleDialogClose = useCallback(() => {
    if (location.state?.backgroundLocation) {
      navigate(location.state.backgroundLocation);
      return;
    }

    navigate(overtimeRoutes.detail.generatePath({ overtimeId: overtime.id }));
  }, [location.state, navigate, overtime.id]);

  function handleConfirmationModalConfirm() {
    setShowConfirmationModal(false);
    setDialogOpen(false);
  }

  const permitted = scopes.sendOvertimeDocumentSignatureMail.isPermitted;
  const missingScopeNames = scopes.sendOvertimeDocumentSignatureMail.missingScopeNames;

  if (!overtime.viewerCanSendExternalLink) {
    return (
      <Navigate to={overtimeRoutes.detail.generatePath({ overtimeId: overtime.id })} replace />
    );
  }

  return (
    <RouteDialog
      open={dialogOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      header={
        <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
          {overtime.name}
        </Text>
      }
    >
      {!permitted ? (
        <MissingScopeError missingScopes={missingScopeNames} />
      ) : (
        <OvertimeSendExternalEmailBody
          overtime={overtime}
          project={project}
          onDirtyStateChange={() => setFormDirty(true)}
        />
      )}

      <AlertDialog
        open={showConfirmationModal}
        title={overtime.name}
        description={t('sendOvertimePdfEmail.cancelModal.content')}
        confirmButtonText={t('sendOvertimePdfEmail.cancelModal.confirmButton')}
        confirmButtonEndIcon={<Icon name="delete" />}
        cancelButtonText={t('sendOvertimePdfEmail.cancelModal.cancelButton')}
        onConfirm={handleConfirmationModalConfirm}
        onCancel={() => setShowConfirmationModal(false)}
        onRequestClose={() => setShowConfirmationModal(false)}
        destructive
      />
    </RouteDialog>
  );
}
